import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  deleteSharePrice,
  deleteSharePriceSucceeded,
  fetchSharePrices,
  fetchSharePricesSucceeded,
  postSharePrice,
  postSharePriceSucceeded,
} from "src/admin-portal/actions/share-price-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { buildURL } from "src/common/utils/utils";
import * as selectors from "src/selectors";

const sharePricesUrl = (
  tenantId,
  params: Api.V1.SharePriceQueryParams = { sort: "-date,-createdAt" }
) => buildURL(`/tenants/${tenantId}/stock-prices`, params);

const deleteSharePriceUrl = sharePriceId => `/stock-prices/${sharePriceId}`;
const dataFormatter = new Jsona();

function* postSharePriceRequested(action: ReturnType<typeof postSharePrice>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const body = {
      data: {
        type: "stockPrices",
        attributes: action.payload,
      },
    };

    const response = yield call(() =>
      callApi(sharePricesUrl(tenantId), token, method, body)
    );

    yield put(
      postSharePriceSucceeded(dataFormatter.deserialize(
        response
      ) as Api.V1.SharePrice)
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostSharePrice() {
  yield takeEvery(postSharePrice.getType(), postSharePriceRequested);
}

function* fetchSharePriceRequested(
  action: ReturnType<typeof fetchSharePrices>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const response = yield call(() =>
      callApi(sharePricesUrl(tenantId, action.payload), token)
    );
    yield put(
      fetchSharePricesSucceeded({
        date:
          action.payload && action.payload.filter && action.payload.filter.date,
        sharePrices: dataFormatter.deserialize(response) as Api.V1.SharePrice[],
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchSharePrice() {
  yield takeEvery(fetchSharePrices.getType(), fetchSharePriceRequested);
}

function* deleteSharePriceRequested(
  action: ReturnType<typeof deleteSharePrice>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "DELETE";

    yield call(() =>
      callApi(deleteSharePriceUrl(action.payload), token, method)
    );
    yield put(deleteSharePriceSucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteSharePrice() {
  yield takeEvery(deleteSharePrice.getType(), deleteSharePriceRequested);
}
