import moment, { isMoment, Moment } from "moment";
import { apiShortDate, employeeName, flatten } from "../../common/utils/utils";
import { RootState } from "../../reducers/all-reducers";
import { employeeForId } from "../employees/employee-selectors";
import { APIDocument, APIEmployeeDocument2 } from "./files-reducer";

export interface DocumentAcceptanceStatus {
  id: string;
  fileName: string;
  employeeName: string;
  employeeEmail: string;
  requiresAcceptance: boolean;
  terminationDate?: Moment;
  acceptedAt?: Moment;
}

export const allDocumentsAcceptanceStatus = (
  state: RootState
): DocumentAcceptanceStatus[] => {
  const documentsThatNeedsAction = state.file.documents
    .filter(removeDocumentsWithoutEmployee)
    .map(doc =>
      doc.employee_documents
        .map(toDocumentAcceptanceStatus(doc, state))
        .filter(removeDocumentsThatNeverNeededAction)
    );

  return flatten(documentsThatNeedsAction);
};

export const allNonAcceptedDocuments = (state: RootState) =>
  allDocumentsAcceptanceStatus(state).filter(d => d.requiresAcceptance);

const toDocumentAcceptanceStatus = (doc: APIDocument, state: RootState) => (
  ed: APIEmployeeDocument2
) => {
  const employee = employeeForId(ed.employee_id)(state);

  return {
    id: ed.id,
    fileName: doc.file_name,
    employeeName: employee && employeeName(employee),
    employeeEmail: employee && employee.email,
    requiresAcceptance: ed.requires_acceptance,
    terminationDate:
      employee &&
      employee.terminationDate &&
      moment(employee.terminationDate, apiShortDate),
    acceptedAt: ed.accepted_at && moment(ed.accepted_at),
  } as DocumentAcceptanceStatus;
};

const removeDocumentsWithoutEmployee = (doc: APIDocument) =>
  doc.employee_documents.length > 0;
const removeDocumentsThatNeverNeededAction = (doc: DocumentAcceptanceStatus) =>
  doc.requiresAcceptance || isMoment(doc.acceptedAt);

export const documentOptions = (state: RootState) =>
  state.file.documents.map(document => ({
    key: document.id,
    value: document.id,
    text: document.file_name,
  }));
