import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import * as selectors from "../orders/orders-selectors";
import {
  DELETE_CONTENT_TEMPLATE_VALUES,
  DELETE_CONTENT_TEMPLATE_VALUES_SUCCEEDED,
  FETCH_CONTENTS,
  FETCH_CONTENTS_FAILED,
  FETCH_CONTENTS_SUCCEEDED,
  PATCH_CONTENT_TEMPLATE_VALUES,
  PATCH_CONTENT_TEMPLATE_VALUES_SUCCEEDED,
  POST_CONTENT_TEMPLATE_VALUES,
  POST_CONTENT_TEMPLATE_VALUES_SUCCEEDED,
  UPDATE_CONTENT,
  UPDATE_CONTENT_FAILED,
  UPDATE_CONTENT_SUCCEEDED,
} from "./content-actions";
const dataFormatter = new Jsona();

const fetchContentsUrl = "/content-templates?include=contentTemplateValues";
const updateContentUrl = (contentId: string) =>
  `/content-template-values/${contentId}`;

function* fetchContentsRequested() {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    const response = yield call(() => callApi(fetchContentsUrl, token));
    yield put({
      type: FETCH_CONTENTS_SUCCEEDED,
      contents: dataFormatter.deserialize(response),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
      yield put({ type: FETCH_CONTENTS_FAILED, message: e.message });
    }
  }
}

export function* watchFetchContent() {
  yield takeEvery(FETCH_CONTENTS, fetchContentsRequested);
}

export interface ContentUpdateObject {
  name?: string;
  description?: string;
  type?: string;
  content_type?: string;
  raw_content?: string;
  locale?: string;
}

interface UpdateContentAction {
  type: "UPDATE_CONTENT";
  content_id: string;
  updateObject: ContentUpdateObject;
}

function* updateContent(action: UpdateContentAction) {
  try {
    const token = yield select(selectors.token);

    const response = yield call(() =>
      callApi(updateContentUrl(action.content_id), token, "PUT", {
        data: {
          id: action.content_id,
          type: "contentTemplateValues",
          attributes: {
            value: action.updateObject.raw_content,
            locale: action.updateObject.locale,
          },
        },
      })
    );
    yield put({ type: UPDATE_CONTENT_SUCCEEDED, contents: response.data });
    yield put({
      type: FETCH_CONTENTS,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
      yield put({ type: UPDATE_CONTENT_FAILED, message: e.message });
    }
  }
}

export function* watchUpdateContent() {
  yield takeEvery(UPDATE_CONTENT, updateContent);
}

const postContentTemplateValuesUrl = (contentTemplatesId: string) =>
  `/content-templates/${contentTemplatesId}/content-template-values`;

function* postContentTemplateValues(action) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";
    const body = dataFormatter.serialize({
      stuff: { ...action.payload, type: "contentTemplateValues" },
    });

    const response = yield call(() =>
      callApi(
        postContentTemplateValuesUrl(action.contentTemplatesId),
        token,
        method,
        body
      )
    );

    yield put({
      type: POST_CONTENT_TEMPLATE_VALUES_SUCCEEDED,
      contentTemplateValue: dataFormatter.deserialize(response),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchPostContentTemplateValues() {
  yield takeEvery(POST_CONTENT_TEMPLATE_VALUES, postContentTemplateValues);
}

const patchDeleteContentTemplateValuesUrl = (contentTemplateValuesId: string) =>
  `/content-template-values/${contentTemplateValuesId}`;

function* patchContentTemplateValues(action) {
  try {
    const token = yield select(selectors.token);
    const method = "PATCH";

    const body = dataFormatter.serialize({
      stuff: { ...action.payload, type: "contentTemplateValues" },
    });

    const response = yield call(() =>
      callApi(
        patchDeleteContentTemplateValuesUrl(action.payload.id),
        token,
        method,
        body
      )
    );

    yield put({
      type: PATCH_CONTENT_TEMPLATE_VALUES_SUCCEEDED,
      contentTemplateValue: dataFormatter.deserialize(response),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchPatchContentTemplateValues() {
  yield takeEvery(PATCH_CONTENT_TEMPLATE_VALUES, patchContentTemplateValues);
}

function* deleteContentTemplateValues(action) {
  try {
    const token = yield select(selectors.token);
    const method = "DELETE";
    yield call(() =>
      callApi(
        patchDeleteContentTemplateValuesUrl(action.contentTemplateValuesId),
        token,
        method
      )
    );

    yield put({
      type: DELETE_CONTENT_TEMPLATE_VALUES_SUCCEEDED,
      contentTemplateValuesId: action.contentTemplateValuesId,
      contentTemplateId: action.contentTemplateId,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchDeleteContentTemplateValues() {
  yield takeEvery(DELETE_CONTENT_TEMPLATE_VALUES, deleteContentTemplateValues);
}
