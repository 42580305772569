import React, { FunctionComponent } from "react";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";

const currencyOptions = [
  { key: "EUR", value: "EUR", flag: "eu", text: "EUR" },
  { key: "kr", value: "kr", flag: "", text: "kr" },
  { key: "NOK", value: "NOK", flag: "no", text: "NOK" },
  { key: "SEK", value: "SEK", flag: "se", text: "SEK" },
  { key: "USD", value: "USD", flag: "us", text: "USD" },
];

interface Props {
  value: string;
  name: string;
  onChange: (e, { value, name }) => void;
}

const CurrencyDropdown: FunctionComponent<Props & DropdownItemProps> = ({
  value,
  name,
  onChange,
}) => (
  <Dropdown
    placeholder="Select Country"
    fluid={true}
    search={true}
    selection={true}
    options={currencyOptions}
    value={value}
    onChange={(e, { value }) => onChange(e, { value, name })}
  />
);

export default CurrencyDropdown;
