import numeral from "numeral";
import { FunctionComponent } from "react";
import React from "react";
import { Icon, Popup, Table } from "semantic-ui-react";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import { formatNumber, sumNumbers } from "src/common/utils/utils";
import CapOnGainHeaderCell from "src/employee-portal/instrument-page/common/cap-on-gain-header-cell";
import { DateFormatState } from "src/reducers/date-format-reducer";
import Content from "../../../admin-portal/texts/content";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "../../../common/components/currency/currency-formatter";
import { hasExpired, removeExpiredAwards } from "../instruments-page";
import {
  AwardGainFunction,
  calculateGains,
  FlatAward,
  hasCapOnGain,
  hasOriginalQuantity,
  hasPerformanceCriteria,
} from "../instruments-reducer";
import OptionTableRow from "./optionlike-table-row";

interface Props {
  options: FlatAward[];
  sharePriceToday: number;
  showPurchasePrice?: boolean;
  showExpired: boolean;
  dateFormat: DateFormatState;
  awardGain: AwardGainFunction;
  overrideCurrency?: string;
  totalGain: number;
}

const OptionLikeTable: FunctionComponent<Props> = ({
  options,
  sharePriceToday,
  showPurchasePrice,
  showExpired,
  dateFormat,
  awardGain,
  totalGain,
  overrideCurrency,
}) => {
  const showExercisedQuantity = options.some(o => o.exercisedQuantity !== 0);
  const showCapOnGain = options.some(hasCapOnGain);
  const showOriginalQuantity = options.some(hasOriginalQuantity);
  const totalOriginalQuantity = options
    .map(o => o.originalQuantity || 0)
    .reduce(sumNumbers, 0);
  const exercisedQuantity =
    -1 * options.reduce((accu, current) => accu + current.exercisedQuantity, 0);
  const optionQuantity = options
    .filter(removeExpiredAwards)
    .reduce((accu, current) => accu + current.quantity, 0);
  const hasPerformance = options.some(hasPerformanceCriteria);
  const totalExercisePrice = options
    .filter(removeExpiredAwards)
    .reduce((accu, o) => o.quantity * o.strike + accu, 0);

  const shownOptions = showExpired
    ? options
    : options.filter(removeExpiredAwards);
  return (
    <div className="sideways-scrollable block-l">
      <CurrencyFormatter>
        {(formatter: CurrencyFormatterProps) => (
          <Table celled={true} unstackable={true} textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Content id={"instruments.program.label"} />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id={"instruments.grant.label"} />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id={"instruments.vesting.label"} />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id={"instruments.expiry.label"} />
                </Table.HeaderCell>
                {showOriginalQuantity && (
                  <Table.HeaderCell>
                    <Content id={"instruments.originalquantity.label"} />
                    <Popup trigger={<Icon name="question circle outline" />}>
                      <Popup.Content>
                        <Content id={"instruments.originalquantity.popup"} />
                      </Popup.Content>
                    </Popup>
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Content id={"instruments.quantity.label"} />
                </Table.HeaderCell>
                {showExercisedQuantity && (
                  <Table.HeaderCell>
                    <Content id={"instruments.exercisequantity.label"} />
                  </Table.HeaderCell>
                )}
                {showPurchasePrice && (
                  <Table.HeaderCell>
                    <Content id={"instruments.purchaseprice.label"} />{" "}
                    <Popup
                      trigger={<Icon name="question circle outline" />}
                      content="The price you paid to purchase this warrant."
                    />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Content id={"instruments.strike.label"} />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id={"instruments.totalstrike.label"} />{" "}
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    content={<Content id={"instruments.totalstrike.popup"} />}
                  />
                </Table.HeaderCell>
                {showCapOnGain && <CapOnGainHeaderCell />}
                <Table.HeaderCell>
                  <Content id={"instruments.totalvalue.label"} />
                </Table.HeaderCell>
                {hasPerformance && (
                  <Table.HeaderCell>
                    <Content id={"instruments.performance.label"} />
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {shownOptions.map((award: FlatAward) => (
                <OptionTableRow
                  {...award}
                  key={award.trancheId}
                  showExercisedQuantity={showExercisedQuantity}
                  totalGain={awardGain(sharePriceToday, award).gain}
                  showPerformance={hasPerformance}
                  showPurchasePrice={showPurchasePrice}
                  showOriginalQuantity={showOriginalQuantity}
                  purchasePrice={award.purchasePrice}
                  hasExpired={hasExpired(award)}
                  formatter={formatter}
                  dateFormat={dateFormat}
                  overrideCurrency={overrideCurrency}
                  showCapOnGain={showCapOnGain}
                  capped={awardGain(sharePriceToday, award).capped}
                />
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <Content id={"instruments.sum.label"} />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" />
                <Table.HeaderCell textAlign="right" />
                <Table.HeaderCell textAlign="right" />
                {showOriginalQuantity && (
                  <Table.HeaderCell textAlign="right">
                    {formatNumber(totalOriginalQuantity)}
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell textAlign="right">
                  {numeral(optionQuantity).format()}
                </Table.HeaderCell>
                {showExercisedQuantity && (
                  <Table.HeaderCell textAlign="right">
                    {numeral(exercisedQuantity).format()}
                  </Table.HeaderCell>
                )}
                {showPurchasePrice && <Table.HeaderCell textAlign="right" />}
                <Table.HeaderCell textAlign="right" />
                <Table.HeaderCell textAlign="right">
                  <CurrencyConversionView
                    value={totalExercisePrice}
                    decimals={0}
                  />
                </Table.HeaderCell>
                {showCapOnGain && <Table.HeaderCell />}
                <Table.HeaderCell textAlign="right">
                  <CurrencyConversionView value={totalGain} decimals={0} />
                </Table.HeaderCell>
                {hasPerformance && <Table.HeaderCell textAlign="right" />}
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </CurrencyFormatter>
    </div>
  );
};

export default OptionLikeTable;
