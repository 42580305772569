export const CREATE_TRANSACTIONS = "CREATE_TRANSACTIONS";
export const CREATE_TRANSACTIONS_SUCCEEDED = "CREATE_TRANSACTIONS_SUCCEEDED";
export const CREATE_TRANSACTIONS_FAILED = "CREATE_TRANSACTIONS_FAILED";

export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const UPDATE_TRANSACTION_SUCCEEDED = "UPDATE_TRANSACTION_SUCCEEDED";
export const UPDATE_TRANSACTION_FAILED = "UPDATE_TRANSACTION_FAILED";

export const UPDATE_TRANSACTION_SECONDARY = "UPDATE_TRANSACTION_SECONDARY";
export const UPDATE_TRANSACTION_SECONDARY_SUCCEEDED =
  "UPDATE_TRANSACTION_SECONDARY_SUCCEEDED";
export const UPDATE_TRANSACTION_SECONDARY_FAILED =
  "UPDATE_TRANSACTION_SECONDARY_FAILED";

export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const DELETE_TRANSACTION_SUCCEEDED = "DELETE_TRANSACTION_SUCCEEDED";
export const DELETE_TRANSACTION_FAILED = "DELETE_TRANSACTION_FAILED";

export enum AdjustmentType {
  ADJUSTMENT_VESTING_DATE = "ADJUSTMENT_VESTING_DATE",
  ADJUSTMENT_STRIKE = "ADJUSTMENT_STRIKE",
}
