export const FETCH_CONTENTS = "FETCH_CONTENTS";
export const FETCH_CONTENTS_FAILED = "FETCH_CONTENTS_FAILED";
export const FETCH_CONTENTS_SUCCEEDED = "FETCH_CONTENTS_SUCCEEDED";

export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const UPDATE_CONTENT_FAILED = "UPDATE_CONTENT_FAILED";
export const UPDATE_CONTENT_SUCCEEDED = "UPDATE_CONTENT_SUCCEEDED";

export const POST_CONTENT_TEMPLATE_VALUES = "POST_CONTENT_TEMPLATE_VALUES";
export const POST_CONTENT_TEMPLATE_VALUES_SUCCEEDED =
  "POST_CONTENT_TEMPLATE_VALUES_SUCCEEDED";

export const PATCH_CONTENT_TEMPLATE_VALUES = "PATCH_CONTENT_TEMPLATE_VALUES";
export const PATCH_CONTENT_TEMPLATE_VALUES_SUCCEEDED =
  "PATCH_CONTENT_TEMPLATE_VALUES_SUCCEEDED";

export const DELETE_CONTENT_TEMPLATE_VALUES = "DELETE_CONTENT_TEMPLATE_VALUES";
export const DELETE_CONTENT_TEMPLATE_VALUES_SUCCEEDED =
  "DELETE_CONTENT_TEMPLATE_VALUES_SUCCEEDED";
