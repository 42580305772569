import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { Method } from "src/common/api/api-helper";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { toApiOrder } from "src/common/api/orders-response-mapper";
import {
  FETCH_WINDOW_ORDERS,
  WINDOW_COMPLETE_CASHLESS_ORDERS,
  WINDOW_COMPLETE_CASHLESS_ORDERS_FAILED,
  WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS,
  WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS_FAILED,
  WINDOW_COMPLETE_SALE_CASHLESS_ORDERS,
  WINDOW_COMPLETE_SALE_CASHLESS_ORDERS_FAILED,
  WINDOW_STATE_START_PROCESSING,
  WINDOW_STATE_START_PROCESSING_FAILED,
  WINDOW_STATE_START_PROCESSING_SUCCEEDED,
} from "./window-actions";
import {
  DELETE_WINDOW,
  DELETE_WINDOW_FAILED,
  DELETE_WINDOW_SUCCEEDED,
  FETCH_WINDOW,
  FETCH_WINDOW_FAILED,
  FETCH_WINDOW_ORDERS_FAILED,
  FETCH_WINDOW_ORDERS_SUCCEEDED,
  FETCH_WINDOW_SUCCEEDED,
  POST_WINDOW,
  POST_WINDOW_FAILED,
  POST_WINDOW_SUCCEEDED,
  PUT_WINDOW,
  PUT_WINDOW_FAILED,
  PUT_WINDOW_SUCCEEDED,
} from "./window-actions";
import * as selectors from "./window-selectors";

const EXERCISE_WINDOW_URL = "/windows?tenantId=";
const OPTIONS_EXERCISE_WINDOW_URL = "/windows/";
const windowOrdersUrl = (windowId: string, tenantId: string) =>
  `/windows/${windowId}/orders?tenantId=${tenantId}`;
const windowStartProcessingUrl = (windowId: string, tenantId: string) =>
  `/windows/${windowId}/start_processing_orders?tenantId=${tenantId}`;

function* postExerciseWindowRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const windowResponse = yield call(() =>
      callApi(EXERCISE_WINDOW_URL + tenantId, token, method, action.window)
    );
    yield put({ type: POST_WINDOW_SUCCEEDED, window: windowResponse.data });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: POST_WINDOW_FAILED, message: e.message });
    }
  }
}

export function* watchPostExerciseWindow() {
  yield takeEvery(POST_WINDOW, postExerciseWindowRequested);
}

function* fetchExerciseWindowRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const windowResponse = yield call(() =>
      callApi(EXERCISE_WINDOW_URL + tenantId, token)
    );
    yield put({
      type: FETCH_WINDOW_SUCCEEDED,
      windows: windowResponse.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_WINDOW_FAILED, message: e.message });
    }
  }
}

export function* watchFetchExerciseWindow() {
  yield takeEvery(FETCH_WINDOW, fetchExerciseWindowRequested);
}

function* deleteExerciseWindowRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const windowId = action.windowId;
    const method = "DELETE";

    yield call(() =>
      callApi(
        OPTIONS_EXERCISE_WINDOW_URL + windowId + "?tenantId=" + tenantId,
        token,
        method
      )
    );
    yield put({ type: DELETE_WINDOW_SUCCEEDED, windowId });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_WINDOW_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteExerciseWindow() {
  yield takeEvery(DELETE_WINDOW, deleteExerciseWindowRequested);
}

function* putExerciseWindowRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const windowId = action.windowId;
    const method = "PUT";

    const windowResponse = yield call(() =>
      callApi(
        OPTIONS_EXERCISE_WINDOW_URL + windowId + "?tenantId=" + tenantId,
        token,
        method,
        action.window
      )
    );
    yield put({ type: PUT_WINDOW_SUCCEEDED, window: windowResponse.data });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: PUT_WINDOW_FAILED, message: e.message });
    }
  }
}

export function* watchPutExerciseWindow() {
  yield takeEvery(PUT_WINDOW, putExerciseWindowRequested);
}

function* fetchWindowOrdersRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const ordersResponse = yield call(() =>
      callApi(windowOrdersUrl(action.windowId, tenantId), token)
    );

    yield put({
      type: FETCH_WINDOW_ORDERS_SUCCEEDED,
      orders: ordersResponse.data.map(toApiOrder),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_WINDOW_ORDERS_FAILED, message: e.message });
    }
  }
}

export function* watchFetchWindowOrdersRequested() {
  yield takeEvery(FETCH_WINDOW_ORDERS, fetchWindowOrdersRequested);
}

function* windowStateStartProcessingRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);
    const { transactionDate } = action;

    yield call(() =>
      callApi(
        windowStartProcessingUrl(action.windowId, tenantId),
        token,
        Method.POST,
        {
          transactionDate,
        }
      )
    );

    yield put({
      type: WINDOW_STATE_START_PROCESSING_SUCCEEDED,
      windowId: action.windowId,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: WINDOW_STATE_START_PROCESSING_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchWindowStateStartProcessingRequested() {
  yield takeEvery(
    WINDOW_STATE_START_PROCESSING,
    windowStateStartProcessingRequested
  );
}

const windowCompleteExerciseAndHoldOrdersUrl = (
  windowId: string,
  tenantId: string
) =>
  `/windows/${windowId}/complete_exercise_and_hold_orders?tenantId=${tenantId}`;

function* windowCompleteExerciseAndHoldOrders(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    yield call(() =>
      callApi(
        windowCompleteExerciseAndHoldOrdersUrl(action.windowId, tenantId),
        token,
        Method.POST,
        {
          sharePrice: action.sharePrice,
        }
      )
    );

    yield put({
      type: FETCH_WINDOW,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchWindowCompleteExerciseAndHoldOrders() {
  yield takeEvery(
    WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS,
    windowCompleteExerciseAndHoldOrders
  );
}

const windowCompleteCashlessOrdersUrl = (windowId: string, tenantId: string) =>
  `/windows/${windowId}/complete_cashless_orders?tenantId=${tenantId}`;

function* windowCompleteCashlessOrders(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    yield call(() =>
      callApi(
        windowCompleteCashlessOrdersUrl(action.windowId, tenantId),
        token,
        Method.POST
      )
    );

    yield put({
      type: FETCH_WINDOW,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: WINDOW_COMPLETE_CASHLESS_ORDERS_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchWindowCompleteCashlessOrders() {
  yield takeEvery(
    WINDOW_COMPLETE_CASHLESS_ORDERS,
    windowCompleteCashlessOrders
  );
}

const windowCompleteSaleCashlessOrdersUrl = (
  windowId: string,
  tenantId: string
) => `/windows/${windowId}/complete_sale_cashless_orders?tenantId=${tenantId}`;

function* windowCompleteSaleCashlessOrders(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    yield call(() =>
      callApi(
        windowCompleteSaleCashlessOrdersUrl(action.windowId, tenantId),
        token,
        Method.POST,
        {
          sharePrice: action.sharePrice,
        }
      )
    );

    yield put({
      type: FETCH_WINDOW,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: WINDOW_COMPLETE_SALE_CASHLESS_ORDERS_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchWindowCompleteSaleCashlessOrders() {
  yield takeEvery(
    WINDOW_COMPLETE_SALE_CASHLESS_ORDERS,
    windowCompleteSaleCashlessOrders
  );
}
