export const FETCH_SYSADMINS_SUCCEEDED = "FETCH_SYSADMINS_SUCCEEDED";
export const FETCH_SYSADMINS_FAILED = "FETCH_SYSADMINS_FAILED";
export const FETCH_SYSADMINS = "FETCH_SYSADMINS";

export const POST_SYSADMIN_SUCCEEDED = "POST_SYSADMIN_SUCCEEDED";
export const POST_SYSADMIN_FAILED = "POST_SYSADMIN_FAILED";
export const POST_SYSADMIN = "POST_SYSADMIN";

export const SELECT_SYSADMIN = "SELECT_SYSADMIN";

export const DELETE_SYSADMIN_SUCCEEDED = "DELETE_SYSADMIN_SUCCEEDED";
export const DELETE_SYSADMIN_FAILED = "DELETE_SYSADMIN_FAILED";
export const DELETE_SYSADMIN = "DELETE_SYSADMIN";
