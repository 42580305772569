import stringify from "jquery-param";
import moment, { Moment } from "moment";
import numeral from "numeral";
import { Employee } from "../../admin-portal/employees/employee-reducer";

const stringOfZeros = (numberOfZeros: number) =>
  Array(numberOfZeros)
    .fill("0")
    .join("");

const createCurrencyFormat = (decimals: number) =>
  decimals > 0 ? `0,0.${stringOfZeros(decimals)}` : "0,0";

export const createFormatCurrencyFunction = (
  currencyCode: string,
  abbriviate = false
) => (number: number, decimals?: number): string =>
  `${currencyCode} ${numeral(number).format(
    `${createCurrencyFormat(decimals || 0)}${abbriviate ? "a" : ""}`
  )}`;
export const createFormatSharePriceFunction = (currencyCode: string) => (
  number: number
): string =>
  `${currencyCode} ${numeral(number).format(createCurrencyFormat(3))}`;

export const formatNumber = (number: number, decimal?: number) =>
  decimal
    ? `${numeral(number).format("0,0")} ${decimal}`
    : numeral(number).format();

export const formatCurrencyConversionFactor = (number: number) =>
  numeral(number).format("0.000000");

export const formatPercentage = (number: number | string, decimals = 0) =>
  numeral(typeof number === "number" ? number : parseFloat(number)).format(
    `0.${stringOfZeros(decimals)} %`
  );
export const formatCurrency = (number: number, currency?: string) =>
  currency
    ? `${numeral(number).format("0,0.000")} ${currency}`
    : numeral(number).format("0,0.000 $");
export const formatCurrency2Decimals = (number: number, currency?: string) =>
  currency
    ? `${numeral(number).format("0,0.00")} ${currency}`
    : numeral(number).format("0,0.00 $");
export const formatSharePrice = (number: number, currency?: string) =>
  currency
    ? `${numeral(number).format("0,0.000")} ${currency}`
    : numeral(number).format("0,0.000 $");

export const formatDate = (date: string | Moment, dateFormat: string) =>
  moment(date, dateFormat).format(dateFormat);
export const formatShortDate = (date: string | Moment) =>
  moment(date).format(norwegianShortDate);
export const formatLongDate = (date: string | Moment) =>
  moment(date).format("ll");

// For use in reduce functions to sum the value of a property
export const sum = propertyKey => (accumulator, previous) =>
  previous[propertyKey] + accumulator;
export const sumNumbers = (accumulator, previous) => previous + accumulator;

export const flatten = (list: any[]) =>
  list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

export const flattenOneLevel = (list: any[]) =>
  list.reduce((a, b) => a.concat(b), []);

export const setAllValues = (keys: string[], value: any): object =>
  keys.reduce((acc, key) => ({ ...acc, [key]: value }), {});

export const norwegianShortDate = "DD.MM.YY";
export const norwegianShortDateLongYear = "DD.MM.YYYY";
export const norwegianShortDateLongYearHours = "DD.MM.YYYY HH:mm";
export const apiShortDate = "YYYY-MM-DD";

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const removeDuplicates = (array, property) =>
  array.filter(
    (obj, pos, arr) =>
      arr.map(mapObj => mapObj[property]).indexOf(obj[property]) === pos
  );

export const removeNulls = (element: any): boolean => element !== null;
export const removeEmptyArrays = (array: [any]): boolean => array.length > 0;

export const notNullOrUndefined = (element: any): boolean =>
  element !== null && element !== undefined;

export const changePunctuationForComma = (string: string) =>
  string.replace(".", ",");
export const changeCommaForPunctuation = (string: string) =>
  string.replace(",", ".");

export const prepareDateForBackend = (date: string | Moment) =>
  moment(date).format(apiShortDate);

export const employeeName = (employee: Employee | Api.V1.Employee) =>
  `${employee.firstName} ${employee.lastName}`;

export const employeeNameForSnakeCaseAttr = (employee: {
  first_name: string;
  last_name: string;
}) => `${employee.first_name} ${employee.last_name}`;

export const sortAlphabetically = (property: string) => (
  objectA: any,
  objectB: any
) => {
  const a = objectA[property];
  const b = objectB[property];

  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const replaceAll = (string, search, replacement) =>
  string.split(search).join(replacement);

export const yesOrNo = (value: boolean): string => (value ? "Yes" : "No");

export const buildURL = (url: string, params: Object = {}) => {
  const searchParams = stringify(params);
  const searchParamsResult = searchParams == "" ? "" : `?${searchParams}`;
  return `${url}${searchParamsResult}`;
};

export const toNumberOrDefault = (
  numberString: string,
  defaultNumber: number
): number =>
  isNaN(parseFloat(numberString)) ? defaultNumber : parseFloat(numberString);

export const cloneDeepSimple = (
  objectOrArray: any[] | object
): any[] | object => JSON.parse(JSON.stringify(objectOrArray));
