import { PurchaseType } from "src/admin-portal/awards/purchase/purchase-page";
import { PurchaseWindow } from "src/common/data/data";
import { sumNumbers } from "src/common/utils/utils";
import { RootState } from "src/reducers/all-reducers";

export const purchaseAvailable = (state: RootState): boolean => {
  const window = state.user.currentPurchaseWindow;
  if (!window) {
    return false;
  }
  const { purchase_opportunity, purchasable_award } = window;

  const hasValidInstrumentQuantityType =
    purchase_opportunity &&
    purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY &&
    purchase_opportunity.purchasedAmount < purchase_opportunity.maximumAmount;

  const hasValidCashType =
    purchase_opportunity &&
    purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_CASH &&
    parseFloat(purchase_opportunity.cash_amount_used || "0") <
      parseFloat(purchase_opportunity.maximum_cash_amount);
  const hasValidPurchasableAward = !!purchasable_award;

  return (
    hasValidInstrumentQuantityType ||
    hasValidCashType ||
    hasValidPurchasableAward
  );
};

/**
 * Returns true if no purchase has happened, false if partly of fully purchase has occurred (or no purchase is available)
 * @param state
 */
export const purchaseAvailableAndPrestine = (state: RootState): boolean => {
  const isPurchaseAvailable = purchaseAvailable(state);
  if (!isPurchaseAvailable) {
    return false;
  }

  const { purchase_opportunity } = state.user.currentPurchaseWindow;
  const partlyPurchased =
    purchase_opportunity &&
    purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY &&
    purchase_opportunity.purchasedAmount !==
      purchase_opportunity.maximumAmount &&
    purchase_opportunity.purchasedAmount !== 0;

  const partlyPurchasedCashPurchase =
    purchase_opportunity &&
    purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_CASH &&
    parseFloat(purchase_opportunity.cash_amount_used) !==
      parseFloat(purchase_opportunity.maximum_cash_amount) &&
    parseFloat(purchase_opportunity.cash_amount_used) !== 0;

  return !partlyPurchased && !partlyPurchasedCashPurchase;
};

export interface PurchaseInfo {
  type: PurchaseType;
  showQuantityStep: boolean;
  showCashAmountStep: boolean;
  showShareDepositoryStep: boolean;
  purchasePrice?: number;
  discount?: number;
  maxCashAmount?: number;
  instrumentType: string;
  documentId: string;
  purchasableQuantity?: number;
  purchasableCashAmount?: number;
  purchaseOpportunityId?: string;
  awardId?: string;
}

export const getPurchaseInfoForCurrentWindow = (
  state: RootState
): PurchaseInfo => {
  const window = state.user.currentPurchaseWindow;
  return getPurchaseInfo(window);
};

export const getPurchaseInfoForNextWindow = (
  state: RootState
): PurchaseInfo => {
  const window = state.user.nextPurchaseWindow;
  return getPurchaseInfo(window);
};

const getPurchaseInfo = (window: PurchaseWindow): PurchaseInfo => {
  if (!window) {
    return null;
  }
  const { purchase_opportunity, purchasable_award } = window;
  if (purchase_opportunity) {
    if (
      purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY
    ) {
      return {
        type: PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY,
        showShareDepositoryStep: purchase_opportunity.showShareDepository,
        showQuantityStep: true,
        showCashAmountStep: false,
        purchasePrice: parseFloat(purchase_opportunity.price),
        instrumentType: purchase_opportunity.instrument,
        documentId: purchase_opportunity.documentId,
        purchaseOpportunityId: purchase_opportunity.id,
        purchasableQuantity:
          purchase_opportunity.maximumAmount -
          purchase_opportunity.purchasedAmount,
      };
    }
    const maxCashAmount = parseFloat(purchase_opportunity.maximum_cash_amount);
    const cashSpent = parseFloat(purchase_opportunity.cash_amount_used || "0");
    return {
      type: PurchaseType.PURCHASE_OPPORTUNITY_CASH,
      showShareDepositoryStep: purchase_opportunity.showShareDepository,
      showQuantityStep: false,
      showCashAmountStep: true,
      discount: parseFloat(purchase_opportunity.discount),
      maxCashAmount,
      instrumentType: purchase_opportunity.instrument,
      documentId: purchase_opportunity.documentId,
      purchaseOpportunityId: purchase_opportunity.id,
      purchasableCashAmount: maxCashAmount - cashSpent,
    };
  } else if (purchasable_award && purchasable_award.documents[0]) {
    const price = purchasable_award.incentive_sub_program.purchase_config.price;

    const quantity = purchasable_award.tranches
      .map(t => t.quantity)
      .reduce(sumNumbers);
    return {
      type: PurchaseType.PURCHASABLE_AWARD,
      showShareDepositoryStep: false,
      showQuantityStep: false,
      showCashAmountStep: false,
      purchasePrice: parseFloat(price),
      instrumentType:
        purchasable_award.incentive_sub_program.instrument_type_id,
      documentId: purchasable_award.documents[0].id,
      purchasableQuantity: quantity,
      awardId: purchasable_award.id,
    };
  } else {
    return null;
  }
};
