import { push } from "connected-react-router";
import Jsona from "jsona";
import Raven from "raven-js";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { UpdateTransaction } from "src/admin-portal/reports/constants";
import { batchRequests } from "src/common/sagas/batch-requests-saga";
import { callApi, NOT_AUTHORIZED } from "../../../common/api/api-helper";
import { FETCH_TENANT_VESTING_EVENTS } from "../award-actions";
import * as selectors from "../award-selectors";
import {
  CREATE_TRANSACTIONS,
  CREATE_TRANSACTIONS_FAILED,
  CREATE_TRANSACTIONS_SUCCEEDED,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_FAILED,
  DELETE_TRANSACTION_SUCCEEDED,
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_FAILED,
  UPDATE_TRANSACTION_SECONDARY,
  UPDATE_TRANSACTION_SECONDARY_FAILED,
  UPDATE_TRANSACTION_SECONDARY_SUCCEEDED,
  UPDATE_TRANSACTION_SUCCEEDED,
} from "./transaction-actions";

const updateTransactionUrl = transactionId => `/transactions/${transactionId}`;
const createTransactionUrl = tranche_id =>
  `/tranches/${tranche_id}/transactions`;

const dataFormatter = new Jsona();

interface UpdateTransactionAction {
  type: "UPDATE_TRANSACTION";
  transactions: UpdateTransaction[];
}

function* updateTransaction(action: UpdateTransactionAction) {
  try {
    const token = yield select(selectors.token);
    const method = "PUT";

    const res = yield all(
      action.transactions.map(transaction =>
        call(() => {
          const { id, ...attributes } = transaction;
          return callApi(updateTransactionUrl(id), token, method, {
            data: {
              id,
              type: "transactions",
              attributes,
            },
          });
        })
      )
    );

    yield put({
      type: UPDATE_TRANSACTION_SUCCEEDED,
      transactions: res.map(r => dataFormatter.deserialize(r)),
    });
    window.location.reload();
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: UPDATE_TRANSACTION_FAILED, message: e.message });
    }
  }
}

export function* watchUpdateTransaction() {
  yield takeEvery(UPDATE_TRANSACTION, updateTransaction);
}

const transactionIncludeParam = [
  "tranche.award.employee.entity",
  "tranche.tranchePerformanceRules.performanceRule",
  "tranche.award.incentiveSubProgram.incentiveProgram",
  "tranche.transactions",
].join(",");

function* updateTransactionSecondary(action) {
  try {
    const token = yield select(selectors.token);
    const method = "PATCH";

    const res = yield batchRequests(
      20,
      1000,
      action.transactions.map(transaction => () => {
        const { id, ...attributes } = transaction;
        return callApi(
          updateTransactionUrl(id) + "?include=" + transactionIncludeParam,
          token,
          method,
          {
            data: {
              id,
              type: "transactions",
              attributes,
            },
          }
        );
      })
    );

    yield put({
      type: UPDATE_TRANSACTION_SECONDARY_SUCCEEDED,
      transactions: res.map(r => dataFormatter.deserialize(r)),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: UPDATE_TRANSACTION_SECONDARY_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchUpdateTransactionSecondary() {
  yield takeEvery(UPDATE_TRANSACTION_SECONDARY, updateTransactionSecondary);
}

interface CreateTransactionsAction {
  type: "CREATE_TRANSACTIONS";
  transactions: Array<{
    trancheId: string;
    transaction: Api.V1.Transaction;
  }>;
}

function* createTransactions(action: CreateTransactionsAction) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";

    const promiseCreators = action.transactions.map(transaction => () =>
      callApi(
        createTransactionUrl(transaction.trancheId) +
          "?include=" +
          transactionIncludeParam,
        token,
        method,
        dataFormatter.serialize({ stuff: transaction.transaction })
      )
    );

    const res = yield batchRequests(25, 500, promiseCreators);

    yield put({
      type: CREATE_TRANSACTIONS_SUCCEEDED,
      transactions: res.map(r => dataFormatter.deserialize(r)),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: CREATE_TRANSACTIONS_FAILED, message: e.message });
    }
  }
}

export function* watchCreateTransactions() {
  yield takeEvery(CREATE_TRANSACTIONS, createTransactions);
}

function* deleteTransactionRequested(action) {
  try {
    const token = yield select(selectors.token);
    const method = "DELETE";
    const { transactionId, trancheId } = action;
    yield call(() => callApi("/transactions/" + transactionId, token, method));

    yield put({
      type: DELETE_TRANSACTION_SUCCEEDED,
      transactionId,
      trancheId,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_TRANSACTION_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteTransaction() {
  yield takeEvery(DELETE_TRANSACTION, deleteTransactionRequested);
}
