import moment from "moment";
import React, { FunctionComponent, useState } from "react";
import { connect, MapStateToProps } from "react-redux";
import { match, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { Button, Icon, Popup, Table } from "semantic-ui-react";
import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import ShowItemsCheckBox from "src/common/components/show-items-checkbox";
import SpinnerInline from "src/common/components/spinner-inline.tsx";
import { sortMultipleLevels } from "src/common/utils/sort";
import { formatNumber, sumNumbers } from "src/common/utils/utils";
import {
  currencyConversionFactor,
  userCurrencyCode,
} from "src/employee-portal/employee-portal-selectors";
import { SharePrice } from "src/employee-portal/exercise/exercise-router";
import OptionLikeTable from "src/employee-portal/instrument-page/option-like/optionlike-table";
import PSULikeTable from "src/employee-portal/instrument-page/psu-like/psu-like-table";
import RSULikeTable from "src/employee-portal/instrument-page/rsu-like/rsu-like-table";
import { RootState } from "src/reducers/all-reducers";
import { DateFormatState } from "src/reducers/date-format-reducer";
import {
  awardGain,
  AwardGainFunction,
  FlatAward,
  IndividualInstrumentState,
} from "./instruments-reducer";
import "./instruments.less";

interface Props {
  match: match<{}>;
}

interface StateProps {
  warrant: IndividualInstrumentState;
  rsa: IndividualInstrumentState;
  rsu: IndividualInstrumentState;
  psu: IndividualInstrumentState;
  option: IndividualInstrumentState;
  funds: IndividualInstrumentState;
  cash: IndividualInstrumentState;
  subscriptionRights: IndividualInstrumentState;
  isFetchingWelcomeData: boolean;
  sharePrice?: SharePrice;
  totalQuantity: number;
  totalVestedQuantity: number;
  totalGain: number;
  totalVestedGain: number;
  dateFormat: DateFormatState;
  awardGain: AwardGainFunction;
  currencyCode?: string;
}

export const vestedAwards = (award: FlatAward): boolean =>
  moment().diff(award.vestedDate, "days") >= 0;
export const removeExpiredAwards = (award: FlatAward): boolean =>
  !hasExpired(award);
export const hasExpired = (award: FlatAward): boolean =>
  moment().isAfter(award.expiryDate, "days");

const IndividualInstrumentSummaryRow: FunctionComponent<{
  instrument: IndividualInstrumentState;
  title: string;
  linkTo: string;
  showPerformanceColumns: boolean;
}> = ({ instrument, title, linkTo, showPerformanceColumns }) => (
  <Table.Row>
    <Table.Cell>{title}</Table.Cell>
    <Table.Cell textAlign="right">
      {formatNumber(instrument.totalQuantity)}
    </Table.Cell>
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={instrument.gain.totalGain} decimals={0} />
    </Table.Cell>
    {showPerformanceColumns && (
      <>
        <Table.Cell textAlign="right">Not Applicable</Table.Cell>
        <Table.Cell textAlign="right">Not Applicable</Table.Cell>
      </>
    )}
    <Table.Cell textAlign="right">
      {formatNumber(instrument.vestedQuantity)}
    </Table.Cell>
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={instrument.gain.vestedGain} decimals={0} />
    </Table.Cell>
    <Table.Cell>
      <Button as={Link} to={linkTo} size="medium" primary={true} basic={true}>
        <Content id="global.viewDetailsBtn" />
      </Button>
    </Table.Cell>
  </Table.Row>
);

const PSUSummaryRow: FunctionComponent<{
  instrument: IndividualInstrumentState;
  title: string;
  linkTo: string;
}> = ({ instrument, title, linkTo }) => (
  <Table.Row>
    <Table.Cell>{title}</Table.Cell>
    <Table.Cell textAlign="right">
      {formatNumber(instrument.totalQuantity)}
    </Table.Cell>
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={instrument.gain.totalGain} decimals={0} />
    </Table.Cell>
    <Table.Cell textAlign="right">
      {formatNumber(instrument.performanceAdjustedQuantity)}
    </Table.Cell>
    <Table.Cell textAlign="right">
      <CurrencyConversionView
        value={instrument.performanceAdjustedGain}
        decimals={0}
      />
    </Table.Cell>
    <Table.Cell textAlign="right">
      {formatNumber(instrument.vestedQuantity)}
    </Table.Cell>
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={instrument.gain.vestedGain} decimals={0} />
    </Table.Cell>
    <Table.Cell>
      <Button
        content="View details"
        as={Link}
        to={linkTo}
        size="medium"
        primary={true}
        basic={true}
      />
    </Table.Cell>
  </Table.Row>
);

const textKeys = {
  summaryAll: {
    header: "instruments.all.details.header",
    description: "instruments.all.details.description",
  },
  option: {
    header: "instruments.option.details.header",
    description: "instruments.option.details.description",
  },
  subcriptionRights: {
    header: "instruments.subcriptionrights.details.header",
    description: "instruments.subcriptionrights.details.description",
  },
  warrant: {
    header: "instruments.warrant.details.header",
    description: "instruments.warrant.details.description",
  },
  rsu: {
    header: "instruments.rsu.details.header",
    description: "instruments.rsu.details.description",
  },
  psu: {
    header: "instruments.psu.details.header",
    description: "instruments.psu.details.description",
  },
  fund: {
    header: "instruments.fund.details.header",
    description: "instruments.fund.details.description",
  },
  cash: {
    header: "instruments.cash.details.header",
    description: "instruments.cash.details.description",
  },
  rsa: {
    header: "instruments.rsa.details.header",
    description: "instruments.rsa.details.description",
  },
};

const HeaderAndDescription: FunctionComponent<{
  header: string;
  description: string;
}> = ({ header, description }) => (
  <div className="instruments-header block-m">
    <h1 className="block-m">
      <Content id={header} />
    </h1>
    <p className="text-content text-content-center block-m">
      <Content id={description} />
    </p>
  </div>
);

const sorted = (awards: FlatAward[]): FlatAward[] =>
  sortMultipleLevels(awards)("grantDate", "vestedDate");

const InstrumentsPage: FunctionComponent<Props & StateProps> = props => {
  const [showExpired, setShowExpired] = useState(false);
  const formatMessage = useFormatMessage();
  const {
    match,
    option,
    rsu,
    psu,
    rsa,
    warrant,
    funds,
    cash,
    subscriptionRights,
    awardGain,
    currencyCode,
    sharePrice,
    dateFormat,
  } = props;

  if (!props.sharePrice) {
    return <SpinnerInline active={true} />;
  }

  const instruments = [rsu, option, psu, rsa, warrant, subscriptionRights];
  const totalGainIncludingMaxPSUgain = instruments
    .map(a => a.gain.totalGain)
    .reduce(sumNumbers, 0);
  const totalQuantityIncludingMaxPSUgain = instruments
    .map(a => a.totalQuantity)
    .reduce(sumNumbers, 0);

  const vestedGainIncludingMaxPSUgain = instruments
    .map(a => a.gain.vestedGain)
    .reduce(sumNumbers, 0);
  const vestedQuantityIncludingMaxPSUgain = instruments
    .map(a => a.vestedQuantity)
    .reduce(sumNumbers, 0);

  const showPerformanceColumns = psu.totalQuantity > 0;
  const showExpiredToggle = () => setShowExpired(!showExpired);
  return (
    <Switch>
      <Route
        path={`${match.path}/options`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.option} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showExpired.label" })}
              />
              {option.allAwards.length > 0 && (
                <OptionLikeTable
                  options={sorted(option.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                  totalGain={option.gain.totalGain}
                  overrideCurrency={currencyCode}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/subscription_rights`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.subcriptionRights} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showExpired.label" })}
              />
              {subscriptionRights.allAwards.length > 0 && (
                <OptionLikeTable
                  options={sorted(subscriptionRights.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                  totalGain={subscriptionRights.gain.totalGain}
                  overrideCurrency={currencyCode}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/warrants`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.warrant} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showExpired.label" })}
              />
              {warrant.allAwards.length > 0 && (
                <OptionLikeTable
                  options={sorted(warrant.allAwards)}
                  showPurchasePrice={true}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                  totalGain={warrant.gain.totalGain}
                  overrideCurrency={currencyCode}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/rsus`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.rsu} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showExpired.label" })}
              />
              {rsu.allAwards.length > 0 && (
                <RSULikeTable
                  rsus={sorted(rsu.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  totalGain={rsu.gain.totalGain}
                  totalQuantity={rsu.totalQuantity}
                  isReleasedAtVesting={false}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/psus`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.psu} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showReleased.label" })}
              />
              {psu.allAwards.length > 0 && (
                <PSULikeTable
                  psus={sorted(psu.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  totalGain={psu.gain.totalGain}
                  totalQuantity={psu.totalQuantity}
                  isReleasedAtVesting={false}
                  dateFormat={dateFormat}
                  performanceAdjustedGain={psu.performanceAdjustedGain}
                  performanceAdjustedQuantity={psu.performanceAdjustedQuantity}
                  overrideCurrency={currencyCode}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/funds`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.fund} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showReleased.label" })}
              />
              {funds.allAwards.length > 0 && (
                <RSULikeTable
                  rsus={sorted(funds.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  totalGain={funds.gain.totalGain}
                  totalQuantity={funds.totalQuantity}
                  isReleasedAtVesting={true}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/cash`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.cash} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showReleased.label" })}
              />
              {cash.allAwards.length > 0 && (
                <RSULikeTable
                  rsus={sorted(cash.allAwards)}
                  sharePriceToday={1}
                  showExpired={showExpired}
                  totalGain={cash.gain.totalGain}
                  totalQuantity={cash.totalQuantity}
                  isReleasedAtVesting={true}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/rsas`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.rsa} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showReleased.label" })}
              />
              {rsa.allAwards.length > 0 && (
                <RSULikeTable
                  rsus={sorted(rsa.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  totalGain={rsa.gain.totalGain}
                  totalQuantity={rsa.totalQuantity}
                  isReleasedAtVesting={false}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={match.path}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.summaryAll} />
            <CurrencyFormatter>
              {(formatter: CurrencyFormatterProps) => (
                <div className="instruments-tables-container">
                  <Table celled={true} unstackable={true} textAlign="center">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.instrumentType" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {" "}
                          <Content id="global.tableLabel.totalQuantity" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.totalGain" />
                        </Table.HeaderCell>
                        {showPerformanceColumns && (
                          <>
                            <Table.HeaderCell>
                              <Content id="global.tableLabel.totalperfadjquantity" />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              <Content id="global.tableLabel.totalperfadjgain" />
                            </Table.HeaderCell>
                          </>
                        )}
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.vestedQuantity" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.vestedGain" />
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {option.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={option}
                          title={"Options"}
                          linkTo={"/instruments/options"}
                          showPerformanceColumns={showPerformanceColumns}
                        />
                      )}
                      {subscriptionRights.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={subscriptionRights}
                          title={"Subscription Rights"}
                          linkTo={"/instruments/subscription_rights"}
                          showPerformanceColumns={showPerformanceColumns}
                        />
                      )}
                      {warrant.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={warrant}
                          title={"Warrants"}
                          linkTo={"/instruments/warrants"}
                          showPerformanceColumns={showPerformanceColumns}
                        />
                      )}
                      {rsu.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={rsu}
                          title={"RSUs"}
                          linkTo={"/instruments/rsus"}
                          showPerformanceColumns={showPerformanceColumns}
                        />
                      )}
                      {psu.allAwards.length > 0 && (
                        <PSUSummaryRow
                          instrument={psu}
                          title={"PSUs"}
                          linkTo={"/instruments/psus"}
                        />
                      )}
                      {rsa.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={rsa}
                          title={"RSAs"}
                          linkTo={"/instruments/rsas"}
                          showPerformanceColumns={showPerformanceColumns}
                        />
                      )}
                      {funds.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={funds}
                          title={"Deferred Fund Units"}
                          linkTo={"/instruments/funds"}
                          showPerformanceColumns={showPerformanceColumns}
                        />
                      )}
                      {cash.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={cash}
                          title={"Deferred Cash"}
                          linkTo={"/instruments/cash"}
                          showPerformanceColumns={showPerformanceColumns}
                        />
                      )}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.sum" />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {formatNumber(totalQuantityIncludingMaxPSUgain)}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          <CurrencyConversionView
                            value={totalGainIncludingMaxPSUgain}
                            decimals={0}
                          />
                        </Table.HeaderCell>
                        {showPerformanceColumns && (
                          <>
                            <Table.HeaderCell textAlign="right">
                              {formatNumber(psu.performanceAdjustedQuantity)}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">
                              <CurrencyConversionView
                                value={psu.performanceAdjustedGain}
                                decimals={0}
                              />
                            </Table.HeaderCell>
                          </>
                        )}
                        <Table.HeaderCell textAlign="right">
                          {formatNumber(vestedQuantityIncludingMaxPSUgain)}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          <CurrencyConversionView
                            value={vestedGainIncludingMaxPSUgain}
                            decimals={0}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </div>
              )}
            </CurrencyFormatter>
          </div>
        )}
      />
    </Switch>
  );
};

export const PerformanceYesPopup = () => (
  <Popup trigger={<Icon name="question circle outline" />}>
    <Popup.Content>
      <Content id="performace.information.question" />
    </Popup.Content>
  </Popup>
);

const mapStateToProps: MapStateToProps<StateProps, Props, RootState> = (
  state: RootState
): StateProps => ({
  warrant: state.instrument.warrant,
  rsa: state.instrument.rsa,
  rsu: state.instrument.rsu,
  psu: state.instrument.psu,
  option: state.instrument.option,
  funds: state.instrument.fundsState,
  cash: state.instrument.cashState,
  subscriptionRights: state.instrument.subscriptionRightState,
  totalGain: state.instrument.totalGain,
  totalVestedGain: state.instrument.totalVestedGain,
  totalQuantity: state.instrument.totalQuantity,
  totalVestedQuantity: state.instrument.totalVestedQuantity,
  isFetchingWelcomeData: state.instrument.isFetchingWelcomeData,
  sharePrice: state.instrument.sharePrice,
  dateFormat: state.dateFormat,
  awardGain,
  currencyCode: userCurrencyCode(state),
});

export default connect<StateProps>(mapStateToProps)(InstrumentsPage);
