import moment, { Moment } from "moment";
import { Reducer } from "redux";
import { PurchaseType } from "src/admin-portal/awards/purchase/purchase-page";
import { apiShortDate, flatten, sumNumbers } from "src/common/utils/utils";
import { SharePrice } from "src/employee-portal/exercise/exercise-router";
import { removeExpiredAwards, vestedAwards } from "./instruments-page";

export interface PerformanceCriteria {
  name: string;
  performanceFactor: number;
  displayFactor: number;
  date: Moment;
  estimatedPerformanceProgress: number;
  currencyCode: string;
  thresholds: PerformanceCriteriaThreshold[];
}

export interface PerformanceCriteriaThreshold {
  fulfillmentFactor: number;
  fulfillmentLevel: string;
  fulfillmentLevelNumber: number;
  name: string;
}

export interface Currency {
  conversionFactor: number;
  currencyCode: string;
}

interface Gain {
  totalGain: number;
  vestedGain: number;
}

export interface IndividualInstrumentState {
  allAwards: FlatAward[];
  gain: Gain;
  totalQuantity: number;
  vestedQuantity: number;
  performance: boolean;
  performanceAdjustedQuantity?: number;
  performanceAdjustedGain?: number;
}

export interface InstrumentState {
  readonly option: IndividualInstrumentState;
  readonly warrant: IndividualInstrumentState;
  readonly rsu: IndividualInstrumentState;
  readonly psu: IndividualInstrumentState;
  readonly rsa: IndividualInstrumentState;
  readonly fundsState: IndividualInstrumentState;
  readonly cashState: IndividualInstrumentState;
  readonly subscriptionRightState: IndividualInstrumentState;
  readonly totalQuantity: number;
  readonly totalVestedQuantity: number;
  readonly totalGain: number;
  readonly totalVestedGain: number;
  readonly isFetchingWelcomeData: boolean;
  readonly sharePrice?: SharePrice;
  readonly errorFetchingWelcomeData?: boolean;
  readonly hasPerformance?: boolean;
  readonly error?: any;
  readonly currency?: Currency;
}

export interface APIPurchaseOpportunity {
  id: string;
  documentId?: string;
  maximumAmount?: number;
  maximum_cash_amount?: string;
  cash_amount_used?: string;
  discount?: string;
  purchase_type: PurchaseType;
  purchasedAmount: number;
  showShareDepository: boolean;
  price: string;
  instrument: string;
  windowId?: string;
}

const createDefaultIndividualInstrumentState = () => ({
  allAwards: [],
  gain: {
    totalGain: 0,
    vestedGain: 0,
    totalGainOriginalCurrency: 0,
    vestedGainOriginalCurrency: 0,
  },
  totalQuantity: 0,
  vestedQuantity: 0,
  performance: false,
});

const initialState: InstrumentState = {
  option: createDefaultIndividualInstrumentState(),
  warrant: createDefaultIndividualInstrumentState(),
  rsa: createDefaultIndividualInstrumentState(),
  rsu: createDefaultIndividualInstrumentState(),
  psu: createDefaultIndividualInstrumentState(),
  fundsState: createDefaultIndividualInstrumentState(),
  cashState: createDefaultIndividualInstrumentState(),
  subscriptionRightState: createDefaultIndividualInstrumentState(),
  totalQuantity: 0,
  totalVestedQuantity: 0,
  totalGain: 0,
  totalVestedGain: 0,
  isFetchingWelcomeData: false,
  errorFetchingWelcomeData: false,
  sharePrice: null,
};

type InstrumentType =
  | "option"
  | "rsu"
  | "psu"
  | "rsa"
  | "warrant"
  | "deferred_cash"
  | "deferred_fund_shares";
type SettlementType = "equity" | "cash";

interface InstrumentAward {
  programId: string;
  programName: string;
  subProgramName: string;
  tranches: Vesting[];
  instrumentType: InstrumentType;
  settlementType: SettlementType;
  performance: boolean;
  ticker?: Ticker;
}

interface Ticker {
  name: string;
  latest_share_price: number;
  latest_share_price_date: Moment;
}

interface Vesting {
  quantity: number;
  strike: number;
  capOnGain?: number;
  id: string;
  exercisedQuantity: number;
  grantDate: Moment;
  vestedDate: Moment;
  expiryDate: Moment;
  purchasePrice?: number;
  originalQuantity: number;
  performanceFactor?: number;
  performance_rules?: APIPerformanceRule[];
  performance_start_date?: string;
  performance_end_date?: string;
}

export interface APIAward {
  id: string;
  grantDate: string;
  expiryDate: string;
  vesting: any;
  quantity: number;
  employee_id: string;
  is_purchasable: boolean;
  incentive_sub_program: {
    id: string;
    name: string;
    instrument_type_id: InstrumentType;
    settlement_type_id: SettlementType;
    incentive_program_id: string;
    performance: boolean;
    incentive_program: {
      name: string;
      id: string;
    };
    purchase_config?: {
      id: string;
      price?: string;
      discount?: string;
      window_id: string;
      require_share_depository: boolean;
    };
    share_price_ticker?: {
      ticker_name: string;
      latest_share_price?: {
        date: string;
        price: string;
      };
    };
  };
  tranches: APIVestingEvent[];
  documents?: Api.V1.Document[];
}

interface APIPerformanceRule {
  name: string;
  description: string;
  last_performance_rule_entry: {
    date: string;
    probability_factor: string;
    prognosis: number;
  };
  currency_code: string;
  display_factor: string;
  thresholds: Array<{
    fulfillmentFactor: number;
    fulfillmentLevel: string;
    fulfillmentLevelNumber: number;
    name: string;
  }>;
}

interface APIVestingEvent {
  quantity: number;
  strike: string;
  cap_on_gain: string | null;
  grant_date: string;
  vestedDate: string;
  expiry_date: string;
  exercised_quantity: number;
  id: string;
  purchase_price: string;
  new_quantity_factor?: string;
  performance_factor?: string;
  performance_rules?: APIPerformanceRule[];
  performance_start_date?: string;
  performance_end_date?: string;
}

const getQuantity = (ve: APIVestingEvent) =>
  isNaN(parseFloat(ve.new_quantity_factor))
    ? ve.quantity
    : Math.floor(parseFloat(ve.new_quantity_factor) * ve.quantity);
const getOriginalQuantity = (ve: APIVestingEvent) =>
  isNaN(parseFloat(ve.new_quantity_factor)) ? null : ve.quantity;

const toInstrumentAward = (award: APIAward): InstrumentAward => ({
  programId: award.incentive_sub_program.incentive_program.id,
  programName: award.incentive_sub_program.incentive_program.name,
  subProgramName: award.incentive_sub_program.name,
  tranches: award.tranches.map(ve => ({
    quantity: getQuantity(ve),
    capOnGain: isNaN(parseFloat(ve.cap_on_gain))
      ? null
      : parseFloat(ve.cap_on_gain),
    strike: isNaN(parseFloat(ve.strike)) ? 0 : parseFloat(ve.strike),
    id: ve.id,
    exercisedQuantity: ve.exercised_quantity,
    grantDate: moment(ve.grant_date),
    vestedDate: moment(ve.vestedDate),
    expiryDate: moment(ve.expiry_date),
    purchasePrice: parseFloat(ve.purchase_price),
    originalQuantity: getOriginalQuantity(ve),
    performance_rules: ve.performance_rules,
    performance_start_date: ve.performance_start_date,
    performance_end_date: ve.performance_end_date,
    performanceFactor: ve.performance_factor
      ? parseFloat(ve.performance_factor)
      : null,
  })),
  instrumentType: award.incentive_sub_program.instrument_type_id,
  settlementType: award.incentive_sub_program.settlement_type_id,
  performance: award.incentive_sub_program.performance,
  ticker: award.incentive_sub_program.share_price_ticker &&
    award.incentive_sub_program.share_price_ticker.latest_share_price && {
      name: award.incentive_sub_program.share_price_ticker.ticker_name,
      latest_share_price: parseFloat(
        award.incentive_sub_program.share_price_ticker.latest_share_price.price
      ),
      latest_share_price_date: moment(
        award.incentive_sub_program.share_price_ticker.latest_share_price.date,
        apiShortDate
      ),
    },
});

export interface FlatAward {
  grantDate: Moment;
  expiryDate: Moment;
  vestedDate: Moment;
  performanceStartDate?: Moment;
  performanceEndDate?: Moment;
  quantity: number;
  exercisedQuantity: number;
  strike: number;
  capOnGain?: number;
  purchasePrice?: number;
  subProgramName: string;
  programName: string;
  programId: string;
  trancheId: string;
  instrumentType: InstrumentType;
  settlementType: SettlementType;
  performance: boolean;
  share_price?: number;
  share_price_date?: Moment;
  originalQuantity: number;
  performanceCriteria?: PerformanceCriteria;
}

const toFlatAwards = (award: InstrumentAward): FlatAward[] =>
  award.tranches.map(tranche => ({
    grantDate: tranche.grantDate,
    vestedDate: moment(tranche.vestedDate, apiShortDate),
    expiryDate: moment(tranche.expiryDate, apiShortDate),
    quantity:
      award.instrumentType === "option" &&
      tranche.performance_rules &&
      tranche.performance_rules.length > 0
        ? parseFloat(
            tranche.performance_rules[0].last_performance_rule_entry
              .probability_factor
          ) * tranche.quantity
        : tranche.quantity,
    exercisedQuantity: tranche.exercisedQuantity,
    strike: tranche.strike,
    capOnGain: tranche.capOnGain,
    programId: award.programId,
    trancheId: tranche.id,
    subProgramName: award.subProgramName,
    programName: award.programName,
    instrumentType: award.instrumentType,
    performance: award.performance,
    settlementType: award.settlementType,
    purchasePrice: tranche.purchasePrice,
    share_price: award.ticker && award.ticker.latest_share_price,
    share_price_date: award.ticker && award.ticker.latest_share_price_date,
    originalQuantity: tranche.originalQuantity,
    performanceStartDate: tranche.performance_start_date
      ? moment(tranche.performance_start_date, apiShortDate)
      : null,
    performanceEndDate: tranche.performance_end_date
      ? moment(tranche.performance_end_date, apiShortDate)
      : null,
    performanceCriteria:
      tranche.performance_rules && tranche.performance_rules.length > 0
        ? {
            name: tranche.performance_rules[0].name,
            performanceFactor: parseFloat(
              tranche.performance_rules[0].last_performance_rule_entry
                .probability_factor
            ),
            date: moment(
              tranche.performance_rules[0].last_performance_rule_entry.date,
              apiShortDate
            ),
            thresholds: tranche.performance_rules[0].thresholds,
            // estimatedPerformanceProgress: tranche.performance_rules[0].last_performance_rule_entry.estimated_performance_progress,
            estimatedPerformanceProgress:
              tranche.performance_rules[0].last_performance_rule_entry
                .prognosis,
            displayFactor: parseFloat(
              tranche.performance_rules[0].display_factor
            ),
            currencyCode: tranche.performance_rules[0].currency_code,
          }
        : undefined,
  }));

const isOfInstrumentType = (
  instrumentType: string,
  instrumentAward: InstrumentAward
) => instrumentAward.instrumentType === instrumentType;
const keepFundShares = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("deferred_fund_share", instrumentAward);
const keepDeferredCash = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("deferred_cash", instrumentAward);
const keepOptions = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("option", instrumentAward);
const keepSubsctiptionRights = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("subscription_rights", instrumentAward);
const keepRsus = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("rsu", instrumentAward);
const keepPsus = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("psu", instrumentAward);
const keepRsas = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("rsa", instrumentAward);
const keepWarrants = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("warrant", instrumentAward);

const instrumentReducer: Reducer<InstrumentState> = (
  state = initialState,
  action
): InstrumentState => {
  if (action.type === "FETCH_EMPLOYEE_PORTAL_WELCOME_SUCCEEDED") {
    const { welcomeData } = action;
    const currencyConversionFactor = parseFloat(
      welcomeData.profile.currency_exchange_ratio
    );
    const conversionIndicator = currencyConversionFactor !== 1 ? "≈ " : "";
    const currency = {
      conversionFactor: currencyConversionFactor,
      currencyCode: `${conversionIndicator}${
        welcomeData.profile.currency_code
      }`,
    };
    const awards = welcomeData.awards.map(toInstrumentAward);
    const funds: FlatAward[] = flatten(
      awards.filter(keepFundShares).map(toFlatAwards)
    );
    const deferredCash = flatten(
      awards.filter(keepDeferredCash).map(toFlatAwards)
    );
    const options = flatten(awards.filter(keepOptions).map(toFlatAwards));
    const subscriptionRights = flatten(
      awards.filter(keepSubsctiptionRights).map(toFlatAwards)
    );
    const rsus = flatten(awards.filter(keepRsus).map(toFlatAwards));
    const psus = flatten(awards.filter(keepPsus).map(toFlatAwards));
    const rsas = flatten(awards.filter(keepRsas).map(toFlatAwards));
    const warrants = flatten(awards.filter(keepWarrants).map(toFlatAwards));

    const sharePrice: SharePrice = {
      sharePrice: parseFloat(welcomeData.stockPrice.price),
      sharePriceDate: moment(welcomeData.stockPrice.date),
      manual: welcomeData.stockPrice.manual,
    };

    const deferredCashGain = {
      totalGain: deferredCash
        .map((a: FlatAward) => a.quantity)
        .reduce(sumNumbers, 0),
      totalGainOriginalCurrency: deferredCash
        .map((a: FlatAward) => a.quantity)
        .reduce(sumNumbers, 0),
      vestedGain: 0,
      vestedGainOriginalCurrency: 0,
    };
    const fundsGain = calculateGains(funds, sharePrice.sharePrice);
    const optionsGain = calculateGains(options, sharePrice.sharePrice);
    const subscriptionRightGain = calculateGains(
      subscriptionRights,
      sharePrice.sharePrice
    );
    const rsuGain = calculateGains(rsus, sharePrice.sharePrice);
    const psuGain = calculateGains(psus, sharePrice.sharePrice);
    const rsaGain = calculateGains(rsas, sharePrice.sharePrice);
    const warrantsGain = calculateGains(warrants, sharePrice.sharePrice);

    const fundsState: IndividualInstrumentState =
      funds.length > 0
        ? {
            allAwards: funds,
            gain: fundsGain,
            totalQuantity: funds
              .filter(removeExpiredAwards)
              .reduce(instrumentQuantity, 0),
            vestedQuantity: funds
              .filter(removeExpiredAwards)
              .filter(vestedAwards)
              .reduce(instrumentQuantity, 0),
            performance: funds.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const deferredCashState: IndividualInstrumentState =
      deferredCash.length > 0
        ? {
            allAwards: deferredCash,
            gain: deferredCashGain,
            totalQuantity: deferredCash
              .filter(removeExpiredAwards)
              .reduce(instrumentQuantity, 0),
            vestedQuantity: deferredCash
              .filter(removeExpiredAwards)
              .filter(vestedAwards)
              .reduce(instrumentQuantity, 0),
            performance: deferredCash.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const subscriptionRightState: IndividualInstrumentState =
      subscriptionRights.length > 0
        ? {
            allAwards: subscriptionRights,
            gain: subscriptionRightGain,
            totalQuantity: subscriptionRights
              .filter(removeExpiredAwards)
              .reduce(instrumentQuantity, 0),
            vestedQuantity: subscriptionRights
              .filter(removeExpiredAwards)
              .filter(vestedAwards)
              .reduce(instrumentQuantity, 0),
            performance: subscriptionRights.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const optionState: IndividualInstrumentState =
      options.length > 0
        ? {
            allAwards: options,
            gain: optionsGain,
            totalQuantity: options
              .filter(removeExpiredAwards)
              .reduce(instrumentQuantity, 0),
            vestedQuantity: options
              .filter(removeExpiredAwards)
              .filter(vestedAwards)
              .reduce(instrumentQuantity, 0),
            performance: options.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const warrantState: IndividualInstrumentState =
      warrants.length > 0
        ? {
            allAwards: warrants,
            gain: warrantsGain,
            totalQuantity: warrants
              .filter(removeExpiredAwards)
              .reduce(instrumentQuantity, 0),
            vestedQuantity: warrants
              .filter(removeExpiredAwards)
              .filter(vestedAwards)
              .reduce(instrumentQuantity, 0),
            performance: warrants.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const rsuState: IndividualInstrumentState =
      rsus.length > 0
        ? {
            allAwards: rsus,
            gain: rsuGain,
            totalQuantity: rsus
              .filter(removeExpiredAwards)
              .reduce(instrumentQuantity, 0),
            vestedQuantity: rsus
              .filter(removeExpiredAwards)
              .filter(vestedAwards)
              .reduce(instrumentQuantity, 0),
            performance: rsus.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const psuTotalQuantity = psus
      .filter(removeExpiredAwards)
      .reduce(instrumentQuantity, 0);
    const performanceAdjustedQuantity = psus
      .filter(removeExpiredAwards)
      .map(psu =>
        Math.floor(psu.performanceCriteria.performanceFactor * psu.quantity)
      )
      .reduce(sumNumbers, 0);
    const performanceFactor = performanceAdjustedQuantity / psuTotalQuantity;
    const performanceAdjustedGain = performanceFactor * psuGain.totalGain;
    const psuState: IndividualInstrumentState =
      psus.length > 0
        ? {
            allAwards: psus,
            gain: psuGain,
            totalQuantity: psuTotalQuantity,
            vestedQuantity: psus
              .filter(removeExpiredAwards)
              .filter(vestedAwards)
              .reduce(instrumentQuantity, 0),
            performance: psus.some(hasPerformanceCriteria),
            performanceAdjustedQuantity,
            performanceAdjustedGain,
          }
        : createDefaultIndividualInstrumentState();

    const rsaState: IndividualInstrumentState =
      rsus.length > 0
        ? {
            allAwards: rsas,
            gain: rsaGain,
            totalQuantity: rsas
              .filter(removeExpiredAwards)
              .reduce(instrumentQuantity, 0),
            vestedQuantity: rsas
              .filter(removeExpiredAwards)
              .filter(vestedAwards)
              .reduce(instrumentQuantity, 0),
            performance: rsus.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const allInstruments = [
      optionState,
      warrantState,
      rsaState,
      rsuState,
      fundsState,
      deferredCashState,
      subscriptionRightState,
    ];

    const hasPerformance =
      allInstruments.some(x => x.performance) || psuState.totalQuantity > 0;
    return {
      ...state,
      currency,
      isFetchingWelcomeData: false,
      sharePrice,
      option: optionState,
      warrant: warrantState,
      rsa: rsaState,
      rsu: rsuState,
      psu: psuState,
      fundsState,
      subscriptionRightState,
      cashState: deferredCashState,
      totalGain: allInstruments.reduce(
        (accu, instrument) => instrument.gain.totalGain + accu,
        psuState.performanceAdjustedGain || 0
      ),
      totalVestedGain: allInstruments.reduce(
        (accu, instrument) => instrument.gain.vestedGain + accu,
        0
      ),
      totalQuantity: allInstruments.reduce(
        (accu, instrument) => instrument.totalQuantity + accu,
        psuState.performanceAdjustedQuantity || 0
      ),
      totalVestedQuantity: allInstruments.reduce(
        (accu, instrument) => instrument.vestedQuantity + accu,
        0
      ),
      errorFetchingWelcomeData: false,
      hasPerformance,
    };
  } else if (action.type === "FETCH_EMPLOYEE_PORTAL_WELCOME_FAILED") {
    return {
      ...state,
      ...{
        isFetchingWelcomeData: false,
        error: action.error,
        errorFetchingWelcomeData: true,
      },
    };
  } else if (action.type === "FETCH_EMPLOYEE_PORTAL_WELCOME") {
    return { ...state, ...{ isFetchingWelcomeData: true } };
  }
  return state;
};

export type AwardGainFunction = (
  sharePrice: number,
  award: FlatAward
) => AwardGain;

export interface AwardGain {
  gain: number;
  capped: boolean;
}

export const awardGain: AwardGainFunction = (
  sharePrice: number,
  award: FlatAward
): AwardGain => {
  const sharePriceToUse = award.share_price || sharePrice;
  const maxGainPerInstrument = Math.max(sharePriceToUse - award.strike, 0);

  const gainPerInstrument = award.capOnGain
    ? Math.min(maxGainPerInstrument, award.capOnGain)
    : maxGainPerInstrument;

  const capped = award.capOnGain && maxGainPerInstrument > award.capOnGain;

  return {
    gain: award.quantity * gainPerInstrument,
    capped,
  };
};

export const calculateGains = (
  awards: FlatAward[],
  sharePrice: number
): Gain => {
  const totalGain = awards
    .filter(removeExpiredAwards)
    .map(award => awardGain(sharePrice, award).gain)
    .reduce(sumNumbers, 0);

  const vestedGain = awards
    .filter(vestedAwards)
    .filter(removeExpiredAwards)
    .map(award => awardGain(sharePrice, award).gain)
    .reduce(sumNumbers, 0);

  return {
    totalGain,
    vestedGain,
  };
};

export const instrumentQuantity = (accu, current) => accu + current.quantity;
export const hasPerformanceCriteria = (award: FlatAward) => award.performance;
export const hasOriginalQuantity = (award: FlatAward) =>
  award.originalQuantity !== null;
export const hasCapOnGain = (award: FlatAward) => award.capOnGain !== null;
export const hasThreshold = (award: FlatAward) =>
  !!award.performanceCriteria.thresholds;
export const hasPrognosis = (award: FlatAward) =>
  !!award.performanceCriteria.estimatedPerformanceProgress;
export const hasStrike = (award: FlatAward) => !!award.strike;

export default instrumentReducer;
