import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

interface Props extends FormattedMessage.Props {
  showKeys?: boolean;
}

interface State {}

class Content extends Component<Props, State> {
  public render() {
    if (window.location.href.includes("showKeys=true")) {
      return (
        <>
          [{this.props.id}] <FormattedHTMLMessage {...this.props} />
        </>
      );
    }

    return <FormattedHTMLMessage {...this.props} />;
  }
}

export default Content;
