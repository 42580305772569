import moment from "moment";
import numeral from "numeral";
import Raven from "raven-js";
import { call } from "redux-saga/effects";
import { fetchTexts } from "src/admin-portal/texts/employee-portal-texts-saga";
import { store } from "src/app";
import { ENGLISH_DATE, NORWEGIAN_DATE } from "src/constants";

function localeMapper(locale) {
  if (locale === "en") {
    // numeral.locale("");
    moment.locale("en-GB");
    store.dispatch({ type: ENGLISH_DATE });
  } else if (locale === "no") {
    // numeral.locale("");
    moment.locale("nb");
    store.dispatch({ type: NORWEGIAN_DATE });
  } else if (locale === "sv") {
    // numeral.locale("");
    moment.locale("sv");
  } else if (locale === "da") {
    // numeral.locale("");
    moment.locale("da");
  }
}

export function* setLocale(locale) {
  try {
    yield call(() => localeMapper(locale));
    yield call(() => fetchTexts(locale));
  } catch (e) {
    Raven.captureException(e);
  }
}
