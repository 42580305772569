import moment from "moment";
import { APIOrder as AdminAPIOrder } from "src/admin-portal/orders/orders-reducer";
import { OrderExerciseType } from "src/employee-portal/exercise/common/types";
import { APIOrder } from "../../employee-portal/exercise/order/order-reducer";
import { OrderType } from "../../employee-portal/purchase/duck/purchase-saga";

export const toApiOrder = (order): AdminAPIOrder | APIOrder => {
  const newOrder = {
    ...order,
    order_type: OrderType[order.order_type],
    created_at: moment(order.created_at),
  };
  switch (OrderType[order.order_type]) {
    case OrderType.PURCHASE:
      return newOrder;
    case OrderType.EXERCISE: {
      const exercise_order = {
        ...order.exercise_order,
        ...{
          exerciseType: OrderExerciseType[order.exercise_order.exerciseType],
        },
      };
      return { ...newOrder, exercise_order };
    }
    default:
      return newOrder;
  }
};

export const toApiOrderSimple = (order): AdminAPIOrder | APIOrder => {
  return {
    ...order,
    order_type: OrderType[order.order_type],
    created_at: moment(order.created_at),
  };
};
