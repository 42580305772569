import { Moment } from "moment";
import { createAction } from "redux-act";

export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORTS_FAILED = "FETCH_REPORTS_FAILED";
export const FETCH_REPORTS_SUCCEEDED = "FETCH_REPORTS_SUCCEEDED";

export const POST_REPORT = "POST_REPORT";
export const POST_REPORT_FAILED = "POST_REPORT_FAILED";
export const POST_REPORT_SUCCEEDED = "POST_REPORT_SUCCEEDED";

export const UPDATE_REPORT = "UPDATE_REPORT";
export const UPDATE_REPORT_FAILED = "UPDATE_REPORT_FAILED";
export const UPDATE_REPORT_SUCCEEDED = "UPDATE_REPORT_SUCCEEDED";

export const DELETE_REPORT = "DELETE_REPORT";
export const DELETE_REPORT_FAILED = "DELETE_REPORT_FAILED";
export const DELETE_REPORT_SUCCEEDED = "DELETE_REPORT_SUCCEEDED";

export const FETCH_REPORTS_ALL_AWARDS = "FETCH_REPORTS_ALL_AWARDS";
export const FETCH_REPORTS_ALL_AWARDS_FAILED =
  "FETCH_REPORTS_ALL_AWARDS_FAILED";
export const FETCH_REPORTS_ALL_AWARDS_SUCCEEDED =
  "FETCH_REPORTS_ALL_AWARDS_SUCCEEDED";

interface GenerateReportPayload {
  overviewData: any[][];
  ifrsData: any[][];
  ifrsYtdData?: any[][];
  socSecData: any[][];
  socSecYtdData?: any[][];
  dilutionData: any[][];
  notesData: any[][];
  employeeNotesData: any[][];
  fromDate: Moment;
  toDate: Moment;
  filename: string;
}

export const generateReport = createAction(
  "GENERATE_REPORT",
  (payload: GenerateReportPayload) => payload
);
