import { FlagNameValues } from "semantic-ui-react";
import { SupportedLocale } from "../../admin-portal/texts/text-reducer";

interface LanguageDropdownItem {
  key: SupportedLocale;
  value: SupportedLocale;
  flag: FlagNameValues;
  text: string;
}

type FormatMessageFuntion = (data: { id: string }) => string;

export const generateSupportedLanguagesOptions = (
  formatMessage?: FormatMessageFuntion
): LanguageDropdownItem[] => {
  // prettier-ignore
  return [
    {
      key: "en", value: "en", flag: "gb",
      text: formatMessage ? formatMessage({
        id: "profile.language.english"
      }) : "English",
    },
    {
      key: "no", value: "no", flag: "no",
      text: formatMessage ? formatMessage({
        id: "profile.language.norwegian",
      }) : "Norwegian",
    },
    {
      key: "sv", value: "sv", flag: "se",
      text: formatMessage ? formatMessage({
        id: "profile.language.swedish",
      }) : "Swedish",
    },
    {
      key: "da", value: "da", flag: "dk",
      text: formatMessage ? formatMessage({
        id: "profile.language.danish",
      }) : "Danish",
    },
  ];
};
