import { Moment } from "moment";
import { Reducer } from "redux";
import { PurchaseType } from "src/admin-portal/awards/purchase/purchase-page";
import { toApiOrder } from "src/common/api/orders-response-mapper";
import { OrderType } from "src/employee-portal/purchase/duck/purchase-saga";
import { OrderExerciseType } from "../common/types";

export interface OrderState {
  readonly isPlacingOrder: boolean;
  readonly isFetchingOrders: boolean;
  readonly orders?: APIOrder[];
  readonly errorFetchingOrders: boolean;
  readonly error?: string;
}

export enum OrderStatus {
  CREATED = "CREATED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export interface APIOrder {
  order_type: OrderType;
  status: OrderStatus;
  id: string;
  created_at: Moment;
  exercise_order?: APIExerciseOrder;
  purchase_order?: APIPurchaseOrder;
}

export interface APIPurchaseOrder {
  purchase_amount: number;
  purchase_cash_amount: string;
  instrument_type: string;
  purchase_config: {
    price: string;
    purchase_type: PurchaseType;
  };
}

export interface APIExerciseOrder {
  exerciseType: OrderExerciseType;
  status: OrderStatus;
  id: string;
  exercise_order_lines: APIExerciseOrderLine[];
}

interface APIExerciseOrderLine {
  exercise_quantity: number;
  is_released: boolean;
}

const initialState: OrderState = {
  isPlacingOrder: false,
  isFetchingOrders: false,
  errorFetchingOrders: false,
  orders: null,
};

export const mostRecentFirst = (orderA: APIOrder, orderB: APIOrder): number =>
  orderA.created_at.isSameOrBefore(orderB.created_at) ? 1 : -1;

const orderReducer: Reducer<OrderState> = (state = initialState, action) => {
  if (action.type === "FETCH_ORDERS") {
    return { ...state, ...{ isFetchingOrders: true } };
  }

  if (action.type === "FETCH_ORDERS_SUCCEEDED") {
    const orders = action.orders
      .map(toApiOrder)
      .map(order => {
        return order;
      })
      .sort(mostRecentFirst);
    return {
      ...state,
      ...{ isFetchingOrders: false, orders, errorFetchingOrders: false },
    };
  }

  if (action.type === "FETCH_ORDERS_FAILED") {
    return {
      ...state,
      ...{ isFetchingOrders: false, errorFetchingOrders: true },
    };
  }

  if (action.type === "PLACE_ORDER") {
    return { ...state, ...{ isPlacingOrder: true } };
  }

  if (action.type === "PLACE_ORDER_SUCCEEDED") {
    return { ...state, ...{ isPlacingOrder: false } };
  }

  if (action.type === "PLACE_ORDER_FAILED") {
    return {
      ...state,
      ...{
        isPlacingOrder: false,
        error:
          "An error occurred while placing your order. Please contact us if the issue persists",
      },
    };
  }

  if (action.type === "REMOVE_ORDER_ERROR") {
    return { ...state, ...{ error: null } };
  }

  return state;
};

export default orderReducer;
