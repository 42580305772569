import numeral from "numeral";
import React, { Component } from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, Redirect, Route, withRouter } from "react-router-dom";
import { Button, Dropdown, Icon, Menu, Popup } from "semantic-ui-react";
import FormatSharePrice from "src/common/components/format-share-price";
import { RootState } from "src/reducers/all-reducers";
import { DateFormatState } from "src/reducers/date-format-reducer";
import { isAdminUserWithoutInstruments } from "src/selectors";
import { formatCurrency, formatSharePrice } from "../../common/utils/utils";
import { SharePrice } from "../../employee-portal/exercise/exercise-router";
import { Features } from "../../employee-portal/features/features-reducer";
import { AUTH0_CLIENT_ID, AUTH0_LOGOUT_URL } from "../../env";
import { Tenant } from "../tenant/tenant-reducer";
import "./menu.less";

const employeePortalHomeRoute = "/";
const tenantRoute = "/client";
const reportsRoute = "/admin/reports";
const helpRoute = "/help";
const profileRoute = "/profile";
const selfServiceRoute = "/selfservice/overview";
export const documentsRoute = "/documents";
const importRoute = "/admin/import";
export const adminFilesRoute = "/admin/files";
export const logoutRoute = "/logout";
const entitiesRoute = "/admin/entities";
const employeeRoute = "/employees";
const programsRoute = "/admin/programs";
const sharePriceRoute = "/admin/share-price";
const tenantAwardsRoute = "/admin/all-awards";
const performanceRulesRoute = "/admin/performance-rules";
export const awardsRoute = "/admin/awards";
export const subprogramAwardsRoute = (subprogramId: string) =>
  `${awardsRoute}/subprogramawards/${subprogramId}`;
export const employeesRoute = "/admin/employees";
export const dividendsRoute = "/admin/dividends";
const manageRoute = "/admin/manage";
const adminWindowsRoute = "/admin/windows";
const adminOrdersRoute = "/admin/orders";
const textsRoute = "/admin/texts/emails";
export const auditLogsRoute = "/admin/audit-logs";
export const exerciseRoute = "/exercise";
export const purchaseRoute = "/purchase";
export const purchasePopupRoute = "/purchasepopup";
export const orderRoute = "/orders";
const instrumentsRoute = "/instruments";
const adminHomeRoute = "/admin";
const selfServiceAdminRoute = "/admin/selfservice";
const employeePortalRoute = "/";
interface OwnProps extends RouteComponentProps<{}> {
  userName: string;
}

interface StateProps {
  showAdminMenu: boolean;
  isAdmin: boolean;
  isAdminUserWithoutInstruments: boolean;
  features: Features;
  logoUrl?: string;
  sharePrice: SharePrice;
  selectedTenant?: Tenant;
  dateFormat: DateFormatState;
}

type Props = OwnProps & StateProps;

class AdminPortalMenu extends Component<Props, {}> {
  public state = { activeItem: employeePortalHomeRoute };

  public renderAdminToolMenu() {
    const { location, selectedTenant } = this.props;
    return (
      <Menu size="tiny" className={"force-no-border-radius"}>
        {selectedTenant && (
          <div className="flex-row align-center space-between flex-1 left-menu-container">
            {selectedTenant.logo_url && (
              <Link to={adminHomeRoute}>
                <img src={selectedTenant.logo_url} alt="" className="logo" />
              </Link>
            )}
          </div>
        )}
        <Menu.Menu position="right">
          <Menu.Item
            active={location.pathname === adminHomeRoute}
            as={Link}
            to={adminHomeRoute}
          >
            Home
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(selfServiceAdminRoute)
            }
            as={Link}
            to={selfServiceAdminRoute}
          >
            SS
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(reportsRoute)
            }
            as={Link}
            to={reportsRoute}
          >
            Reports
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(entitiesRoute)
            }
            as={Link}
            to={entitiesRoute}
          >
            Entities
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(employeesRoute)
            }
            as={Link}
            to={employeesRoute}
          >
            Employees
          </Menu.Item>
          {/*<Menu.Item*/}
          {/*active={*/}
          {/*location.pathname && location.pathname.startsWith(programsRoute)*/}
          {/*}*/}
          {/*as={Link}*/}
          {/*to={programsRoute}*/}
          {/*>*/}
          {/*Programs*/}
          {/*</Menu.Item>*/}
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(awardsRoute)
            }
            as={Link}
            to={awardsRoute}
          >
            Awards
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(adminWindowsRoute)
            }
            as={Link}
            to={adminWindowsRoute}
          >
            Windows
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(sharePriceRoute)
            }
            as={Link}
            to={sharePriceRoute}
          >
            Share Price
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(tenantAwardsRoute)
            }
            as={Link}
            to={`${tenantAwardsRoute}/1`}
          >
            All Awards
          </Menu.Item>

          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(performanceRulesRoute)
            }
            as={Link}
            to={performanceRulesRoute}
          >
            Performance Rules
          </Menu.Item>

          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(adminOrdersRoute)
            }
            as={Link}
            to={adminOrdersRoute}
          >
            Orders
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(textsRoute)
            }
            as={Link}
            to={textsRoute}
          >
            Texts
          </Menu.Item>

          {/*<Menu.Item*/}
          {/*active={*/}
          {/*location.pathname && location.pathname.startsWith(importRoute)*/}
          {/*}*/}
          {/*as={Link}*/}
          {/*to={importRoute}*/}
          {/*>*/}
          {/*Import*/}
          {/*</Menu.Item>*/}

          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(adminFilesRoute)
            }
            as={Link}
            to={adminFilesRoute}
          >
            Files
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(dividendsRoute)
            }
            as={Link}
            to={dividendsRoute}
          >
            Dividends
          </Menu.Item>

          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(auditLogsRoute)
            }
            as={Link}
            to={`${auditLogsRoute}/1`}
          >
            Logs
          </Menu.Item>

          <Dropdown item={true} text={`${this.props.userName}`}>
            <Dropdown.Menu>
              <Dropdown.Item>Settings</Dropdown.Item>
              <Dropdown.Item as={"a"} href={AUTH0_LOGOUT_URL}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }

  public render() {
    const { location } = this.props;
    return (
      <nav className="site-header">
        <div>
          {location.pathname.startsWith(adminHomeRoute) &&
            this.renderAdminToolMenu()}
        </div>
      </nav>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = ({
  user,
  features,
  instrument,
  tenant,
  dateFormat,
}) => ({
  showAdminMenu: user.isSysadmin || user.isAdmin,
  isAdmin: user.isAdmin,
  isAdminUserWithoutInstruments: isAdminUserWithoutInstruments(
    user,
    instrument
  ),
  sharePrice: instrument.sharePrice,
  logoUrl: user.tenant && user.tenant.logo_url,
  selectedTenant: tenant.selectedTenant,
  features,
  dateFormat,
});

export default withRouter<OwnProps>(
  connect<StateProps, null, OwnProps>(
    mapStateToProps,
    null
  )(AdminPortalMenu)
);
