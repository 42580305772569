import { get } from "lodash";
import { groupBy } from "src/admin-portal/reports/generate-report/common/utils";
import { sortMultipleLevels } from "src/common/utils/sort";
import {
  FormatMessageFuntion,
  InstrumentType,
  instrumentTypesText,
} from "src/common/utils/text-mappings";
import { flatten } from "../common/utils/utils";
import { RootState } from "../reducers/all-reducers";
import {
  removeExpiredAwards,
  vestedAwards,
} from "./instrument-page/instruments-page";
import { awardGain, FlatAward } from "./instrument-page/instruments-reducer";

export const exercisibleInstrumentTerm = (
  tranches: FlatAward[],
  formatMessage
) => {
  const instrumentObject = groupBy(tranches, t => t.instrumentType);
  const exercisibleInstrumentTypes = Object.keys(instrumentObject).map(
    instrumentTypeString => InstrumentType[instrumentTypeString.toUpperCase()]
  );
  return instrumentTypesText(exercisibleInstrumentTypes, formatMessage);
};

export const canExercise = (state: RootState) =>
  state.user.currentExerciseWindow &&
  !state.user.currentExerciseWindow.is_release_process &&
  state.instrument.option.vestedQuantity +
    state.instrument.warrant.vestedQuantity +
    state.instrument.subscriptionRightState.vestedQuantity >
    0;

export const canRelease = (state: RootState) =>
  state.user.currentExerciseWindow &&
  state.user.currentExerciseWindow.is_release_process &&
  state.instrument.rsu.vestedQuantity + state.instrument.psu.vestedQuantity > 0;

export const exercisibleTranches = (state: RootState): FlatAward[] => {
  const sharePrice = state.instrument.sharePrice.sharePrice;
  if (canExercise(state)) {
    const exercisibleInstruments = flatten([
      state.instrument.option.allAwards,
      state.instrument.warrant.allAwards,
      state.instrument.subscriptionRightState.allAwards,
    ]);
    return findExercisibleTranches(exercisibleInstruments, sharePrice);
  } else if (canRelease(state)) {
    const releasableInstruments = flatten([
      state.instrument.rsu.allAwards,
      state.instrument.psu.allAwards,
    ]);
    return findExercisibleTranches(releasableInstruments, sharePrice);
  } else {
    return [];
  }
};

export const sortedExercisibleTranches = (state: RootState): FlatAward[] =>
  sortMultipleLevels(exercisibleTranches(state))("grantDate", "vestedDate");

export const userCurrencyCode = (state: RootState): string | undefined => {
  const sharePriceCurrency = tenantCurrencyCode(state);
  const overrideCurrency = overrideCurrencyCode(state);
  return overrideCurrency || sharePriceCurrency;
};

export const tenantCurrencyCode = (state: RootState): string | undefined =>
  get(state, "user.tenant.currency_code");

export const overrideCurrencyCode = (state: RootState): string | undefined =>
  get(state, "instrument.currency.currencyCode");

export const currencyConversionFactor = (state: RootState) =>
  get(state, "instrument.currency.conversionFactor") || 1;

const findExercisibleTranches = (
  instruments: FlatAward[],
  sharePrice: number
) =>
  instruments
    .filter(vestedAwards)
    .filter(removeExpiredAwards)
    .filter(inTheMoney.bind(this, sharePrice));

const inTheMoney = (sharePrice: number, award: FlatAward): boolean =>
  sharePrice >= award.strike;
