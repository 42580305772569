export const FETCH_FILES = "FETCH_FILES";
export const FETCH_FILES_SUCCEEDED = "FETCH_FILES_SUCCEEDED";
export const FETCH_FILES_FAILED = "FETCH_FILES_FAILED";

export const FETCH_EMPLOYEES_AND_FILES = "FETCH_EMPLOYEES_AND_FILES";
export const FETCH_EMPLOYEES_AND_FILES_SUCCEEDED =
  "FETCH_EMPLOYEES_AND_FILES_SUCCEEDED";
export const FETCH_EMPLOYEES_AND_FILES_FAILED =
  "FETCH_EMPLOYEES_AND_FILES_FAILED";

export const DELETE_EMPLOYEE_ASSOCIATION = "DELETE_EMPLOYEE_ASSOCIATION";
export const DELETE_EMPLOYEE_ASSOCIATION_SUCCEEDED =
  "DELETE_EMPLOYEE_ASSOCIATION_SUCCEEDED";
export const DELETE_EMPLOYEE_ASSOCIATION_FAILED =
  "DELETE_EMPLOYEE_ASSOCIATION_FAILED";

export const UPLOAD_FILES = "UPLOAD_FILES";
export const UPLOAD_FILES_SUCCEEDED = "UPLOAD_FILES_SUCCEEDED";
export const UPLOAD_FILES_FAILED = "UPLOAD_FILES_FAILED";

export const ATTACH_EMPLOYEE_TO_FILE = "ATTACH_EMPLOYEE_TO_FILE";
export const ATTACH_EMPLOYEE_TO_FILE_SUCCEEDED =
  "ATTACH_EMPLOYEE_TO_FILE_SUCCEEDED";
export const ATTACH_EMPLOYEE_TO_FILE_FAILED = "ATTACH_EMPLOYEE_TO_FILE_FAILED";

export const UPDATE_EMPLOYEE_DOCUMENTS_FOR_FILE =
  "UPDATE_EMPLOYEE_DOCUMENTS_FOR_FILE";
export const UPDATE_EMPLOYEE_DOCUMENTS_FOR_FILE_SUCCEEDED =
  "UPDATE_EMPLOYEE_DOCUMENTS_FOR_FILE_SUCCEEDED";
export const UPDATE_EMPLOYEE_DOCUMENTS_FOR_FILE_FAILED =
  "UPDATE_EMPLOYEE_DOCUMENTS_FOR_FILE_FAILED";

export const FILE_DOWNLOAD = "FILE_DOWNLOAD";
export const FILE_DOWNLOAD_SUCCEEDED = "FILE_DOWNLOAD_SUCCEEDED";
export const FILE_DOWNLOAD_FAILED = "FILE_DOWNLOAD_FAILED";

export const MARK_DOCUMENT_AS_READ = "MARK_DOCUMENT_AS_READ";
export const MARK_DOCUMENT_AS_READ_SUCCEEDED =
  "MARK_DOCUMENT_AS_READ_SUCCEEDED";
export const MARK_DOCUMENT_AS_READ_FAILED = "MARK_DOCUMENT_AS_READ_FAILED";

export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const FETCH_DOCUMENTS_SUCCEEDED = "FETCH_DOCUMENTS_SUCCEEDED";
export const FETCH_DOCUMENTS_FAILED = "FETCH_DOCUMENTS_FAILED";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCEEDED = "DELETE_DOCUMENT_SUCCEEDED";
export const DELETE_DOCUMENT_FAILED = "DELETE_DOCUMENT_FAILED";

export const UPDATE_DOCUMENT_SUCCEEDED = "UPDATE_DOCUMENT_SUCCEEDED";
export const RESET_UPLOAD_FILE_STATE = "RESET_UPLOAD_FILE_STATE";
