import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { changeCommaForPunctuation } from "src/common/utils/utils";
import { DateFormatState } from "src/reducers/date-format-reducer";
import SpinnerFullScreen from "../../../common/components/spinner-full-screen";
import { Window } from "../window-reducer";

interface Props {
  window: Window;
  startProcessing: (transactionDate: string) => void;
  completeExerciseAndHoldOrders: (sharePrice: string) => void;
  completeSaleCashlessOrders: (sharePrice: string) => void;
  completeCashlessOrders: () => void;
  isLoading: boolean;
  dateFormat: DateFormatState;
}

interface State {
  cashlessOrdersPrice: string;
  exerciseAndHoldOrdersPrice: string;
  transactionDate: string;
}

export enum WindowProcessingState {
  IN_PROCESS = "IN_PROCESS",
}

export class WindowDetailsHome extends Component<Props, State> {
  public state = {
    cashlessOrdersPrice: "",
    exerciseAndHoldOrdersPrice: "",
    transactionDate: "",
  };

  public render() {
    const { window, isLoading, startProcessing } = this.props;
    const windowHasEnded = moment().isAfter(window.end_time);
    return (
      <div>
        <SpinnerFullScreen active={isLoading} />
        <div className="block-l">
          <h2>Window progress status</h2>
        </div>
        <div className="block-m">
          <span>Status:</span>
          {moment().isBefore(window.start_time) && "Before opening"}
          {moment().isBetween(window.start_time, window.end_time) &&
            "Window open"}
          {windowHasEnded && "Window Closed"}
        </div>
        {windowHasEnded && (
          <div className="block-m">
            {this.renderStartProcessingOrders()}
            {window.processing_status === WindowProcessingState.IN_PROCESS && (
              <div className="block-m">
                {this.renderExerciseAndHoldOrdersStatus()}
                {this.renderCashlessOrdersStatus()}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  public componentDidMount() {
    if (!this.state.transactionDate) {
      const { window, dateFormat } = this.props;
      this.setState({
        transactionDate: window.end_time.format(dateFormat.normalDateFormat),
      });
    }
  }

  private renderStartProcessingOrders = () => {
    const { window, dateFormat } = this.props;
    if (window.processing_status) {
      return null;
    }
    return (
      <div>
        <label>
          Transaction date(
          {dateFormat.normalDateFormat})
        </label>
        <Form.Input
          type="text"
          value={this.state.transactionDate}
          onChange={this.handleChange.bind(this, "transactionDate")}
          placeholder="Transaction date"
        />
        <div>
          {moment(
            this.state.transactionDate,
            dateFormat.normalDateFormat
          ).format(dateFormat.normalDateFormat)}
        </div>
        <a href="javascript:void(0)" onClick={this.startProcessing}>
          Start processing all orders
        </a>
      </div>
    );
  };

  private renderExerciseAndHoldOrdersStatus = () => {
    const { window } = this.props;
    if (window.exercise_and_hold_orders_completed) {
      return <div>Exercise and Hold orders are completed</div>;
    }
    return (
      <div>
        <input
          type="text"
          onChange={this.handleChange.bind(this, "exerciseAndHoldOrdersPrice")}
          placeholder="Price"
        />
        <a
          href="javascript:void(0)"
          onClick={this.completeExerciseAndHoldOrders}
        >
          Complete all Exercise and Hold orders
        </a>
      </div>
    );
  };

  private renderCashlessOrdersStatus = () => {
    const { window } = this.props;
    if (window.cashless_orders_completed) {
      return <div>Exercise and Sell orders are completed</div>;
    }
    if (window.cashless_orders_sale_completed) {
      return (
        <div>
          Exercise and Sell orders are sale completed
          <br />
          <a
            href="javascript:void(0)"
            onClick={this.props.completeCashlessOrders}
          >
            Complete all Exercise and Sale orders
          </a>
        </div>
      );
    }
    return (
      <div>
        <input
          type="text"
          onChange={this.handleChange.bind(this, "cashlessOrdersPrice")}
          placeholder="Price"
        />
        <a href="javascript:void(0)" onClick={this.completeSaleCashlessOrders}>
          Complete sale on all Exercise and Sale orders
        </a>
      </div>
    );
  };

  private handleChange = (key, event) => {
    this.setState({ [key]: event.target.value } as Pick<State, keyof State>);
  };

  private completeExerciseAndHoldOrders = () => {
    this.props.completeExerciseAndHoldOrders(
      changeCommaForPunctuation(this.state.exerciseAndHoldOrdersPrice)
    );
  };

  private completeSaleCashlessOrders = () => {
    this.props.completeSaleCashlessOrders(
      changeCommaForPunctuation(this.state.cashlessOrdersPrice)
    );
  };

  private startProcessing = () => {
    const { transactionDate } = this.state;
    this.props.startProcessing(transactionDate);
  };
}
