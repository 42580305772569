import React, { Component, StatelessComponent } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { RootState } from "src/reducers/all-reducers";
import { logoutRoute } from "../../admin-portal/menu/admin-portal-menu";
import { KEEP_ALIVE_ACTION } from "./keep-alive-saga";

interface StateProps {
  isLoggedIn: boolean;
}

interface DispatchProps {
  keepAlive: () => void;
}

interface State {
  cancelListener: () => void;
}

const loginUrl = "/login";

type Props = RouteComponentProps<{}> & StateProps & DispatchProps;

const ignoredRoutes = [logoutRoute, loginUrl];

class RequireAuth extends Component<Props, State> {
  public state = {
    cancelListener: () => {},
  };

  public componentDidMount() {
    const cancelListener = this.props.history.listen((location, action) => {
      if (!ignoredRoutes.includes(location.pathname)) {
        this.props.keepAlive();
      }
    });
    this.setState({ cancelListener });
  }

  public render() {
    return this.props.isLoggedIn ? (
      this.props.children
    ) : (
      <Redirect to={loginUrl} />
    );
  }

  public componentWillUnmount() {
    this.state.cancelListener();
  }
}
const mapStateToProps: MapStateToProps<StateProps, null, RootState> = ({
  user,
}) => ({
  isLoggedIn: user.loggedIn,
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  null
> = dispatch => ({
  keepAlive: () => dispatch({ type: KEEP_ALIVE_ACTION }),
});

export default withRouter<RouteComponentProps<{}>>(
  connect<StateProps, DispatchProps, null>(
    mapStateToProps,
    mapDispatchToProps
  )(RequireAuth)
);
