import React, { useContext } from "react";
import {
  FormattedMessage,
  InjectedIntl,
  injectIntl,
  MessageValue,
} from "react-intl";

export const IntlContext = React.createContext<InjectedIntl>(
  {} as InjectedIntl
);

// turn the old context into the new context
export const InjectIntlContext = injectIntl(({ intl, children }) => (
  <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>
));

// the format message hook
export const useFormatMessage = () => {
  const intl = useContext(IntlContext);
  return (
    messageDescriptor: FormattedMessage.MessageDescriptor,
    values?: { [key: string]: MessageValue }
  ) => {
    const showKeys = window.location.href.includes("showKeys=true");
    if (showKeys) {
      return `[${messageDescriptor.id}] ${intl.formatMessage(
        messageDescriptor,
        values
      )}`;
    }
    return intl.formatMessage(messageDescriptor, values);
  };
};

export const formatMessageWithDebugger = (
  id: string,
  formatMessage,
  values?: object
): string => {
  if (window.location.href.includes("showKeys=true")) {
    return `[${id}] ${formatMessage({ id }, values)}`;
  }

  return formatMessage({ id }, values);
};

export function getUniqueTextKeys(arr: Api.V1.Text[]): string[] {
  const uniqueTextKeysSet = new Set(
    arr.reduce((acc, next) => [...acc, ...Object.keys(next.value)], [])
  );
  return Array.from(uniqueTextKeysSet).sort();
}
