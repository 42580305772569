import { push } from "connected-react-router";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { purchaseAvailableAndPrestine } from "src/employee-portal/purchase/duck/purchase-selectors";
import { setLocale } from "src/employee-portal/set-locale-saga";
import { isAdminUserWithoutInstruments } from "src/selectors";
import { callApi, NOT_AUTHORIZED } from "../common/api/api-helper";
import { RootState } from "../reducers/all-reducers";

const WELCOME_URL = "/welcomes";

function* fetchWelcome(action) {
  try {
    const beforeState: RootState = yield select();
    const token = beforeState.user.token;
    const welcomeResponse = yield call(() => callApi(WELCOME_URL, token));

    yield put({
      type: "FETCH_EMPLOYEE_PORTAL_WELCOME_SUCCEEDED",
      welcomeData: welcomeResponse.data,
    });
    yield call(() => setLocale(welcomeResponse.data.profile.locale));
    yield put({
      type: "FETCH_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED",
      employeeData: welcomeResponse.data.profile,
    });
    const afterState: RootState = yield select();
    if (
      afterState.features.documents &&
      afterState.user.documents.some(doc => doc.requires_acceptance)
    ) {
      yield put(push("/acceptdocuments"));
    } else if (
      afterState.features.purchase &&
      purchaseAvailableAndPrestine(afterState)
    ) {
      yield put(push("/purchasepopup"));
    } else if (
      isAdminUserWithoutInstruments(afterState.user, afterState.instrument)
    ) {
      yield put(push("/selfservice"));
    }
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureMessage(e.errorMessage);
      yield put({
        type: "FETCH_EMPLOYEE_PORTAL_WELCOME_FAILED",
        message: e.message,
        error: e,
      });
    } else {
      Raven.captureException(e);
      yield put({
        type: "FETCH_EMPLOYEE_PORTAL_WELCOME_FAILED",
        message: e.message,
        error: e,
      });
    }
  }
}

export function* watchFethEmployeePortalWelcome() {
  yield takeEvery("FETCH_EMPLOYEE_PORTAL_WELCOME", fetchWelcome);
}
