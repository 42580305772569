// src/Auth/Auth.js
import Auth0Lock, { Auth0LockPasswordless } from "auth0-lock";
import jwtDecode from "jwt-decode";
import Raven from "raven-js";
import {
  AUTH0_CLIENT_ID,
  AUTH0_CONFIGURATION_BASE_URL,
  AUTH0_DOMAIN,
  AUTH0_PASSWORDLESS_METHOD,
  AUTH_REDIRECT_URL,
} from "src/env";

const authenticationOff = false;

interface Token {
  app_metadata: AppMetadata;
  user_metadata: UserMetadata;
  email: string;
}

interface AppMetadata {
  tenants: string[];
  roles: string[];
}

interface UserMetadata {
  selectedTenantId?: string;
  name: string;
}

const lockLanguageSpec = {
  passwordlessEmailInstructions: "Enter your email to sign in.",
  success: {
    magicLink:
      "We sent you a link to log in<br />to %s. Check your spam filter if it does not appear in your inbox.",
  },
  error: {
    passwordless: {
      "bad.connection": `<div style="text-align: left;"><div>YOUR SUPPLIED EMAIL IS NOT REGISTERED. PLEASE TRY THE FOLLOWING:</div><ul style="list-style: initial; padding-left: 1rem;"><li>CHECK YOUR EMAIL FOR TYPOS</li><li>ENTER OTHER EMAIL ADDRESSES/ALIASES YOU HAVE/USE </li></ul><div>CONTACT SUPPORT@OPTIOINCENTIVES.NO IF IT STILL DOES NOT WORK</div></div>`,
    },
  },
  title: "Optio Incentives Portal",
};

export const parseAuthUrl = () =>
  new Promise((resolve, reject) => {
    lock.on("authenticated", (authResult: any) => {
      resolve(authResult);
    });

    lock.on("authorization_error", error => {
      if (error) {
        Raven.captureMessage(`${error.error} - ${error.errorDescription}`, {
          extra: error,
          level: "info",
        });
      }
      reject(error);
    });

    lock.on("unrecoverable_error", error => {
      Raven.captureException(error);
      reject(error);
    });
  });

export const updateSession = () =>
  new Promise<AuthResult>((resolve, reject) => {
    lock.checkSession(checkSessionOptions, function(error, authResult) {
      if (error || !authResult) {
        const authError = error
          ? new Error(`${error.error} - ${error.errorDescription}`)
          : null;
        reject(
          authError ||
            new Error("Updating session: Both error and authResult are null")
        );
      } else {
        resolve(authResult);
      }
    });
  });

export const setSession = (authResult: AuthResult, userEmail: string) => {
  const expiresAt = JSON.stringify(authResult.idTokenPayload.exp * 1000);

  localStorage.setItem("id_token", authResult.idToken);
  localStorage.setItem("expires_at", expiresAt);
  localStorage.setItem("last_used_email", userEmail);
};

export const clearLoginStateFromLocalStorage = () => {
  // Clear access token and ID token from local storage
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("selectedTenant");
};

export const isAuthenticated = () => {
  // Check whether the current time is past the
  // access token's expiry time
  if (authenticationOff) {
    return true;
  }

  const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
  const now = new Date().getTime();
  return now < expiresAt;
};

export const token = () => localStorage.getItem("id_token");

export const decodedToken = (): Token => {
  const id_token = token();
  return id_token && jwtDecode(id_token);
};

export const showLock = () => lock.show();

const checkSessionOptions = {
  responseType: "token id_token",
};

const lock = new Auth0Lock(AUTH0_CLIENT_ID, AUTH0_DOMAIN, {
  allowedConnections: ["optiopartners-waad"],
  defaultEnterpriseConnection: "optiopartners-waad",
  allowSignUp: false,
  closable: false,
  allowAutocomplete: true,
  passwordlessMethod: AUTH0_PASSWORDLESS_METHOD,
  languageDictionary: lockLanguageSpec,
  responseType: "token id_token",
  rememberLastLogin: false,
  configurationBaseUrl: AUTH0_CONFIGURATION_BASE_URL,
  auth: {
    redirectUrl: AUTH_REDIRECT_URL,
    params: {
      scope: "openid profile",
    },
    responseType: "token",
  },
  theme: {
    labeledSubmitButton: false,
    logo:
      "https://storage.googleapis.com/optio-incentives-public/logos/4_EPS.gif",
    primaryColor: "#607D8B",
  },
  prefill: {
    email: localStorage.getItem("last_used_email"),
  },
  autofocus: false,
});

interface AuthResult {
  idTokenPayload: any;
  idToken: string;
}
