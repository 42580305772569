export const POST_WINDOW = "POST_WINDOW";
export const POST_WINDOW_SUCCEEDED = "POST_WINDOW_SUCCEEDED";
export const POST_WINDOW_FAILED = "POST_WINDOW_FAILED";

export const FETCH_WINDOW = "FETCH_WINDOW";
export const FETCH_WINDOW_SUCCEEDED = "FETCH_WINDOW_SUCCEEDED";
export const FETCH_WINDOW_FAILED = "FETCH_WINDOW_FAILED";

export const DELETE_WINDOW = "DELETE_WINDOW";
export const DELETE_WINDOW_SUCCEEDED = "DELETE_WINDOW_SUCCEEDED";
export const DELETE_WINDOW_FAILED = "DELETE_WINDOW_FAILED";

export const PUT_WINDOW = "PUT_WINDOW";
export const PUT_WINDOW_FAILED = "PUTE_WINDOW_FAILED";
export const PUT_WINDOW_SUCCEEDED = "PUT_WINDOW_SUCCEEDED";

export const FETCH_WINDOW_ORDERS = "FETCH_WINDOW_ORDERS";
export const FETCH_WINDOW_ORDERS_SUCCEEDED = "FETCH_WINDOW_ORDERS_SUCCEEDED";
export const FETCH_WINDOW_ORDERS_FAILED = "FETCH_WINDOW_ORDERS_FAILED";

export const WINDOW_STATE_START_PROCESSING = "WINDOW_STATE_START_PROCESSING";
export const WINDOW_STATE_START_PROCESSING_SUCCEEDED =
  "WINDOW_STATE_START_PROCESSING_SUCCEEDED";
export const WINDOW_STATE_START_PROCESSING_FAILED =
  "WINDOW_STATE_START_PROCESSING_FAILED";

export const WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS =
  "WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS";
export const WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS_FAILED =
  "WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS_FAILED";

export const WINDOW_COMPLETE_CASHLESS_ORDERS =
  "WINDOW_COMPLETE_CASHLESS_ORDERS";
export const WINDOW_COMPLETE_CASHLESS_ORDERS_FAILED =
  "WINDOW_COMPLETE_CASHLESS_ORDERS_FAILED";

export const WINDOW_COMPLETE_SALE_CASHLESS_ORDERS =
  "WINDOW_COMPLETE_SALE_CASHLESS_ORDERS";
export const WINDOW_COMPLETE_SALE_CASHLESS_ORDERS_FAILED =
  "WINDOW_COMPLETE_SALE_CASHLESS_ORDERS_FAILED";
