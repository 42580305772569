import { store } from "src/app";
import { API_ROOT } from "src/env";
import {
  GENERAL_API_REQUEST_FAILED,
  GENERAL_API_REQUEST_SUCCESS,
} from "src/reducers/general-api-request-reducer";

const HTTPMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};
export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export interface Request {
  method: Method;
  body?: RequestBody;
  url: string;
  token: string;
}

type RequestBody = CreateEmployeeDocumentBody;

interface CreateEmployeeDocumentBody {}

const settings = (method: string, body: any, token: string): RequestInit => {
  const bodyOrUndefined = body ? body : undefined; // Edge requires the body to be undefined on GET requests
  return {
    method: method ? method : HTTPMethod.GET,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    mode: "cors",
    body: bodyOrUndefined,
  };
};

export const NOT_AUTHORIZED = 401;
const checkHTTPStatus = (response): Promise<Response> => {
  return new Promise((resolve, reject) => {
    if (response.status == NOT_AUTHORIZED) {
      reject({
        errorMessage: "User not authorized",
        status: response.status,
        response,
      });
    }

    if (!response.ok) {
      reject({
        errorMessage: "Something wrong happened",
        status: response.status,
        response,
      });
      return;
    }

    resolve(response);
  });
};

export function callApi(endpoint, token?: string, method?: string, body?: any) {
  const url = API_ROOT + endpoint;
  const jsonBody = body ? JSON.stringify(body) : null;

  return new Promise((resolve, reject) => {
    return fetch(url, settings(method, jsonBody, token))
      .then(checkHTTPStatus)
      .then(response => {
        store.dispatch({ type: GENERAL_API_REQUEST_SUCCESS });
        if (response.status === 204) {
          return resolve({});
        }

        if (!response.headers.get("Content-Type").includes("json")) {
          return resolve(response);
        }

        return resolve(response.json());
      })
      .catch(error => {
        store.dispatch({ type: GENERAL_API_REQUEST_FAILED });
        console.error(error);
        reject(error);
      });
  });
}

export function submitForm(endpoint: string, token: string, body: FormData) {
  const url = API_ROOT + endpoint;

  return new Promise((resolve, reject) => {
    return fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
      .then(checkHTTPStatus)
      .then(response => resolve(response.json()))
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
}
