module.exports = {
    "currency.conversion.message": "<div>{baseCurrency} 1 {overrideCurrency} {conversionFactor}</div><div>Source: The central bank of Norway</div>",
  "menu.overview": "Overview",
  "menu.support": "Support",
  "menu.documents": "Documents",
  "menu.admin": "Admin",
  "menu.profile": "Profile",
  "menu.logout": "Logout",
  "sharePrice.message.title": "Last available closing price: ",
  "sharePrice.message.popup.header": "Share price",
  "sharePrice.message.popup.content.lastAvailablePrice": "Last available share price <br>{date}",
  "sharePrice.message.popup.content.endOfDayPrice": "End of day price {date}",
  "showExpired.label": "Show expired",
  "showReleased.label": "Show released",
  "global.contactEmail": "support@optioincentives.no",
  "global.contactPhone": "+47 22 34 33 32",
  "global.tableLabel.program": "Program",
  "global.tableLabel.grantDate": "Grant date",
  "global.tableLabel.vestingDate": "Vesting date",
  "global.tableLabel.releaseDate": "Release date",
  "global.tableLabel.expiryDate": "Expiry date",
  "global.tableLabel.sharePrice": "Share price",
  "global.tableLabel.performance": "Performance",
  "global.tableLabel.quantity": "Quantity",
  "global.tableLabel.total": "Total",
  "global.tableLabel.totalValue": "Total estimated value",
  "global.tableLabel.sum": "Sum",
  "global.tableLabel.instrumentType": "Instrument type",
  "global.tableLabel.totalQuantity": "Total quantity",
  "global.tableLabel.totalGain": "Total estimated gain",
  "global.tableLabel.totalperfadjquantity": "Total perf. adj. quantity",
  "global.tableLabel.totalperfadjgain": "Total perf. adj. gain",
  "global.tableLabel.vestedQuantity": "Vested quantity",
  "global.tableLabel.vestedGain": "Vested estimated gain",
  "global.tableLabel.fileName": "File name",
  "global.viewDetailsBtn": "View details",
  "global.viewOrdersBtn": "View orders",
  "global.downloadFileTxt": "Download file",
  "global.downloadTxt": "Download",
  "global.backBtnTxt": "Back",
  "global.nextBtnTxt": "Next",
  "global.saveBtnTxt": "Save",
  "global.acceptAndProceedBtnTxt": "Accept and proceed",
  "global.markAsAccepted": "Mark as accepted",
  "global.accepted": "Accepted, ",
  "documentsPage.title": "Documents",
  "documentsPage.empty.header": "No documents",
  "documentsPage.empty.content": "Documents related to your instruments will appear here.",
  "acceptdocuments.modal.title": "Action Required",
  "acceptdocuments.modal.accepted.title": "Accepted",
  "acceptdocuments.modal.accepted.content": "Your documents are available to view within the portal",
  "purchase.title": "Purchase",
  "purchase.steps.01.title": "Purchase Agreement",
  "purchase.steps.01.description": "Read and accept purchase terms",
  "purchase.steps.02.title": "Quantity",
  "purchase.steps.02.description": "Choose purchase quantity",
  "purchase.steps.cash.title": "Amount",
  "purchase.steps.cash.description": "Choose amount to purchase for",
  "purchase.steps.03.title": "Securities Account",
  "purchase.steps.04.title": "Confirm order",
  "purchase.acceptdocument.header": "Read and accept purchase agreement",
  "purchase.acceptdocument.body": "The terms for your purchase",
  "purchase.cash.title": "How much would you like to purchase for?",
  "purchase.cash.body": "You have the option to buy {instrumentTermPlural} for up to {maximumCashAmount}, with a discount of {discount} per {instrumentTermSingular}. The price for each {instrumentTermSingular} is will be determined by... The last available share price is {sharePrice}",
  "purchase.cash.errMessageHeader": "Errors",
  "purchase.cash.errMessageContent": "Total amount must be a number less than or equal to the maximum purchasable amount {maximumCashAmount}",
  "purchase.cash.label": "Amount (max is {maximumCashAmount})",
  "purchase.cash.discount": "Discount: {discountPercentage}",
  "purchase.cash.estimatedShares.label": "Est. {instrumentTermPlural} you receive",
  "purchase.cash.estimatedShares.popup": "Est. {instrumentTermPlural} you receive",
  "purchase.cash.marketValue.label": "Market value",
  "purchase.cash.marketValue.popup": "Market value",
  "purchase.cash.discount.label": "Your discount ({discountPercentage})",
  "purchase.cash.discount.popup": "Your discount ({discountPercentage})",
  "purchase.quantity.title": "How many would you like to purchase?",
  "purchase.quantity.body": "The price for each {instrumentTermSingular} is {price}",
  "purchase.quantity.errMessageHeader": "Errors",
  "purchase.quantity.errMessageContent": "Purchase quantity must be a number less than or equal to the maximum purchasable quantity {maximumQuantity}",
  "purchase.quantity.label": "Purchase quantity (maximum quantity is {maximumQuantity})",
  "purchase.quantity.totalPurchasePrice": "Total purchase price: {totalPurchasePrice}",
  "purchase.sharedepository.header": "In which Share Depository Account would you like to receive your {instrumentTermPlural}?",
  "purchase.sharedepository.body": "Kanskje noe om prosessen for overføring? Og eventuelt om binding?",
  "purchase.confirm.header": "Confirm order",
  "purchase.confirm.body.1": "You have chosen to purchase {numberOfInstruments} {instrumentTermPlural} for a price per {instrumentTermSingular} of {pricePerInstrument}.",
  "purchase.confirm.body.2": "The total price for all {instrumentTermPlural} is {totalAmount}",
  "purchase.cash.confirm.body.1": "You are about to purchase {instrumentTermPlural} for {purchaseCashAmount}. The payment information below will also be sent to you by email once you confirm your order.",
  "purchase.confirm.description": "Please transfer the total price of {totalPrice} using the payment information below. The full amount must be transferred and registered into the account before {transferDeadline}. If payment is not registered in time, the purchase will not be valid. The transfer of funds might take several days and we encourage you to do this as soon as possible in order for the payment to be registered on time. ",
  "purchase.confirm.btnText": "Confirm purchase order",
  "purchase.type.warrant": "Purchase of warrants",
  "purchase.type.option": "Purchase of options",
  "purchase.type.rsu": "Purchase of RSUs",
  "purchase.type.rsa": "Purchase of RSAs",
  "purchase.not.possible.notinwindow.header": "Not in a purchase window",
  "purchase.not.possible.notinwindow.description": "Wait for a purchase window to open in order to purchase.",
  "purchase.not.possible.nopurchasableinstruments.header": "Purchase not possible",
  "purchase.not.possible.nopurchasableinstruments.description": "No purchasable instruments available",
  "purchase.popup.button.start": "Start purchase process",
  "purchase.popup.congratz.header": "CONGRATULATIONS",
  "purchase.popup.congratz.subheader": "You have Options available to purchase",
  "purchase.popup.description": "<div><p>A total number of {purchasableQuantity} {instrumentTerm} are available for purchase at a price of {price} per {instrumentTermSingular} </p><p>Use the portal to accept the general terms and conditions and register your purchase.</p><p>The purchase window closes at {windowCloseDate}</p></div>",
  "purchase.popup.description.cashpurchase": "<div><p>You are able to purchase {instrumentTerm} for the total amount of {purchasableCashAmount}</p><p>Use the portal to accept the general terms and conditions and register your purchase.</p><p>The purchase window closes at {windowCloseDate}</p></div>",
  "performace.information": "Some or all instruments above are subject to performance criteria",
  "performace.information.question": "Your instruments are subject to performance, meaning that there exists one or more additional criteria that must be fulfilled in order to earn the instruments. View your agreement documents to see your specific performance criteria",
  "exercise.title": "Exercise {instrumentName}",
  "banner.exercise.next.window": "Next exercise window will be from {startDate} to {endDate}",
  "banner.exercise-release.next.window": "Next exercise window will be from {startDate} to {endDate}",
  "banner.exercise.button": "Start Exercising",
  "overview.exercise.button": "Start Exercising",
  "banner.exercise.window": "Current exercise window closes on {endDate}",
  "banner.exercise-release.window": "Current release window closes on {endDate}",
  "banner.purchase.next.message": "Next purchase window will be from {fromDate} to {toDate}",
  "banner.purchase.current.message": "Current purchase window closes on {date}",
  "banner.purchase.current.button": "Start Purchasing",
  "overview.exercise.in.window.description": "Current exercise window closes on {endDate}",
  "overview.exercise-release.in.window.description": "Current release window closes on {endDate}",
  "overview.exercise.not.in.window.next.window.description": "Exercising will be available when you have exercisible options and we're in an exercise window. Next exercise window will be from {from} to {to}.",
  "overview.exercise.not.in.window.no.next.window.description": "Exercising will be available when you have exercisible options and we're in an exercise window. When the next exercise window opens will be determined by your employer.",
  "overview.viewOrders.button": "View orders",
  "overview.viewDetails.button": "View details",
  "overview.sections.viewDetails.button": "View details",
  "overview.sections.viewOrders.button": "View orders",
  "exercise.exercise.note": "Only exercisable instruments are shown in the table",
  "exercise.quantity.body": "Note that Estimated gain below is based on last available share price ({sharePrice}). Actual gain will depend on market prices following the exercise window.",
  "exercise.quantity.title": "How many of the exercisible {instrumentTerm} would you like to exercise?",
  "exercise.quantity.errMessageHeader": "Errors",
  "exercise.quantity.errMessageContent": "Order quantity must be a number less than or equal to the exercisible quantity",
  "exercise.type.title": "How would you like to exercise?",
  "exercise.step.quantity.title": "Quantity",
  "exercise.step.quantity.description": "Choose quantity to excercise",
  "exercise.step.type.title": "Exercise type",
  "exercise.step.type.description": "Choose how to exercise",
  "exercise.step.terms.title": "Terms",
  "exercise.step.terms.description": "Review terms",
  "exercise.step.confirm.title": "Confirm Order",
  "exercise.type.exerciseandhold.terms": "Your shares will be transferred to the submitted share depository account dependent on the completion of the following: <br><br> 1) Total strike amount for the corresponding shares must be transferred to the company supplied bank account number, and verified by the company, before the set payment deadline.<br><br> 2) Any documentation needed for the share capital increase (and related) with respect to governmental (or other institutions) requirements, must be fulfilled by the company and you as a participant.<br><br> 3) You as the participant must supply a valid Share Depository Account. If the total strike amount is not registered on the company account within the given deadline, the options will not be exercised	",
  "exercise.type.exerciseandsell.terms": "Your shares will be transferred to the submitted share depository account dependent on the completion of the following: <br><br> 1) Total strike amount for the corresponding shares must be transferred to the company supplied bank account number, and verified by the company, before the set payment deadline.<br><br> 2) Any documentation needed for the share capital increase (and related) with respect to governmental (or other institutions) requirements, must be fulfilled by the company and you as a participant.<br><br> 3) You as the participant must supply a valid Share Depository Account. If the total strike amount is not registered on the company account within the given deadline, the options will not be exercised	",
  "exercise.type.exerciseandselltocover.terms": "Your shares will be transferred to the submitted share depository account dependent on the completion of the following: <br><br> 1) Total strike amount for the corresponding shares must be transferred to the company supplied bank account number, and verified by the company, before the set payment deadline.<br><br> 2) Any documentation needed for the share capital increase (and related) with respect to governmental (or other institutions) requirements, must be fulfilled by the company and you as a participant.<br><br> 3) You as the participant must supply a valid Share Depository Account. If the total strike amount is not registered on the company account within the given deadline, the options will not be exercised	",
  "exercise.type.exerciseandhold.header": "1. Exercise & Hold",
  "exercise.type.exerciseandhold": "Exercise & Hold",
  "exercise.type.exerciseandhold.description": "Exercise your {instrumentName} and cover the total strike amount using your own funds, receive all shares following the exercise to your share depository account",
  "exercise.type.exerciseandsell.header": "2. Exercise & Sell",
  "exercise.type.exerciseandsell": "Exercise & Sell",
  "exercise.type.exerciseandsell.description": "Exercise your {instrumentName}, broker will cover the total strike amount, broker will receive shares and sell all the received shares in the market. You will receive the net gain into supplied bank account, after total strike amount and broker fee is covered",
  "exercise.type.exerciseandselltocover.header": "3. Exercise & Sell to cover",
  "exercise.type.exerciseandselltocover": "Exercise & Sell to cover",
  "exercise.type.exerciseandselltocover.description": "Exercise your {instrumentName}, broker will cover the total strike amount, broker will receive shares and sell all the received shares in the market. Broker will then purchase shares for the remaining amount, after total strike amount and broker fee is covered, and transfer these to your share depository account",
  "exercise.type.sharedepositoryaccount.header": "Share Depository Account",
  "exercise.type.sharedepositoryaccount.description": "In order to transfer your shares to you we need your Share Depository Account number",
  "exercise.type.sharedepositoryaccount.label": "Share Depository Account Number",
  "exercise.type.sharedepositoryaccount.placeholder": "Share Depository Account",
  "exercise.type.sharedepositoryaccount.checkbox.label": "Share Depository Account is under establishment / not ready",
  "exercise.type.sharedepositorybank.label": "Share Depository Bank",
  "exercise.type.sharedepositorybank.label.popup": "Share Depository Bank",
  "exercise.type.sharedepositorybank.placeholder": "Share Depository Bank",
  "exercise.type.sharedepositoryclearingcode.label": "Share Depository Clearing Code",
  "exercise.type.sharedepositoryclearingcode.popup": "Share Depository Clearing Code",
  "exercise.type.sharedepositoryclearingcode.placeholder": "Share Depository Clearing Code",
  "exercise.type.sharedepositorycontact.label": "Share Depository Contact (if relevant)",
  "exercise.type.sharedepositorycontact.popup": "Share Depository Contact (if relevant)",
  "exercise.type.sharedepositorycontact.placeholder": "Share Depository Contact (if relevant)",
  "exercise.type.sharedepositorydescription.label": "Share Depository Description (if relevant)",
  "exercise.type.sharedepositorydescription.label.popup": " Share Depository Description Popup message",
  "exercise.type.sharedepositorydescription.popup": "Share Depository Description (if relevant)",
  "exercise.type.sharedepositorydescription.placeholder": "Share Depository Description (if relevant)",
  "exercise.type.bankaccount.checkbox.label": "I will supply this information later",
  "exercise.type.bankaccount.header": "Bank Account",
  "exercise.type.bankaccount.description": "In order to transfer funds please supply your bank account number",
  "exercise.type.bankaccount.label": "Bank Account Number",
  "exercise.type.bankaccount.placeholder": "Bank Account Number",
  "exercise.type.bic.label": "BIC/SWIFT (foreign accounts only)",
  "exercise.type.bic.placeholder": "BIC/SWIFT",
  "exercise.confirm.termsAcceptanceBtnText": "I hereby accept and proceed",
  "exercise.confirm.exerciseandhold.body": "You have chosen to excercise <strong>{totalQuantity}</strong> {instrumentTerm} with the average strike price of <strong>{averageCost}</strong>. <br>The total strike price/cost of exercising the {instrumentTerm} is <strong>{totalCostOfExercise}</strong>.",
  "exercise.confirm.exerciseandsell.body": "You have chosen to excercise <strong>{totalQuantity}</strong> {instrumentTerm}. The average strike price is <strong>{averageCost}</strong>. The total strike price/cost of exercising the {instrumentTerm} is <strong>{totalCostOfExercise}</strong>. This total cost will be covered by the broker. Net gain, after total strike and broker fee is covered, will be paid as salary by the company.",
  "exercise.confirm.exerciseandsell.body.2": "Note that Estimated gain below is based on last available share price ({sharePrice}). Actual gain will depend on market prices following the exercise window.",
  "exercise.confirm.exerciseandselltocover.body": "You have chosen to excercise <strong>{totalQuantity}</strong> {instrumentTerm}. The average strike price is <strong>{averageCost}</strong>. The total strike price/cost of exercising the {instrumentTerm} is <strong>{totalCostOfExercise}</strong>. This total cost will be covered by the broker. Net gain, after total strike and broker fee is covered, will be used to purchase new shares in the company.",
  "exercise.confirm.exerciseandselltocover.body.2": "Note that Estimated gain below is based on last available share price ({sharePrice}). Actual gain will depend on market prices following the exercise window.",
  "exercise.confirm.button": "Confirm exercise order",
  "exercise.quantity.table.program": "Program",
  "exercise.quantity.table.strike": "Strike/Price",
  "exercise.quantity.table.grant": "Grant Date",
  "exercise.quantity.table.vested": "Vested Date",
  "exercise.quantity.table.expiry": "Expiry Date",
  "exercise.quantity.table.exercisiblequantity": "Exercisible",
  "exercise.quantity.table.orderquantity": "Order quantity",
  "exercise.quantity.table.gain": "Estimated gain",
  "exercise.quantity.table.totalEstimatedgain": "Total estimated gain",
  "exercise.confirm.table.exercisetype.label": "Exercise type",
  "exercise.confirm.table.quantityexercised.label": "Number of {instrumentTerm} exercised",
  "exercise.confirm.table.totalcost.label": "Total cost",
  "exercise.confirm.table.avgprice.label": "Average price per {instrumentTerm}",
  "exercise.confirm.table.bankaccount.label": "Bank Account",
  "exercise.confirm.table.bic.label": "BIC/SWIFT",
  "exercise.confirm.table.brokerfee.label": "Broker fee",
  "exercise.confirm.exercise.and.sell.payment.description": "Please transfer the total strike price of {totalPrice} using the payment information below. The full amount must be transferred and registered into the account before {transferDeadline}. If payment is not registered in time, the options will fail to be exercised. The transfer of funds might take several days, we encourage to do this as soon as possible in order for the payment to be registered in time. ",
  "exercise.complete.header": "Thank you for placing your order",
  "exercise.complete.description": "You may cancel your order any time before the current window closes on {windowEndDate}",
  "exerciseNavigationButtons.back": "Back",
  "exerciseNavigationButtons.next": "Next",
  "exerciseNavigationButtons.cancel": "Cancel",
  "purchase.complete.header": "Thank you for placing your order",
  "purchase.complete.description": "You may cancel your order any time before the current window closes on {windowEndDate}",
  "payment.header": "Payment Information",
  "payment.address.label": "Payment address:",
  "payment.description": "Please transfer the total strike price of {totalPrice} using the payment information below. The full amount must be transferred and registered into the account before {transferDeadline}. If payment is not registered in time, the options will fail to be exercised. The transfer of funds might take several days, we encourage to do this as soon as possible in order for the payment to be registered in time. ",
  "payment.domestic.account.header": "1. From domestic accounts:",
  "payment.domestic.account.description": "Use bank account number {bankAccountNumber}",
  "payment.domestic.noaccount.description": "Use the bank account number given to you by your employer",
  "payment.foreign.account.header": "2. From foreign accounts:",
  "payment.foreign.noaccount.description": "Use the payment information given to you by your employer",
  "faq.title": "Support",
  "faq.content": "<p>We're available for support during working hours 09:00-16:00 CET.</p> <p>If you have any questions, don't hesitate to contact us at {email}, by calling {phone} or by using the chat in the bottom right corner.</p>",
  "exercise.confirm.header": "Review & Confirm",
  "exercise.confirm.table.header": "Order Summary",
  "exercise.confirm.table.sharedepositoryaccount.label": "Share Depository Account",
  "exercise.confirm.table.sharedepositorybank.label": "Share Depository Bank",
  "exercise.confirm.table.sharedepositoryclearingcode.label": "Share Depository Clearing Code",
  "exercise.confirm.table.sharedepositorycontact.label": "Share Depository Contact",
  "exercise.confirm.table.sharedepositorydescription.label": "Share Depository Description",
  "exercise.confirm.table.sharePrice.label": "Share price",
  "exercise.confirm.table.estimatedNetGain.label": "Estimated net gain",
  "exercise.confirm.table.note": "Estimated gain is not actual gain",
  "orders.exercisecomplete": "",
  "instrument.warrant.singular": "warrant",
  "instrument.warrant.plural": "warrants",
  "instrument.option.singular": "option",
  "instrument.option.plural": "options",
  "instrument.rsu.singular": "RSU",
  "instrument.rsu.plural": "RSUs",
  "instrument.psu.singular": "PSU",
  "instrument.psu.plural": "PSUs",
  "instrument.rsa.singular": "RSA",
  "instrument.rsa.plural": "RSAs",
  "instrument.subscription_rights.singular": "subscription right",
  "instrument.subscription_rights.plural": "subscription rights",
  "instruments.frontpage.noInstrumentsMessage.header": "You have no instruments yet",
  "instruments.frontpage.noInstrumentsMessage.content": "Instruments you purchase and instruments you are granted will show up here.",
  "instruments.frontpagebox.title": "Total Estimated Gain",
  "instruments.frontpagebox.popup": "This is the estimated gain on all your awarded instruments that are still active (not released, exercised or expired), not taking into account any restrictions, vesting periods, and such",
  "instruments.frontpagebox.disclaimer": "* Note that all values are estimates based on the latest available share price",
  "instruments.frontpagebox.option.header": "Employee Stock Options",
  "instruments.frontpagebox.option.totalinstruments": "Quantity, all options",
  "instruments.frontpagebox.option.totalinstruments.popup": "This is the total awarded options that are still active (not exercised or expired)",
  "instruments.frontpagebox.option.totalgain": "Estimated gain, all options",
  "instruments.frontpagebox.option.totalgain.popup": "This is the estimated gain on all your awarded options that are still active (not released, exercised or expired), not taking into account any restrictions, vesting periods, and such",
  "instruments.frontpagebox.option.vestedgain": "Estimated gain, vested options",
  "instruments.frontpagebox.option.vestedgain.popup": "This is the estimated gain on all your awarded options that are still active (not exercised or expired) and vested",
  "instruments.frontpagebox.option.vestedinstruments": "Quantity, vested options",
  "instruments.frontpagebox.option.vestedinstruments.popup": "This is the number of options that are still active (not exercised or expired) and vested",
  "instruments.frontpagebox.warrant.header": "Warrants",
  "instruments.frontpagebox.warrant.totalinstruments": "Quantity, all warrants",
  "instruments.frontpagebox.warrant.totalinstruments.popup": "Quantity, all instruments",
  "instruments.frontpagebox.warrant.totalgain": "Estimated gain, all warrants",
  "instruments.frontpagebox.warrant.totalgain.popup": "Estimated gain, all instruments",
  "instruments.frontpagebox.warrant.vestedgain": "Estimated gain, vested instruments",
  "instruments.frontpagebox.warrant.vestedgain.popup": "Estimated gain, vested warrants",
  "instruments.frontpagebox.warrant.vestedinstruments": "Quantity, vested warrants",
  "instruments.frontpagebox.warrant.vestedinstruments.popup": "Quantity, vested instruments",
  "instruments.frontpagebox.subscriptionright.header": "Subscription Rights",
  "instruments.frontpagebox.subscriptionright.totalinstruments": "Quantity, all instruments",
  "instruments.frontpagebox.subscriptionright.totalinstruments.popup": "Quantity, all instruments",
  "instruments.frontpagebox.subscriptionright.totalgain": "Estimated gain, all instruments",
  "instruments.frontpagebox.subscriptionright.totalgain.popup": "Estimated gain, all instruments",
  "instruments.frontpagebox.subscriptionright.vestedgain": "Estimated gain, vested instruments",
  "instruments.frontpagebox.subscriptionright.vestedgain.popup": "Estimated gain, vested instruments",
  "instruments.frontpagebox.subscriptionright.vestedinstruments": "Quantity, vested instruments",
  "instruments.frontpagebox.subscriptionright.vestedinstruments.popup": "Quantity, vested instruments",
  "instruments.frontpagebox.rsu.header": "Restricted Share Units (RSU)",
  "instruments.frontpagebox.rsu.totalinstruments": "Quantity, all RSUs",
  "instruments.frontpagebox.rsu.totalinstruments.popup": "This is the total awarded RSUs that are still active (not released, exercised or expired)",
  "instruments.frontpagebox.rsu.totalgain": "Estimated gain, all RSUs",
  "instruments.frontpagebox.rsu.totalgain.popup": "This is the estimated gain on all your awarded RSUs that are still active (not released, exercised or expired), not taking into account any restrictions, vesting periods, and such",
  "instruments.frontpagebox.psu.header": "Performance Share Units (PSU)",
  "instruments.frontpagebox.psu.maxinstruments": "Maximum performance quantity",
  "instruments.frontpagebox.psu.maxinstruments.popup": "This is the number of PSUs you will receive if the maximum performance target is met",
  "instruments.frontpagebox.psu.maxgain": "Maximum performance gain",
  "instruments.frontpagebox.psu.maxgain.popup": "This is the estimated gain of your PSUs if the maximum performance target is met",
  "instruments.frontpagebox.psu.perfadjgain": "Performance adjusted gain",
  "instruments.frontpagebox.psu.perfadjgain.popup": "This is the estimated gain of your PSUs at the current performance target",
  "instruments.frontpagebox.psu.perfadjinstruments": "Performance adjusted quantity",
  "instruments.frontpagebox.psu.perfadjinstruments.popup": "This is the number of PSUs you will receive at the current performance target",
  "instruments.show.expired": "Show expired",
  "instruments.all.frontpage.header": "My Total Outstanding Instruments",
  "instruments.all.frontpage.vestedgain": "ESTIMATED GAIN, VESTED INSTRUMENTS",
  "instruments.all.frontpage.totalgain": "ESTIMATED GAIN, ALL INSTRUMENTS",
  "instruments.all.frontpage.instruments": "TOTAL NUMBER OF INSTRUMENTS",
  "instruments.option.frontpage.header": "My Outstanding Employee Stock Options",
  "instruments.option.frontpage.vestedgain": "ESTIMATED GAIN, VESTED OPTIONS",
  "instruments.option.frontpage.totalgain": "ESTIMATED GAIN, ALL OPTIONS",
  "instruments.option.frontpage.instruments": "TOTAL NUMBER OF OPTIONS",
  "instruments.subscriptionrights.frontpage.header": "My Outstanding Employee Subscription Rights",
  "instruments.subscriptionrights.frontpage.vestedgain": "ESTIMATED GAIN, VESTED SUBSCRIPTION RIGHTS",
  "instruments.subscriptionrights.frontpage.totalgain": "ESTIMATED GAIN, ALL SUBSCRIPTION RIGHTS",
  "instruments.subscriptionrights.frontpage.instruments": "TOTAL NUMBER OF SUBSCRIPTION RIGHTS",
  "instruments.warrant.frontpage.header": "My Outstanding Warrants",
  "instruments.warrant.frontpage.vestedgain": "ESTIMATED GAIN, VESTED WARRANTS",
  "instruments.warrant.frontpage.totalgain": "ESTIMATED GAIN, ALL WARRANTS",
  "instruments.warrant.frontpage.instruments": "TOTAL NUMBER OF WARRANTS",
  "instruments.fund.frontpage.header": "My Outstanding Deferred Fund Units",
  "instruments.fund.frontpage.vestedgain": "ESTIMATED VALUE RELEASED AT NEXT RELEASE DATE",
  "instruments.fund.frontpage.totalgain": "ESTIMATED VALUE RELEASED ALL FUND UNITS",
  "instruments.fund.frontpage.instruments": "TOTAL NUMBER OF FUND UNITS",
  "instruments.cash.frontpage.header": "Deferred Cash",
  "instruments.cash.frontpage.vestedgain": "TOTAL OUTSTANDING CASH",
  "instruments.cash.frontpage.totalgain": "TOTAL OUTSTANDING CASH",
  "instruments.cash.frontpage.instruments": "TOTAL NUMBER OF FUND SHARES",
  "instruments.rsu.frontpage.header": "My Outstanding Restricted Share Units (RSUs)",
  "instruments.rsu.frontpage.vestedgain": "ESTIMATED GAIN, VESTED RSUS",
  "instruments.rsu.frontpage.totalgain": "ESTIMATED GAIN, ALL RSUS",
  "instruments.rsu.frontpage.instruments": "TOTAL NUMBER OF RSUS",
  "instruments.rsa.frontpage.header": "My Outstanding Restricted Share Awards (RSAs)",
  "instruments.rsa.frontpage.vestedgain": "ESTIMATED GAIN, VESTED RSAS",
  "instruments.rsa.frontpage.totalgain": "ESTIMATED GAIN, ALL RSAS",
  "instruments.rsa.frontpage.instruments": "TOTAL NUMBER OF RSAS",
  "instruments.all.frontpage.compact.header": "My Total Outstanding Instruments",
  "instruments.all.frontpage.compact.vestedgain": "ESTIMATED GAIN, VESTED INSTRUMENTS",
  "instruments.all.frontpage.compact.totalgain": "ESTIMATED GAIN, ALL INSTRUMENTS",
  "instruments.all.frontpage.compact.instruments": "TOTAL NUMBER OF INSTRUMENTS",
  "instruments.option.frontpage.compact.header": "Employee Stock Options",
  "instruments.option.frontpage.compact.vestedgain": "ESTIMATED GAIN, VESTED OPTIONS",
  "instruments.option.frontpage.compact.totalgain": "ESTIMATED GAIN, ALL OPTIONS",
  "instruments.option.frontpage.compact.instruments": "TOTAL NUMBER OF OPTIONS",
  "instruments.subscriptionrights.frontpage.compact.header": "Employee Subscription Rights",
  "instruments.subscriptionrights.frontpage.compact.vestedgain": "ESTIMATED GAIN, VESTED SUBSCRIPTION RIGHTS",
  "instruments.subscriptionrights.frontpage.compact.totalgain": "ESTIMATED GAIN, ALL SUBSCRIPTION RIGHTS",
  "instruments.subscriptionrights.frontpage.compact.instruments": "TOTAL NUMBER OF SUBSCRIPTION RIGHTS",
  "instruments.warrant.frontpage.compact.header": "Warrants",
  "instruments.warrant.frontpage.compact.vestedgain": "ESTIMATED GAIN, VESTED WARRANTS",
  "instruments.warrant.frontpage.compact.totalgain": "ESTIMATED GAIN, ALL WARRANTS",
  "instruments.warrant.frontpage.compact.instruments": "TOTAL NUMBER OF WARRANTS",
  "instruments.fund.frontpage.compact.header": "Deferred Fund Units",
  "instruments.fund.frontpage.compact.vestedgain": "ESTIMATED VALUE RELEASED AT NEXT RELEASE DATE",
  "instruments.fund.frontpage.compact.totalgain": "ESTIMATED VALUE RELEASED ALL FUND UNITS",
  "instruments.fund.frontpage.compact.instruments": "TOTAL NUMBER OF FUND UNITS",
  "instruments.cash.frontpage.compact.header": "Deferred Cash",
  "instruments.cash.frontpage.compact.vestedgain": "TOTAL OUTSTANDING CASH",
  "instruments.cash.frontpage.compact.totalgain": "TOTAL OUTSTANDING CASH",
  "instruments.cash.frontpage.compact.instruments": "TOTAL NUMBER OF FUND SHARES",
  "instruments.rsu.frontpage.compact.header": "Restricted Share Units (RSUs)",
  "instruments.rsu.frontpage.compact.vestedgain": "ESTIMATED GAIN, VESTED RSUS",
  "instruments.rsu.frontpage.compact.totalgain": "ESTIMATED GAIN, ALL RSUS",
  "instruments.rsu.frontpage.compact.instruments": "TOTAL NUMBER OF RSUS",
  "instruments.rsa.frontpage.compact.header": "Restricted Share Awards (RSAs)",
  "instruments.rsa.frontpage.compact.vestedgain": "ESTIMATED GAIN, VESTED RSAS",
  "instruments.rsa.frontpage.compact.totalgain": "ESTIMATED GAIN, ALL RSAS",
  "instruments.rsa.frontpage.compact.instruments": "TOTAL NUMBER OF RSAS",
  "instruments.psu.frontpage.compact.header": "Performance Share Units (PSUs)",
  "instruments.psu.frontpage.compact.totalgain": "ESTIMATED GAIN, ALL PSUS",
  "instruments.psu.frontpage.compact.performanceadjustedgain": "ESTIMATED GAIN, PERFORMANCE ADJ. PSUS",
  "instruments.psu.frontpage.compact.instruments": "TOTAL NUMBER OF PSUS",
  "instruments.psu.frontpage.compact.performanceadjustedinstruments": "PERFORMANCE ADJ. NUMBER OF PSUS",
  "instruments.psu.table.criteria.header": "Criteria",
  "instruments.psu.table.grantdate.header": "Award date",
  "instruments.psu.table.maxquantity.header": "Maximum Quantity",
  "instruments.psu.table.thresholds.header": "Performance Criteria",
  "instruments.psu.table.performancestart.header": "Performance Start Date",
  "instruments.psu.table.performanceend.header": "Performance End Date",
  "instruments.psu.table.lastestimatedate.header": "Last Perf. Est. Date",
  "instruments.psu.table.lastprognosis.header": "Last Prognosis",
  "instruments.psu.table.lastthreshold.header": "Last Est. Threshold",
  "instruments.psu.table.fulfillmentpercentage.header": "Last Perf. fulfillment %",
  "instruments.psu.table.estimatedquantity.header": "Estimated quantity",
  "instruments.psu.table.vestingdate.header": "Vesting date",
  "instruments.psu.table.strike.header": "Strike/nom",
  "instruments.psu.table.strikemax.header": "Total maximum strike/nom",
  "instruments.psu.table.strikeperf.header": "Total estimated strike/nom",
  "instruments.psu.table.maxgain.header": "Total maximum gain",
  "instruments.psu.table.performancegain.header": "Total estimated gain",
  "psu.performance.information": "Performance adjustment is based on likely performance achievement",
  "psu.performance.information.question": "Performance adjustment is based on likely performance achievement",
  "instruments.all.details.header": "My Outstanding Instruments",
  "instruments.all.details.description": "Table below display a detailed overview of information connected to your instruments. Please note that all values are based on the last imported share price and that actual gains will vary based on changes in share price and prices achieved in the market",
  "instruments.rsu.details.header": "My Outstanding Restricted Share Units",
  "instruments.rsu.details.description": "Table below display a detailed overview of information connected to your Restricted Share Units. Please note that all values are based on the last imported share price and that actual gains will vary based on changes in share price and prices achieved in the market.",
  "instruments.psu.details.header": "My Outstanding Performance Share Units",
  "instruments.psu.details.description": "Table below display a detailed overview of information connected to your Performance Share Units. Please note that all values are based on the last imported share price and that actual gains will vary based on changes in share price and prices achieved in the market.",
  "instruments.rsa.details.header": "My Outstanding Restricted Share Awards",
  "instruments.rsa.details.description": "Table below display a detailed overview of information connected to your Restricted Share Awards. Please note that total value is based on the last known share price and that actual gains will vary based on changes in share price and prices achieved in the market.",
  "instruments.option.details.header": "My Outstanding Employee Stock Options",
  "instruments.option.details.description": "Table below display a detailed overview of information connected to your options. Please note that total value is based on the last known share price and that actual gains will vary based on changes in share price and prices achieved in the market.",
  "instruments.subcriptionrights.details.header": "My Outstanding Employee Subscription Rights",
  "instruments.subcriptionrights.details.description": "Table below display a detailed overview of information connected to your subscription rights. Please note that total value is based on the last known share price and that actual gains will vary based on changes in share price and prices achieved in the market.",
  "instruments.warrant.details.header": "My Outstanding Warrants",
  "instruments.warrant.details.description": "Table below display a detailed overview of information connected to your warrants. Please note that total value is based on the last known share price and that actual gains will vary based on changes in share price and prices achieved in the market.",
  "instruments.fund.details.header": "My Outstanding Deferred Fund Units",
  "instruments.fund.details.description": "Table below display a detailed overview of information connected to your Deferred Fund Units. Please note that total value is based on the last known share price and that actual gains will vary based on changes in share price and prices achieved in the market.",
  "instruments.cash.details.header": "My Outstanding Deferred Cash",
  "instruments.cash.details.description": "Table below display a detailed overview of information connected to your Deferred Cash.",
  "instruments.quantity.label": "Quantity",
  "instruments.totalvalue.label": "Total net value",
  "instruments.capongain.label": "Cap on gain",
  "instruments.capongain.popup": "Cap on gain",
  "instruments.performance.label": "Performance",
  "instruments.totalstrike.label": "Total strike",
  "instruments.totalstrike.popup": "The price of exercising all the remaining instruments in each table row.",
  "instruments.strike.label": "Strike",
  "instruments.purchaseprice.label": "Purchase price",
  "instruments.exercisequantity.label": "Exercised quantity",
  "instruments.expiry.label": "Expiry date",
  "instruments.vesting.label": "Vesting date",
  "instruments.grant.label": "Grant date",
  "instruments.program.label": "Program",
  "instruments.sum.label": "Sum",
  "instruments.originalquantity.label": "Original quantity",
  "instruments.originalquantity.popup": "Original quantity is ...",
  "instruments.frontpage.vested.popup": "An estimate of the net total gain if your fully vested/earned and unrestricted instruments would be exercised and then all shares sold at the market price displayed above.  Not taking into account any fees or other costs associated with exercising and/or selling.",
  "instruments.frontpage.total.popup": "An estimate of the net total gain if all your instruments would be exercised and then all shares sold at the market price displayed above. Not taking into account any remaining vesting, restrictions, fees or other costs associated with exercising and/or selling.",
  "orders.status.placed": "Order placed",
  "orders.status.completed": "Order completed",
  "orders.status.cancelled": "Order cancelled",
  "orders.ORDER_PLACED.popup.text": "Status ORDER_PLACED text",
  "orders.CANCELLED.popup.text": "Status CANCELLED text",
  "orders.IN_PROCESS.popup.text": "Status IN_PROCESS text",
  "orders.SALE_COMPLETED.popup.text": "Status SALE_COMPLETED text",
  "orders.COMPLETED.popup.text": "Status COMPLETED text",
  "orders.CREATED.popup.text": "Status CREATED text",
  "orders.contact.us": "Contact us at {email} if you have any questions or want to cancel your order",
  "orders.title": "All orders",
  "orders.table.header.orderNumber": "Order number",
  "orders.table.header.orderDate": "Order date",
  "orders.table.header.orderType": "Order type",
  "orders.table.header.orderQuantity": "Order quantity",
  "orders.table.header.orderStatus": "Status",
  "profile.title": "Profile Page",
  "profile.full.name.label": "Full Name",
  "profile.email.label": "Email",
  "profile.language.label": "Language",
  "profile.language.placeholder": "Select language",
  "profile.language.english": "English",
  "profile.language.norwegian": "Norwegian",
  "profile.language.swedish": "Swedish",
  "profile.language.danish": "Danish",
  "profile.currency.label": "Display currency",
  "profile.currency.placeholder": "Display currency",
  "profile.share.depository.account.label": "Share depository account (VPS account, 12 digits)",
  "profile.share.depository.account.placeholder": "VPS account, 12 digits",
  "profile.share.depository.contact.label": "Bank/provider contact details (if relevant)",
  "profile.share.depository.contact.label.popup": "Bank/provider contact details (if relevant)",
  "profile.share.depository.contact.placeholder": "Bank/provider contact details (if relevant)",
  "profile.share.depository.bank.label": "Bank/provider name (if relevant)",
  "profile.share.depository.bank.label.popup": "Bank/provider name (if relevant)",
  "profile.share.depository.bank.placeholder": "Bank/provider name",
  "profile.share.depository.description.label": "Bank/provider SSI (if relevant)",
  "profile.share.depository.description.label.popup": "Standard Settlement Instructions",
  "profile.share.depository.description.placeholder": "Standard Settlement Instructions",
  "profile.share.depository.header": "Share Depository",
  "profile.share.depository.description": "In order to transfer your shares to you we need your Share Depository Account number",
  "profile.share.depository.account.input.placeholder": "Share depository account test",
  "profile.last.time.saved.message": "Last time saved: {time}",
  "profile.change.name.message": "If you want to change your name, please send us an email to <a href='mailto:{email}'>support</a>.",
  "footer.copyright": "Copyright 2018 ",
  "footer.company": "Optio Incentives AS",
  "purchase.placing.order": "Placing order",
  "sessionExpirationBanner.headerText": "Your login will expire in  {minutes} minutes and {seconds} seconds.",
  "sessionExpirationBanner.expired": "Your session has expired.",
  "sessionExpirationBanner.expired.btnText": "Login again",
  "sessionExpirationBanner.btnText": "Keep me logged in"
};
