export const FETCH_TEXTS = "FETCH_TEXTS";
export const FETCH_TEXTS_FAILED = "FETCH_TEXTS_FAILED";
export const FETCH_TEXTS_SUCCEEDED = "FETCH_TEXTS_SUCCEEDED";

export const PUT_TEXTS = "PUT_TEXTS";
export const PUT_TEXTS_FAILED = "PUT_TEXTS_FAILED";
export const PUT_TEXTS_SUCCEEDED = "PUT_TEXTS_SUCCEEDED";

export const UPDATE_DEFAULT_TEXTS = "UPDATE_DEFAULT_TEXTS";
export const UPDATE_DEFAULT_TEXTS_FAILED = "UPDATE_DEFAULT_TEXTS_FAILED";
export const UPDATE_DEFAULT_TEXTS_SUCCEEDED = "UPDATE_DEFAULT_TEXTS_SUCCEEDED";
