export const POST_ENTITY = "POST_ENTITY";
export const POST_ENTITY_FAILED = "POST_ENTITY_FAILED";
export const POST_ENTITY_SUCCEEDED = "POST_ENTITY_SUCCEEDED";

export const FETCH_ENTITIES = "FETCH_ENTITIES";
export const FETCH_ENTITIES_FAILED = "FETCH_ENTITIES_FAILED";
export const FETCH_ENTITIES_SUCCEEDED = "FETCH_ENTITIES_SUCCEEDED";

export const DELETE_ENTITY = "DELETE_ENTITY";
export const DELETE_ENTITY_FAILED = "DELETE_ENTITY_FAILED";
export const DELETE_ENTITY_SUCCEEDED = "DELETE_ENTITY_SUCCEEDED";

export const PUT_ENTITY = "PUT_ENTITY";
export const PUT_ENTITY_FAILED = "PUT_ENTITY_FAILED";
export const PUT_ENTITY_SUCCEEDED = "PUT_ENTITY_SUCCEEDED";

export const CREATE_ALL_ENTITIES = "CREATE_ALL_ENTITIES";
export const CREATE_ALL_ENTITIES_FAILED = "CREATE_ALL_ENTITIES_FAILED";
export const CREATE_ALL_ENTITIES_SUCCEEDED = "CREATE_ALL_ENTITIES_SUCCEEDED";

export const DELETE_ALL_ENTITIES = "DELETE_ALL_ENTITIES";
export const DELETE_ALL_ENTITIES_FAILED = "DELETE_ALL_ENTITIES_FAILED";
export const DELETE_ALL_ENTITIES_SUCCEEDED = "DELETE_ALL_ENTITIES_SUCCEEDED";
