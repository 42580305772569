import { createReducer } from "redux-act";
import {
  deletePerformanceRuleEntrySucceeded,
  fetchPerformanceRuleEntries,
  fetchPerformanceRuleEntriesSucceeded,
  patchPerformanceRuleEntrySucceeded,
  performanceRuleEntriesDestroy,
  postPerformanceRuleEntrySucceeded,
} from "src/admin-portal/performance-rules/performance-rule-entries-page/performance-rule-entries-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface PerformanceRuleEntriesState {
  performanceRuleEntries: Api.V1.PerformanceRuleEntry[];
  isFetching: boolean;
}

export const initialState: PerformanceRuleEntriesState = {
  performanceRuleEntries: null,
  isFetching: false,
};

export default createReducer(on => {
  on(fetchPerformanceRuleEntries, state => ({
    ...state,
    isFetching: true,
  }));
  on(
    fetchPerformanceRuleEntriesSucceeded,
    (state, { performanceRuleEntries }) => ({
      ...state,
      performanceRuleEntries,
      isFetching: false,
    })
  );
  on(performanceRuleEntriesDestroy, state => ({
    ...state,
    performanceRuleEntries: null,
  }));
  on(postPerformanceRuleEntrySucceeded, (state, payload) => ({
    ...state,
    performanceRuleEntries: [payload, ...state.performanceRuleEntries],
  }));
  on(patchPerformanceRuleEntrySucceeded, (state, payload) => ({
    ...state,
    performanceRuleEntries: state.performanceRuleEntries.map(
      pre => (pre.id === payload.id ? payload : pre)
    ),
  }));
  on(deletePerformanceRuleEntrySucceeded, (state, payload) => ({
    ...state,
    performanceRuleEntries: state.performanceRuleEntries.filter(
      pre => pre.id !== payload
    ),
  }));
  on(selectTenant, () => initialState);
}, initialState);
