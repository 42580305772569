import { flattenOneLevel } from "src/common/utils/utils";
import { RootState } from "src/reducers/all-reducers";
import { SupportedLocale } from "./text-reducer";

export const token = state => state.user.token;
export const isSysadmin = state => state.user.isSysadmin;
export const tenantId = state => state.tenant.selectedTenant.id;
export const texts = state => state.text.allTexts;
export const defaultTexts = (state: RootState) => state.text.defaultTexts;
export const defaultTextsForLocale = (locale: SupportedLocale) => (
  state: RootState
) => state.text.defaultTextsLocaled[locale];
export const supportedLocales = (state: RootState) => state.text.locales;
export const allDefaultTextKeys = (state: RootState): string[] => {
  const textsLocaled = state.text.defaultTextsLocaled;
  const allKeysForAllLocales = flattenOneLevel(
    Object.keys(textsLocaled || {}).map(
      key => Object.keys(textsLocaled[key]) || []
    )
  );
  return Array.from(new Set(allKeysForAllLocales));
};
