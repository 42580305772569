export const GENERAL_API_REQUEST_SUCCESS = "GENERAL_API_REQUEST_SUCCESS";
export const GENERAL_API_REQUEST_FAILED = "GENERAL_API_REQUEST_FAILED";

export const generalApiRequestReducer = (state = false, action) => {
  switch (action.type) {
    case GENERAL_API_REQUEST_SUCCESS:
      return false;
    case GENERAL_API_REQUEST_FAILED:
      return true;
    default:
      return state;
  }
};
