import { Auth0LockPasswordless } from "auth0-lock";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  clearLoginStateFromLocalStorage,
  parseAuthUrl,
  setSession,
  showLock,
} from "./auth";
import { employeePortalUserEmail } from "./selectors";

function* parseAuthHash() {
  try {
    const authResult = yield call(parseAuthUrl);
    const userEmail = yield select(employeePortalUserEmail);
    setSession(authResult, userEmail);
    yield put({ type: "PARSE_AUTH_HASH_SUCCEEDED", authResult });

    // Må cleares ved logout eller sesjonstimout hvis det skal brukes
    // const { idTokenPayload } = authResult;
    // Raven.setExtraContext({
    //     name: idTokenPayload && userMetadata(idTokenPayload).name,
    // })
  } catch (e) {
    // Logging of the error happens in auth.ts
    yield put({ type: "PARSE_AUTH_HASH_FAILED", message: e });
  }
}

export function* watchParseAuthHash() {
  yield takeEvery("PARSE_AUTH_HASH", parseAuthHash);
}

export function* showLoginDialog() {
  showLock();
}

export function* watchLoginRequested() {
  yield takeEvery("LOGIN_REQUESTED", showLoginDialog);
}

export function* logout() {
  clearLoginStateFromLocalStorage();
}

export function* watchLogoutRequested() {
  yield takeEvery("LOGOUT_REQUESTED", logout);
}
