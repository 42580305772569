import { createReducer } from "redux-act";
import {
  deleteSharePrice,
  deleteSharePriceSucceeded,
  fetchSharePrices,
  fetchSharePricesSucceeded,
  postSharePrice,
  postSharePriceSucceeded,
} from "src/admin-portal/actions/share-price-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface SharePriceState {
  allSharePrice: Api.V1.SharePrice[];
  isFetching: boolean;
  filtered: {
    [key: string]: Api.V1.SharePrice[];
  };
}

const initialState: SharePriceState = {
  allSharePrice: [],
  isFetching: false,
  filtered: {},
};

const setIsFetching = (state: SharePriceState) => ({
  ...state,
  isFetching: true,
});

export default createReducer(on => {
  on(fetchSharePrices, setIsFetching);
  on(deleteSharePrice, setIsFetching);
  on(postSharePrice, setIsFetching);
  on(postSharePriceSucceeded, (state, payload) => ({
    ...state,
    allSharePrice: [...state.allSharePrice, payload],
    isFetching: false,
  }));
  on(fetchSharePricesSucceeded, (state, payload) => {
    const newState = {
      ...state,
      ...{ allSharePrice: payload.sharePrices },
      isFetching: false,
    };

    if (payload.date) {
      newState.filtered[payload.date] = payload.sharePrices;
    }
    return newState;
  });
  on(deleteSharePriceSucceeded, (state, payload) => ({
    ...state,
    allSharePrice: [
      ...state.allSharePrice.filter(sharePrice => sharePrice.id !== payload),
    ],
    isFetching: false,
  }));
  on(selectTenant, () => initialState);
}, initialState);
