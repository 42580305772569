import moment, { Moment } from "moment";
import { Reducer } from "redux";
import { APIOrder } from "src/admin-portal/orders/orders-reducer";
import { SELECT_TENANT } from "src/admin-portal/tenant/tenant-actions";
import { WindowProcessingState } from "./details/window-details-home";
import {
  DELETE_WINDOW,
  DELETE_WINDOW_SUCCEEDED,
  FETCH_WINDOW,
  FETCH_WINDOW_ORDERS,
  FETCH_WINDOW_ORDERS_SUCCEEDED,
  FETCH_WINDOW_SUCCEEDED,
  POST_WINDOW,
  POST_WINDOW_SUCCEEDED,
  PUT_WINDOW,
  PUT_WINDOW_SUCCEEDED,
  WINDOW_STATE_START_PROCESSING,
  WINDOW_STATE_START_PROCESSING_SUCCEEDED,
} from "./window-actions";

export interface Window {
  id?: string;
  start_time: Moment;
  end_time: Moment;
  payment_deadline: Moment;
  window_type: WindowType;
  restricted?: boolean;
  require_bank_account: boolean;
  require_share_depository: boolean;
  require_share_depository_bank: boolean;
  require_share_depository_clearing_code: boolean;
  require_share_depository_contact: boolean;
  require_share_depository_description: boolean;
  restricted_employees: string[];
  allowed_exercise_types: string[];
  commission_percentage?: number;
  processing_status?: string;
  exercise_and_hold_orders_completed?: boolean;
  cashless_orders_sale_completed?: boolean;
  cashless_orders_completed?: boolean;
  is_release_process?: boolean;
}

export enum WindowType {
  EXERCISE = "EXERCISE",
  PURCHASE = "PURCHASE",
}

export interface WindowState {
  allWindows: Window[];
  window?: Window;
  windowOrders: APIOrder[];
  isFetching: boolean;
  isFetchingOrders: boolean;
  isUpdatingWindowState: boolean;
}

const initialState: WindowState = {
  allWindows: [],
  windowOrders: [],
  window: null,
  isFetching: false,
  isFetchingOrders: false,
  isUpdatingWindowState: false,
};

const toWindow = (w): Window => ({
  id: w.id,
  start_time: moment(w.start_time),
  end_time: moment(w.end_time),
  payment_deadline: moment(w.payment_deadline),
  window_type: w.window_type,
  restricted_employees:
    w.window_restriction && w.window_restriction.employee_restriction
      ? w.window_restriction.window_employee_restrictions.map(
          er => er.employee_id
        )
      : [],
  allowed_exercise_types: w.allowed_exercise_types,
  require_share_depository: w.require_share_depository,
  require_share_depository_bank: w.require_share_depository_bank,
  require_share_depository_clearing_code:
    w.require_share_depository_clearing_code,
  require_bank_account: w.require_bank_account,
  commission_percentage: w.commission_percentage
    ? parseFloat(w.commission_percentage)
    : null,
  processing_status: w.processing_status,
  exercise_and_hold_orders_completed: w.exercise_and_hold_orders_completed,
  cashless_orders_sale_completed: w.cashless_orders_sale_completed,
  cashless_orders_completed: w.cashless_orders_completed,
  is_release_process: w.is_release_process,
  require_share_depository_description: w.require_share_depository_description,
  require_share_depository_contact: w.require_share_depository_contact,
});

const latestWindowFirst = (windowA: Window, windowB: Window): number =>
  moment(windowA.end_time).isSameOrBefore(windowB.end_time) ? 1 : -1;

const windowReducer: Reducer<WindowState> = (state = initialState, action) => {
  if (action.type === POST_WINDOW) {
    return { ...state, ...{ isFetching: true } };
  }
  if (action.type === POST_WINDOW_SUCCEEDED) {
    const sortedExerciseWindow = [
      ...state.allWindows,
      toWindow(action.window),
    ].sort(latestWindowFirst);
    return {
      ...state,
      allWindows: sortedExerciseWindow,
      isFetching: false,
    };
  }

  if (action.type === FETCH_WINDOW) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === FETCH_WINDOW_SUCCEEDED) {
    return {
      ...state,
      allWindows: action.windows.map(toWindow).sort(latestWindowFirst),
      isFetching: false,
    };
  }

  if (action.type === FETCH_WINDOW_ORDERS) {
    return { ...state, ...{ isFetchingOrders: true } };
  }

  if (action.type === FETCH_WINDOW_ORDERS_SUCCEEDED) {
    return {
      ...state,
      windowOrders: action.orders,
      isFetchingOrders: false,
    };
  }

  if (action.type === DELETE_WINDOW) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === DELETE_WINDOW_SUCCEEDED) {
    const exerciseWindow = state.allWindows.filter(
      exerciseWindow => exerciseWindow.id !== action.windowId
    );
    return {
      ...state,
      allWindows: exerciseWindow,
      isFetching: false,
    };
  }

  if (action.type === PUT_WINDOW) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === PUT_WINDOW_SUCCEEDED) {
    const windowIndex = state.allWindows.findIndex(
      window => window.id === action.window.id
    );
    const windows = [...state.allWindows];
    windows[windowIndex] = toWindow(action.window);
    return { ...state, allWindows: windows, isFetching: false };
  }

  if (action.type === WINDOW_STATE_START_PROCESSING) {
    return { ...state, ...{ isUpdatingWindowState: true } };
  }

  if (action.type === WINDOW_STATE_START_PROCESSING_SUCCEEDED) {
    const windows = state.allWindows.map(
      (window: Window) =>
        window.id === action.windowId
          ? { ...window, processing_status: WindowProcessingState.IN_PROCESS }
          : window
    );
    return { ...state, allWindows: windows, isUpdatingWindowState: false };
  }

  if (action.type === SELECT_TENANT) {
    return initialState;
  }

  return state;
};

export default windowReducer;
