import React, { Component } from "react";
import { connect, MapDispatchToProps } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

const websiteUrl = "https://www.optioincentives.no/";

interface DispatchProps {
  logoutRequested: () => void;
}

class LogoutPage extends Component<DispatchProps> {
  public componentWillMount() {
    this.props.logoutRequested();
  }

  public render() {
    return (
      <div className="text-center main-content">
        <h1 className="block-l">You are now logged out</h1>
        <div className="text-center ">
          <Button primary={true} size="big" as={Link} to={"/login"}>
            Log back in
          </Button>
          <a href={websiteUrl} className="webpage-link">
            Go to our web page
          </a>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = (
  dispatch
): DispatchProps => ({
  logoutRequested: () => dispatch({ type: "LOGOUT_REQUESTED" }),
});

export default connect(
  null,
  mapDispatchToProps
)(LogoutPage);
