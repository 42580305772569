import { Moment } from "moment";
import moment from "moment";
import numeral from "numeral";
import { FunctionComponent } from "react";
import React from "react";
import { Table } from "semantic-ui-react";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import { CurrencyFormatterProps } from "src/common/components/currency/currency-formatter";
import { NO_VALUE } from "src/constants";
import { PerformanceCriteria } from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";
import { PerformanceYesPopup } from "../instruments-page";

interface Props {
  programName: string;
  subProgramName: string;
  quantity: number;
  exercisedQuantity: number;
  showExercisedQuantity: boolean;
  grantDate: Moment;
  vestedDate: Moment;
  expiryDate: Moment;
  strike: number;
  capOnGain?: number;
  purchasePrice?: number;
  totalGain: number;
  performance: boolean;
  showPerformance: boolean;
  showPurchasePrice: boolean;
  hasExpired: boolean;
  showCapOnGain: boolean;
  showOriginalQuantity: boolean;
  originalQuantity: number;
  formatter: CurrencyFormatterProps;
  dateFormat: DateFormatState;
  overrideCurrency?: string;
  capped: boolean;
}

const OptionTableRow: FunctionComponent<Props> = ({
  programName,
  subProgramName,
  quantity,
  exercisedQuantity,
  showExercisedQuantity,
  grantDate,
  vestedDate,
  expiryDate,
  strike,
  showCapOnGain,
  capOnGain,
  totalGain,
  showPerformance,
  performance,
  showPurchasePrice,
  purchasePrice,
  hasExpired,
  originalQuantity,
  showOriginalQuantity,
  formatter: { formatCurrency, formatCurrencyWithCurrencyCode },
  dateFormat: { normalDateFormat },
  overrideCurrency,
  capped,
}) => {
  const totalStrikeValue = hasExpired ? 0 : strike * quantity;
  const totalGainValue = hasExpired ? 0 : totalGain;

  return (
    <Table.Row negative={hasExpired}>
      <Table.Cell>
        {" "}
        <div>{programName}</div>
        <div>{subProgramName}</div>
      </Table.Cell>
      <Table.Cell>{moment(grantDate).format(normalDateFormat)}</Table.Cell>
      <Table.Cell>{vestedDate.format(normalDateFormat)}</Table.Cell>
      <Table.Cell>{expiryDate.format(normalDateFormat)}</Table.Cell>
      {showOriginalQuantity && (
        <Table.Cell textAlign="right">
          {originalQuantity > 0 ? numeral(originalQuantity).format() : NO_VALUE}
        </Table.Cell>
      )}
      <Table.Cell textAlign="right">{numeral(quantity).format()}</Table.Cell>
      {showExercisedQuantity && (
        <Table.Cell textAlign="right">
          {numeral(-1 * exercisedQuantity).format()}
        </Table.Cell>
      )}
      {showPurchasePrice && (
        <Table.Cell textAlign="right">
          {purchasePrice ? formatCurrency(purchasePrice, 2) : "N/A"}
        </Table.Cell>
      )}
      <Table.Cell textAlign="right">
        <CurrencyConversionView value={strike} decimals={2} />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <CurrencyConversionView value={totalStrikeValue} decimals={0} />
      </Table.Cell>
      {showCapOnGain && (
        <Table.Cell singleLine={true} warning={capped}>
          {capOnGain ? (
            <CurrencyConversionView value={capOnGain} decimals={2} />
          ) : (
            NO_VALUE
          )}
        </Table.Cell>
      )}
      <Table.Cell textAlign="right">
        <CurrencyConversionView value={totalGainValue} decimals={0} />
      </Table.Cell>
      {showPerformance && (
        <Table.Cell>
          {performance ? (
            <span>
              Yes <PerformanceYesPopup />
            </span>
          ) : (
            "No"
          )}
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default OptionTableRow;
