import React, { FunctionComponent } from "react";
import { Icon, Popup, Table } from "semantic-ui-react";
import Content from "src/admin-portal/texts/content";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import { formatNumber, sumNumbers } from "src/common/utils/utils";
import CapOnGainHeaderCell from "src/employee-portal/instrument-page/common/cap-on-gain-header-cell";
import { DateFormatState } from "src/reducers/date-format-reducer";
import { hasExpired, removeExpiredAwards } from "../instruments-page";
import {
  AwardGainFunction,
  FlatAward,
  hasCapOnGain,
  hasOriginalQuantity,
  hasPerformanceCriteria,
  hasPrognosis,
  hasStrike,
  hasThreshold,
} from "../instruments-reducer";
import PSUTableRow, { calcStrikeValues } from "./psu-like-table-row";

interface Props {
  psus: FlatAward[];
  sharePriceToday: number;
  showExpired: boolean;
  totalGain: number;
  totalQuantity: number;
  isReleasedAtVesting: boolean;
  dateFormat: DateFormatState;
  performanceAdjustedGain: number;
  performanceAdjustedQuantity: number;
  overrideCurrency?: string;
  awardGain: AwardGainFunction;
}

const PSULikeTable: FunctionComponent<Props> = ({
  psus,
  sharePriceToday,
  showExpired,
  totalGain,
  totalQuantity,
  isReleasedAtVesting,
  dateFormat,
  performanceAdjustedQuantity,
  performanceAdjustedGain,
  overrideCurrency,
  awardGain,
}) => {
  const hasPerformance = psus.some(hasPerformanceCriteria);
  const showCapOnGain = psus.some(hasCapOnGain);
  const showThresholds = psus.some(hasThreshold);
  const showPrognosis = psus.some(hasPrognosis);
  const showStrike = psus.some(hasStrike);
  const strikeValues = showStrike ? psus.map(calcStrikeValues) : null;
  const showOriginalQuantity = psus.some(hasOriginalQuantity);
  const totalOriginalQuantity = psus
    .map(rsu => rsu.originalQuantity)
    .reduce(sumNumbers, 0);
  const shownPsus = showExpired ? psus : psus.filter(removeExpiredAwards);
  const showSharePrice = psus.some(a => !!a.share_price);

  return (
    <div className="sideways-scrollable block-l">
      <CurrencyFormatter>
        {(formatter: CurrencyFormatterProps) => (
          <Table
            celled={true}
            unstackable={true}
            textAlign="center"
            compact={"very"}
          >
            <Table.Header>
              <Table.Row>
                {/*<Table.HeaderCell>Program</Table.HeaderCell>*/}
                <Table.HeaderCell>
                  <Content id={"instruments.psu.table.criteria.header"} />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id={"instruments.psu.table.grantdate.header"} />
                </Table.HeaderCell>
                {showOriginalQuantity && (
                  <Table.HeaderCell>
                    <Content id={"instruments.originalquantity.label"} />
                    <Popup trigger={<Icon name="question circle outline" />}>
                      <Popup.Content>
                        <Content id={"instruments.originalquantity.popup"} />
                      </Popup.Content>
                    </Popup>
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Content id={"instruments.psu.table.maxquantity.header"} />
                </Table.HeaderCell>
                {showThresholds && (
                  <Table.HeaderCell>
                    <Content id={"instruments.psu.table.thresholds.header"} />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Content
                    id={"instruments.psu.table.performancestart.header"}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id={"instruments.psu.table.performanceend.header"} />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content
                    id={"instruments.psu.table.lastestimatedate.header"}
                  />
                </Table.HeaderCell>
                {showPrognosis && (
                  <Table.HeaderCell>
                    <Content
                      id={"instruments.psu.table.lastprognosis.header"}
                    />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Content
                    id={"instruments.psu.table.fulfillmentpercentage.header"}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content
                    id={"instruments.psu.table.estimatedquantity.header"}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id={"instruments.psu.table.vestingdate.header"} />
                </Table.HeaderCell>
                {showCapOnGain && <CapOnGainHeaderCell />}
                {/*<Table.HeaderCell>Expiry date</Table.HeaderCell>*/}
                {showStrike && (
                  <Table.HeaderCell>
                    <Content id={"instruments.psu.table.strike.header"} />
                  </Table.HeaderCell>
                )}
                {showStrike && (
                  <Table.HeaderCell>
                    <Content id={"instruments.psu.table.strikemax.header"} />
                  </Table.HeaderCell>
                )}
                {showStrike && (
                  <Table.HeaderCell>
                    <Content id={"instruments.psu.table.strikeperf.header"} />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Content id={"instruments.psu.table.maxgain.header"} />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content
                    id={"instruments.psu.table.performancegain.header"}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {shownPsus.map(award => (
                <PSUTableRow
                  award={award}
                  awardGain={awardGain}
                  stockPriceToday={sharePriceToday}
                  key={award.trancheId}
                  showPerformance={hasPerformance}
                  showSharePrice={showSharePrice}
                  isReleasedAtVesting={isReleasedAtVesting}
                  hasExpired={hasExpired(award)}
                  showOriginalQuantity={showOriginalQuantity}
                  formatter={formatter}
                  dateFormat={dateFormat}
                  showCapOnGain={showCapOnGain}
                  overrideCurrency={overrideCurrency}
                  showThresholds={showThresholds}
                  showPrognosis={showPrognosis}
                  showStrike={showStrike}
                />
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>Sum</Table.HeaderCell>
                <Table.HeaderCell />
                {showOriginalQuantity && (
                  <Table.HeaderCell textAlign="right" singleLine={true}>
                    {formatNumber(totalOriginalQuantity)}
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell textAlign="right" singleLine={true}>
                  {formatNumber(totalQuantity)}
                </Table.HeaderCell>
                {showThresholds && <Table.HeaderCell textAlign="right" />}
                <Table.HeaderCell textAlign="right" />
                <Table.HeaderCell textAlign="right" />
                <Table.HeaderCell textAlign="right" />
                {showPrognosis && <Table.HeaderCell textAlign="right" />}
                <Table.HeaderCell textAlign="right" />
                <Table.HeaderCell textAlign="right" singleLine={true}>
                  {formatNumber(performanceAdjustedQuantity)}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" />
                {showCapOnGain && <Table.HeaderCell textAlign="right" />}
                {showStrike && <Table.HeaderCell textAlign="right" />}
                {showStrike && (
                  <Table.HeaderCell textAlign="right">
                    <CurrencyConversionView
                      value={strikeValues
                        .map(sv => sv.totalMax)
                        .reduce(sumNumbers, 0)}
                      decimals={0}
                    />
                  </Table.HeaderCell>
                )}
                {showStrike && (
                  <Table.HeaderCell textAlign="right">
                    <CurrencyConversionView
                      value={strikeValues
                        .map(sv => sv.totalEstimated)
                        .reduce(sumNumbers, 0)}
                      decimals={0}
                    />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell textAlign="right" singleLine={true}>
                  <CurrencyConversionView value={totalGain} decimals={0} />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" singleLine={true}>
                  <CurrencyConversionView
                    value={performanceAdjustedGain}
                    decimals={0}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </CurrencyFormatter>
    </div>
  );
};

export default PSULikeTable;
