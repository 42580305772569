import moment, { Moment } from "moment";
import { Reducer } from "redux";

import {
  DELETE_REPORT,
  DELETE_REPORT_SUCCEEDED,
  FETCH_REPORTS,
  FETCH_REPORTS_ALL_AWARDS_SUCCEEDED,
  FETCH_REPORTS_FAILED,
  FETCH_REPORTS_SUCCEEDED,
  POST_REPORT,
  POST_REPORT_SUCCEEDED,
  UPDATE_REPORT,
  UPDATE_REPORT_SUCCEEDED,
} from "src/admin-portal/actions/reports-actions";
import { SELECT_TENANT } from "src/admin-portal/tenant/tenant-actions";

interface UploadedReport {
  id?: string;
  files?: object[];
  type?: "uploadedReports";
  downloadLink?: string;
}

export interface Report {
  id?: string;
  type?: "reports";
  name: string;
  fromDate: Moment;
  toDate: Moment;
  published: boolean;
  uploadedReports?: UploadedReport[];
  files?: FileList;
  tenant?: {
    id: string;
    type?: "tenants";
  };
}

export interface ReportsState {
  readonly allReports: Report[];
  readonly awards: Api.V1.Award[];
  readonly isFetching: boolean;
}

const mostRecentReportByToDate = (a, b) =>
  moment(b.toDate).unix() - moment(a.toDate).unix();

const sortReports = reports => reports.sort(mostRecentReportByToDate);

const initialState: ReportsState = {
  allReports: [],
  awards: [],
  isFetching: false,
};

const entityReducer: Reducer<ReportsState> = (state = initialState, action) => {
  if (
    [FETCH_REPORTS, POST_REPORT, DELETE_REPORT, UPDATE_REPORT].includes(
      action.type
    )
  ) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === FETCH_REPORTS_SUCCEEDED) {
    const allReports = sortReports(action.reports);
    return {
      ...state,
      ...{ allReports, isFetching: false },
    };
  }

  if (action.type === POST_REPORT_SUCCEEDED) {
    const allReports = sortReports([action.report, ...state.allReports]);
    return { ...state, allReports, isFetching: false };
  }

  if (action.type === UPDATE_REPORT_SUCCEEDED) {
    const { report } = action;
    const allReports = sortReports([
      report,
      ...state.allReports.filter(r => r.id != report.id),
    ]);
    return { ...state, allReports, isFetching: false };
  }

  if (action.type === DELETE_REPORT_SUCCEEDED) {
    const allReports = sortReports(
      state.allReports.filter(r => r.id !== action.reportId)
    );
    return { ...state, allReports, isFetching: false };
  }

  if (action.type === FETCH_REPORTS_ALL_AWARDS_SUCCEEDED) {
    return {
      ...state,
      awards: action.awards,
    };
  }

  if (action.type === SELECT_TENANT) {
    return initialState;
  }

  return state;
};

export default entityReducer;
