import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApi, NOT_AUTHORIZED } from "../common/api/api-helper";
import { RootState } from "../reducers/all-reducers";

import { userCurrencyCode } from "src/employee-portal/employee-portal-selectors";
import { setLocale } from "src/employee-portal/set-locale-saga";
import {
  putEmployeeProfileActionCreator,
  TYPES,
} from "./employee-portal-profile-actions";

const PROFILE_URL = "/profile";

function* putEmployeeProfile(
  action: ReturnType<typeof putEmployeeProfileActionCreator>
) {
  try {
    const beforeState: RootState = yield select();
    const token = beforeState.user.token;
    const method = "PUT";
    const previousLocale = beforeState.employeePortalProfile.locale;
    const previousCurrency = userCurrencyCode(beforeState);

    const putProfileResponse = yield call(() =>
      callApi(`${PROFILE_URL}/${action.user_id}`, token, method, {
        share_depository_account: action.share_depository_account || null, // So that "" -> null in the db
        share_depository_bank: action.share_depository_bank || null,
        share_depository_contact: action.share_depository_contact || null,
        share_depository_description:
          action.share_depository_description || null,
        locale: action.locale,
        currency_code: action.currency_code,
      })
    );

    if (previousLocale !== putProfileResponse.data.locale) {
      yield call(() => setLocale(putProfileResponse.data.locale));
    }

    if (previousCurrency !== putProfileResponse.data.currency_code) {
      // Force refresh to ensure new values are shown with the new currency
      window.location.reload();
    }

    yield put({
      type: TYPES.PUT_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED,
      employeeData: putProfileResponse.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: TYPES.USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
      yield put({
        type: TYPES.PUT_EMPLOYEE_PORTAL_PROFILE_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchPutEmployeeProfile() {
  yield takeEvery(TYPES.PUT_EMPLOYEE_PORTAL_PROFILE, putEmployeeProfile);
}
