import { push } from "connected-react-router";
import { Location } from "history";
import moment from "moment";
import React, { Component, StatelessComponent } from "react";
import { connect, MapStateToProps } from "react-redux";
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItemProps,
  Form,
  Header,
  Icon,
  Modal,
  Table,
} from "semantic-ui-react";
import { Employee } from "src/admin-portal/employees/employee-reducer";
import { FETCH_WINDOW } from "src/admin-portal/exercise-windows/window-actions";
import {
  Window,
  WindowType,
} from "src/admin-portal/exercise-windows/window-reducer";
import { purchaseWindows } from "src/admin-portal/exercise-windows/window-selectors";
import { FETCH_FILES } from "src/admin-portal/files/files-actions";
import { documentOptions } from "src/admin-portal/files/files-selectors";
import { PurchaseConfig } from "src/admin-portal/subprograms/subprogram-reducer";
import {
  changeCommaForPunctuation,
  changePunctuationForComma,
} from "src/common/utils/utils";
import { RootState } from "src/reducers/all-reducers";
import {
  CREATE_PURCHASE_CONFIG,
  UPDATE_PURCHASE_CONFIG,
} from "./purchase-actions";
import {
  CreatePurchaseConfigAction,
  UpdatePurchaseConfigAction,
} from "./purchase-saga";

export enum PurchaseType {
  PURCHASABLE_AWARD = "PURCHASABLE_AWARD",
  PURCHASE_OPPORTUNITY_CASH = "PURCHASE_OPPORTUNITY_CASH",
  PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY = "PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY",
}

interface Props {
  employees: Employee[];
  subProgramId: string;
  location: Location;
  purchaseConfig?: PurchaseConfig;
}

export interface PurchaseOpportunityInput {
  id?: string;
  selected: boolean;
  maxAmount: string;
  employee_id: string;
}

interface State {
  price: string;
  discount: string;
  purchaseOpportunities: PurchaseOpportunityInput[];
  allSelected: boolean;
  requireShareDepository: boolean;
  window_id: string;
  purchase_type: PurchaseType;
  document_id: null;
}

interface DispatchProps {
  createPurchaseConfig: (action: CreatePurchaseConfigAction) => void;
  updatePurchaseConfig: (action: UpdatePurchaseConfigAction) => void;
  closeModal: () => void;
  fetchDocuments: () => void;
  fetchWindows: () => void;
}

interface StateProps {
  documentOptions: DropdownItemProps[];
  isCreatingPurchaseConfig: boolean;
  purchaseWindows: Window[];
}

class PurchasePage extends Component<
  Props & StateProps & DispatchProps,
  State
> {
  public state = {
    price: "",
    discount: "0",
    window_id: null,
    purchase_type: PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY,
    requireShareDepository: false,
    purchaseOpportunities: [],
    allSelected: true,
    document_id: null,
  };

  constructor(props) {
    super(props);
    const { purchaseConfig } = this.props;
    this.state.purchaseOpportunities = this.props.employees.map(e => {
      const existingPurchaseOpportunity =
        purchaseConfig &&
        purchaseConfig.purchase_opportunities.find(
          po => po.employee_id === e.id
        );
      if (existingPurchaseOpportunity) {
        return {
          selected: true,
          id: existingPurchaseOpportunity.id,
          maxAmount: existingPurchaseOpportunity.maximumAmount || "0",
          maxCashAmount: existingPurchaseOpportunity.maximum_cash_amount || "0",
          employee_id: existingPurchaseOpportunity.employee_id,
          document_id: existingPurchaseOpportunity.document_id,
        };
      } else {
        return {
          selected: false,
          maxAmount: "0",
          maxCashAmount: "0",
          employee_id: e.id,
          document_id: null,
        };
      }
    });

    if (purchaseConfig) {
      this.state.price = purchaseConfig.price || "";
      this.state.window_id = purchaseConfig.window_id;
      this.state.purchase_type = purchaseConfig.purchase_type;
      this.state.discount = ((purchaseConfig.discount || 0) * 100).toString();
      this.state.allSelected = false;
      this.state.requireShareDepository =
        purchaseConfig.require_share_depository;
    }
  }

  public componentWillMount() {
    this.props.fetchDocuments();
    if (this.props.purchaseWindows.length === 0) {
      this.props.fetchWindows();
    }
  }

  public render() {
    const { price, requireShareDepository } = this.state;
    const editMode = this.props.location.pathname.endsWith("/edit");
    return (
      <Modal
        open={true}
        closeIcon={<Icon className="close icon" />}
        onClose={this.props.closeModal}
      >
        <Header content="Create purchase opportunity" textAlign={"center"} />
        <Modal.Content>
          <Form>
            <Form.Field width={10}>
              <label>In what window should purchasing be available?</label>
              <div className="relative">
                <Dropdown
                  placeholder="Select window or leave empty for now"
                  fluid={true}
                  selection={true}
                  options={windowOptions(this.props.purchaseWindows)}
                  value={this.state.window_id}
                  onChange={this.handleWindowSelection}
                />
              </div>
            </Form.Field>
            <Form.Field inline={true}>
              <Checkbox
                label="Ask for Share Depository Account"
                checked={requireShareDepository}
                name={"requireShareDepository"}
                onChange={this.handleToggleChange}
              />
            </Form.Field>
            <Form.Field width={10}>
              <label>Purchase type</label>
              <div className="relative">
                <Dropdown
                  placeholder="Select purchase type"
                  fluid={true}
                  selection={true}
                  options={purchaseTypeOptions()}
                  value={this.state.purchase_type}
                  onChange={this.handlePurchaseTypeSelection}
                />
              </div>
            </Form.Field>
            {(this.state.purchase_type ===
              PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY ||
              this.state.purchase_type === PurchaseType.PURCHASABLE_AWARD) && (
              <Form.Field>
                <Form.Input
                  placeholder={"Price"}
                  width={10}
                  name={"price"}
                  value={changePunctuationForComma(price)}
                  onChange={this.inputDecimalChange}
                  label="Purchase price"
                />
              </Form.Field>
            )}
            {this.state.purchase_type ===
              PurchaseType.PURCHASE_OPPORTUNITY_CASH && (
              <Form.Field>
                <Form.Input
                  placeholder={"E.g. 20 for 20%"}
                  width={10}
                  name={"discount"}
                  value={changePunctuationForComma(this.state.discount)}
                  onChange={this.inputDecimalChange}
                  label="Discount %"
                />
              </Form.Field>
            )}
            {this.state.purchase_type !== PurchaseType.PURCHASABLE_AWARD && (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Checkbox
                        onChange={this.toggleSelectAll}
                        checked={this.state.allSelected}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>Employee</Table.HeaderCell>
                    <Table.HeaderCell>
                      {this.state.purchase_type ===
                      PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY
                        ? "Maximum purchase quantity"
                        : "Maximum cash amount"}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Document</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.employees.map((employee, index) => (
                    <Table.Row key={employee.id}>
                      <Table.Cell>
                        {
                          <Checkbox
                            onChange={this.toggleEmployee.bind(this, index)}
                            checked={
                              this.state.allSelected ||
                              this.state.purchaseOpportunities[index].selected
                            }
                          />
                        }
                      </Table.Cell>
                      <Table.Cell>{`${employee.firstName} ${
                        employee.lastName
                      }`}</Table.Cell>
                      <Table.Cell>
                        {this.state.purchase_type ===
                        PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY ? (
                          <Form.Input
                            placeholder={"Amount"}
                            name={"maxAmount"}
                            value={
                              this.state.purchaseOpportunities[index].maxAmount
                            }
                            onChange={this.maxAmountInputChange.bind(
                              this,
                              index
                            )}
                          />
                        ) : (
                          <Form.Input
                            placeholder={"Cash amount"}
                            name={"maxCashAmount"}
                            value={
                              this.state.purchaseOpportunities[index]
                                .maxCashAmount
                            }
                            onChange={this.maxAmountInputChange.bind(
                              this,
                              index
                            )}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Form.Field>
                          <Dropdown
                            placeholder="Select terms document"
                            fluid={true}
                            search={true}
                            selection={true}
                            options={this.props.documentOptions}
                            value={
                              this.state.purchaseOpportunities[index]
                                .document_id
                            }
                            onChange={this.handleDocumentSelect.bind(
                              this,
                              index
                            )}
                          />
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={this.save}
            loading={this.props.isCreatingPurchaseConfig}
            inverted={true}
          >
            <Icon name="checkmark" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  private inputDecimalChange = (event, { name, value }) => {
    this.setState({ [name]: value } as Pick<State, keyof State>);
  };

  private toggleEmployee = index => {
    const employees = this.state.purchaseOpportunities.map(
      (e, i) =>
        i === index
          ? {
              ...this.state.purchaseOpportunities[i],
              selected: !this.state.purchaseOpportunities[i].selected,
            }
          : this.state.purchaseOpportunities[i]
    );
    this.setState({ purchaseOpportunities: employees });
  };

  private maxAmountInputChange = (index, event, { name, value }) => {
    const employees = this.state.purchaseOpportunities.map(
      (e, i) =>
        i === index
          ? { ...this.state.purchaseOpportunities[i], [name]: value }
          : this.state.purchaseOpportunities[i]
    );
    this.setState({ purchaseOpportunities: employees });
  };

  private toggleSelectAll = () =>
    this.setState({ allSelected: !this.state.allSelected });

  private handleToggleChange = (event, { name }) => {
    this.setState({ [name]: !this.state[name] } as Pick<State, keyof State>);
  };

  private save = () => {
    const editMode = this.props.location.pathname.endsWith("/edit");
    const data = {
      sub_program_id: this.props.subProgramId,
      purchase_type: this.state.purchase_type,
      price: parseFloat(changeCommaForPunctuation(this.state.price)),
      discount:
        parseFloat(changeCommaForPunctuation(this.state.discount)) / 100,
      requireShareDepository: this.state.requireShareDepository,
      window_id:
        this.state.window_id === "no-exercise-windows"
          ? null
          : this.state.window_id,
      individual_purchase_config: this.state.purchaseOpportunities.map(
        ({
          selected,
          maxAmount,
          maxCashAmount,
          employee_id,
          document_id,
          id,
        }) => ({
          id: id || null,
          selected: selected || this.state.allSelected,
          maximumAmount: parseInt(maxAmount) || null,
          maximum_cash_amount: parseInt(maxCashAmount) || null,
          employee_id,
          document_id,
        })
      ),
    };

    if (editMode) {
      this.props.updatePurchaseConfig({
        type: UPDATE_PURCHASE_CONFIG,
        id: this.props.purchaseConfig.id,
        ...data,
      });
    } else {
      this.props.createPurchaseConfig({
        type: CREATE_PURCHASE_CONFIG,
        ...data,
      });
    }
  };

  private handleDocumentSelect = (index, event, { value }) => {
    const employees = this.state.purchaseOpportunities.map(
      (e, i) =>
        i === index
          ? {
              ...this.state.purchaseOpportunities[i],
              document_id: value,
            }
          : this.state.purchaseOpportunities[i]
    );
    this.setState({ purchaseOpportunities: employees });
  };

  private handleWindowSelection = (event, { value }) =>
    this.setState({ window_id: value });

  private handlePurchaseTypeSelection = (event, { value }) =>
    this.setState({ purchase_type: value });
}

const purchaseTypeOptions = (): DropdownItemProps[] => [
  {
    key: "0",
    value: PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY,
    text: "Instrument Quantity (ala Tobii)",
  },
  {
    key: "1",
    value: PurchaseType.PURCHASABLE_AWARD,
    text: "Award (ala Veidekke)",
  },
  {
    key: "2",
    value: PurchaseType.PURCHASE_OPPORTUNITY_CASH,
    text: "Cash Quantity (ala Infront)",
  },
];

export const windowOptions = (windows: Window[]): DropdownItemProps[] => [
  {
    key: "no-exercise-windows",
    value: "no-exercise-windows",
    text: "No exercise-windows",
  },
  ...windows.map(window => ({
    key: window.id,
    value: window.id,
    text: `${window.start_time.format("lll")} - ${window.end_time.format(
      "lll"
    )}`,
  })),
];

const mapStateToProps: MapStateToProps<StateProps, Props, RootState> = (
  state: RootState
): StateProps => {
  return {
    documentOptions: documentOptions(state),
    isCreatingPurchaseConfig: state.program.isCreatingPurchaseConfig,
    purchaseWindows: purchaseWindows(state),
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => ({
  createPurchaseConfig: (action: CreatePurchaseConfigAction) =>
    dispatch(action),
  updatePurchaseConfig: (action: UpdatePurchaseConfigAction) =>
    dispatch(action),
  closeModal: () => dispatch(push("/admin/awards")),
  fetchDocuments: () => dispatch({ type: FETCH_FILES }),
  fetchWindows: () => dispatch({ type: FETCH_WINDOW }),
});

export default connect<StateProps, DispatchProps, Props>(
  mapStateToProps,
  mapDispatchToProps
)(PurchasePage);
