import { Reducer } from "redux";
import { SELECT_TENANT } from "src/admin-portal/tenant/tenant-actions";
import { IStringStringMap } from "./employee-portal-texts-reducer";
import {
  FETCH_TEXTS,
  FETCH_TEXTS_SUCCEEDED,
  PUT_TEXTS,
  PUT_TEXTS_SUCCEEDED,
  UPDATE_DEFAULT_TEXTS,
  UPDATE_DEFAULT_TEXTS_SUCCEEDED,
} from "./text-actions";

export interface Text {
  [key: string]: string;
}

export interface TextObject {
  [key: string]: {
    value: string;
    defaultValue: string;
    overridden: boolean;
  };
}

export type SupportedLocale = "en" | "no" | "sv" | "da";

export type LocaledTexts = { [key in SupportedLocale]: IStringStringMap };

export interface TextState {
  allTexts?: Api.V1.Text[];
  defaultTexts?: IStringStringMap;
  defaultTextsLocaled?: LocaledTexts;
  locales: SupportedLocale[];
  isFetching: boolean;
  isUpdatingDefaultTexts: boolean;
}

const initialState: TextState = {
  allTexts: null,
  isFetching: false,
  isUpdatingDefaultTexts: false,
  locales: ["en", "no", "sv", "da"],
};

function createTextObject(defaultTexts, tenantSpecificTexts) {
  const texts: TextObject = {};

  Object.keys(defaultTexts).forEach(key => {
    texts[key] = {
      defaultValue: defaultTexts[key],
      value:
        (tenantSpecificTexts && tenantSpecificTexts[key]) || defaultTexts[key],
      overridden: tenantSpecificTexts && !!tenantSpecificTexts[key],
    };
  });

  Object.keys(tenantSpecificTexts || {}).forEach(key => {
    if (!texts[key]) {
      texts[key] = {
        defaultValue: undefined,
        value: tenantSpecificTexts[key],
        overridden: true,
      };
    }
  });

  return texts;
}

const textReducer: Reducer<TextState> = (state = initialState, action) => {
  if (action.type === FETCH_TEXTS) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === FETCH_TEXTS_SUCCEEDED) {
    return {
      ...state,
      allTexts: action.allTexts,
      defaultTexts: action.defaultTexts,
      defaultTextsLocaled: action.defaultTextsLocaled,
      isFetching: false,
    };
  }

  if (action.type === "POST_TEXT") {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === "POST_TEXT_SUCCEEDED") {
    const texts = state.allTexts;
    texts[action.key] = action.value;
    return { ...state, allTexts: { ...state.allTexts }, isFetching: false };
  }

  if (action.type === PUT_TEXTS) {
    return { ...state, isFetching: true };
  }

  if (action.type === PUT_TEXTS_SUCCEEDED) {
    const updatedTestData = state.allTexts.map(
      textObj =>
        textObj.locale === action.texts.locale ? action.texts : textObj
    );
    return { ...state, isFetching: false, allTexts: updatedTestData };
  }

  if (action.type === "DELETE_TEXT_SUCCEEDED") {
    const texts = { ...state.allTexts, [action.key]: undefined };
    return { ...state, allTexts: texts, isFetching: false };
  }

  if (action.type === UPDATE_DEFAULT_TEXTS) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === UPDATE_DEFAULT_TEXTS_SUCCEEDED) {
    const texts = createTextObject(action.texts, state.allTexts);
    return {
      ...state,
      texts,
      defaultTexts: action.texts,
      defaultTextsLocaled: {
        ...state.defaultTextsLocaled,
        [action.locale]: action.texts,
      },
      isFetching: false,
    };
  }

  if (action.type === SELECT_TENANT) {
    return initialState;
  }

  return state;
};

export default textReducer;
