import Jsona from "jsona";
import Raven from "raven-js";
import {
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import {
  UPLOAD_FILES,
  UPLOAD_FILES_FAILED,
  UPLOAD_FILES_SUCCEEDED,
} from "src/admin-portal/files/files-actions";
import { uploadFiles } from "src/admin-portal/files/files-saga";
import { USER_NOT_AUTHORIZED } from "src/reducers/user";
import { callApi, NOT_AUTHORIZED } from "../../common/api/api-helper";
import { DELETE_ALL_PROGRAMS_FAILED } from "../programs/program-actions";
import {
  ADD_TEMPLATE_TO_SUBPROGRAM_FAILED,
  ADD_VESTING,
  ADD_VESTING_FAILED,
  ADD_VESTING_SUCCEEDED,
  DELETE_SUBPROGRAM,
  DELETE_SUBPROGRAM_SUCCEEDED,
  FETCH_SUBPROGRAM_FAILED,
  FETCH_SUBPROGRAMS,
  FETCH_SUBPROGRAMS_SUCCEEDED,
  GENERATE_SUBPROGRAM_AGREEMENTS,
  GENERATE_SUBPROGRAM_AGREEMENTS_SUCCEEDED,
  POST_SUBPROGRAM,
  POST_SUBPROGRAM_FAILED,
  POST_SUBPROGRAM_SUCCEEDED,
  PUT_SUBPROGRAM,
  PUT_SUBPROGRAM_FAILED,
  PUT_SUBPROGRAM_SUCCEEDED,
  UPLOAD_SUBPROGRAM_TEMAPLATE,
  UPLOAD_SUBPROGRAM_TEMAPLATE_SUCCEEDED,
} from "./subprogram-actions";
import * as selectors from "./subprogram-selectors";

const dataFormatter = new Jsona();
const SUBPROGRAM_REQUEST_URL = "/incentive_programs/";

export function* postSubProgramRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const programId = action.programId;
    const method = "POST";

    const subProgramResponse = yield call(() =>
      callApi(
        SUBPROGRAM_REQUEST_URL +
          programId +
          "/sub_programs?tenantId=" +
          tenantId,
        token,
        method,
        action.subProgram
      )
    );
    yield put({
      type: POST_SUBPROGRAM_SUCCEEDED,
      subProgram: subProgramResponse.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: POST_SUBPROGRAM_FAILED, message: e.message });
    }
  }
}

export function* watchPostSubProgram() {
  yield takeEvery(POST_SUBPROGRAM, postSubProgramRequested);
}

export function* fetchSubProgramRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const programId = action.programId;

    const subProgramResponse = yield call(() =>
      callApi(
        SUBPROGRAM_REQUEST_URL +
          programId +
          "/sub_programs?tenantId=" +
          tenantId,
        token
      )
    );
    yield put({
      type: FETCH_SUBPROGRAMS_SUCCEEDED,
      subPrograms: subProgramResponse.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_SUBPROGRAM_FAILED, message: e.message });
    }
  }
}

export function* watchFetchSubProgram() {
  yield takeEvery(FETCH_SUBPROGRAMS, fetchSubProgramRequested);
}

export function* addVestingRequested(action) {
  try {
    yield put({ type: ADD_VESTING_SUCCEEDED, vesting: action.vesting });
  } catch (e) {
    Raven.captureException(e);
    yield put({ type: ADD_VESTING_FAILED, message: e.message });
  }
}

export function* watchAddVesting() {
  yield takeEvery(ADD_VESTING, addVestingRequested);
}

function* putSubProgramRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const programId = action.programId;
    const subProgramId = action.subProgramId;
    const method = "PUT";

    const subProgramResponse = yield call(
      callApi,
      SUBPROGRAM_REQUEST_URL +
        programId +
        "/sub_programs/" +
        subProgramId +
        "?tenantId=" +
        tenantId,
      token,
      method,
      action.subProgram
    );
    yield put({
      type: PUT_SUBPROGRAM_SUCCEEDED,
      subProgram: subProgramResponse.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: PUT_SUBPROGRAM_FAILED, message: e.message });
    }
  }
}

export function* watchPutSubProgram() {
  yield takeEvery(PUT_SUBPROGRAM, putSubProgramRequested);
}

function* deleteSubProgramRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const programId = action.programId;
    const subProgramId = action.subProgramId;
    const method = "DELETE";

    yield call(() =>
      callApi(
        SUBPROGRAM_REQUEST_URL +
          programId +
          "/sub_programs/" +
          subProgramId +
          "?tenantId=" +
          tenantId,
        token,
        method
      )
    );
    yield put({
      type: DELETE_SUBPROGRAM_SUCCEEDED,
      programId,
      subProgramId,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_ALL_PROGRAMS_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteSubProgram() {
  yield takeEvery(DELETE_SUBPROGRAM, deleteSubProgramRequested);
}

function* GenerateSubProgramAgreementsRequested(action) {
  try {
    const token = yield select(selectors.token);
    const subProgramId = action.subProgramId;
    const method = "POST";

    yield call(() =>
      callApi(
        `/sub_programs/${subProgramId}/generate-agreements`,
        token,
        method
      )
    );
    yield put({
      subProgramId,
      type: GENERATE_SUBPROGRAM_AGREEMENTS_SUCCEEDED,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchGenerateSubProgramAgreements() {
  yield takeEvery(
    GENERATE_SUBPROGRAM_AGREEMENTS,
    GenerateSubProgramAgreementsRequested
  );
}

const connectDocumentToSubprogram = (token, subProgramId, documentId) => {
  const method = "POST";
  const body = dataFormatter.serialize({
    stuff: {
      type: "documentsDocumentables",
      documentableId: subProgramId,
      documentableType: "IncentiveSubProgram",
    },
  });
  return callApi(
    `/documents/${documentId}/documents-documentables`,
    token,
    method,
    body
  );
};

/*
  Since this is kinda advanced, here's the intended flow:
    1. Dispatch "UPLOAD_FILES" action so the uploadFiles saga runs
    2. Wait for uploadFiles to either dispatch failure or success actions
    3. If success, do the request to add the uploaded document to the Sub Program
 */
export function* addTemplateToSubprogram(action) {
  yield put({ type: UPLOAD_FILES, locale: action.locale });
  const { response, error } = yield race({
    response: take(UPLOAD_FILES_SUCCEEDED),
    error: take(UPLOAD_FILES_FAILED),
  });

  if (error) {
    yield put({ type: ADD_TEMPLATE_TO_SUBPROGRAM_FAILED, error });
  }

  const documentId = response.document.id;
  const token = yield select(selectors.token);
  try {
    const result = yield call(() =>
      connectDocumentToSubprogram(token, action.subprogramId, documentId)
    );
    yield put({ type: UPLOAD_SUBPROGRAM_TEMAPLATE_SUCCEEDED, result });
  } catch (e) {
    yield put({ type: ADD_TEMPLATE_TO_SUBPROGRAM_FAILED, e });
  }
}

export function* watchAddTemplateToSubprogram() {
  yield takeEvery(UPLOAD_SUBPROGRAM_TEMAPLATE, addTemplateToSubprogram);
}
