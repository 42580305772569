import moment from "moment";
import "moment/locale/nb.js";
import numeral from "numeral";
import "numeral/locales/no.js";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import App from "./app";
import "./common/less/flexbox-utils.less";
import "./common/less/support-widget.less";
import "./index.less";

numeral.locale("no");
moment.locale("nb");

export const CONTACT_EMAIL = "support@optioincentives.no";
// export const CONTACT_PHONE = "+47 22 34 33 32";

ReactDOM.render(<App />, document.getElementById("root"));
