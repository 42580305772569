import { push } from "connected-react-router";
import Jsona from "jsona";
import Raven from "raven-js";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { employeesRoute } from "src/admin-portal/menu/admin-portal-menu";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { batchRequests } from "src/common/sagas/batch-requests-saga";
import { AUTH0_BATCH_SIZE, AUTH0_DELAY_IN_MILLISECONDS } from "src/env";
import { tenantId as tID, userTenantId } from "src/selectors";
import { fetchEntities } from "../entity/entity-saga";
import {
  DELETE_ALL_EMPLOYEES,
  DELETE_ALL_EMPLOYEES_FAILED,
  DELETE_ALL_EMPLOYEES_SUCCEEDED,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_FAILED,
  DELETE_EMPLOYEE_SUCCEEDED,
  FETCH_EMPLOYEE,
  FETCH_EMPLOYEE_FAILED,
  FETCH_EMPLOYEE_SUCCEEDED,
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEES_FAILED,
  FETCH_EMPLOYEES_SUCCEEDED,
  IMPORT_ALL_EMPLOYEES,
  IMPORT_ALL_EMPLOYEES_FAILED,
  IMPORT_ALL_EMPLOYEES_SUCCEEDED,
  POST_EMPLOYEE,
  POST_EMPLOYEE_FAILED,
  POST_EMPLOYEE_SUCCEEDED,
  PUT_EMPLOYEE,
  PUT_EMPLOYEE_FAILED,
  PUT_EMPLOYEE_SUCCEEDED,
  REVERSE_TERMINATE_EMPLOYEE,
  REVERSE_TERMINATE_EMPLOYEE_FAILED,
  REVERSE_TERMINATE_EMPLOYEE_SUCCEEDED,
  TERMINATE_EMPLOYEE,
  TERMINATE_EMPLOYEE_CUSTOM,
  TERMINATE_EMPLOYEE_FAILED,
  TERMINATE_EMPLOYEE_SUCCEEDED,
} from "./employee-actions";
import * as selectors from "./employee-selectors";

const tenantEmployeesUrl = tenantId =>
  `/tenants/${tenantId}/employees?include=entity,currentMobilityEntry,employeeCustomRelationships,employeeCustomRelationships.customRelationshipType`;

const employeeUrlIncludesParam = [
  "entity",
  "mobilityEntries",
  "employeeCustomRelationships",
  "employeeCustomRelationships.customRelationshipType",
  "currentMobilityEntry",
  "tranches",
  "awards.tranches.award.incentiveSubProgram.incentiveProgram",
].join(",");

const employeeUrl = id =>
  `/employees/${id}?include=${employeeUrlIncludesParam}`;

const EMPLOYEES_OPTION_REQUEST_URL = "/employees/";
const terminateEmployeeUrl = (employeeId: string) =>
  `/employees/${employeeId}/termination`;
const reverseTerminateEmployeeUrl = (employeeId: string) =>
  `/employees/${employeeId}/reverse_termination`;
const dataFormatter = new Jsona();

export function* fetchEmployees() {
  const token = yield select(selectors.token);
  const tenantId = (yield select(tID)) || (yield select(userTenantId));

  const response = yield call(() =>
    callApi(tenantEmployeesUrl(tenantId), token)
  );

  yield put({
    employees: dataFormatter.deserialize(response),
    type: FETCH_EMPLOYEES_SUCCEEDED,
  });
}

function* fetchAllEntitiesAndEmployees() {
  yield fork(fetchEntities);
  yield fork(fetchEmployees);
}

function* fetchEntitiesAndEmployees() {
  try {
    yield call(fetchAllEntitiesAndEmployees);
    yield put({ type: "FETCH_EMPLOYEES_AND_ENTITIES_SUCCEEDED" });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        message: e.message,
        type: "FETCH_EMPLOYEES_AND_ENTITIES_FAILED",
      });
    }
  }
}

export function* watchFetchEntitiesAndEmployees() {
  yield takeEvery("FETCH_EMPLOYEES_AND_ENTITIES", fetchEntitiesAndEmployees);
}

function* fetchEmployeesRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const employeeResponse = yield call(() =>
      callApi(tenantEmployeesUrl(tenantId), token)
    );
    yield put({
      employees: dataFormatter.deserialize(employeeResponse),
      type: FETCH_EMPLOYEES_SUCCEEDED,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_EMPLOYEES_FAILED, message: e.message });
    }
  }
}

export function* watchFetchEmployees() {
  yield takeEvery(FETCH_EMPLOYEES, fetchEmployeesRequested);
}

function* fetchEmployeeRequested(action) {
  try {
    const token = yield select(selectors.token);
    const method = "GET";

    const response = yield call(() =>
      callApi(employeeUrl(action.id), token, method)
    );

    yield put({
      employee: dataFormatter.deserialize(response),
      type: FETCH_EMPLOYEE_SUCCEEDED,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_EMPLOYEE_FAILED, message: e.message });
    }
  }
}

export function* watchFetchEmployee() {
  yield takeEvery(FETCH_EMPLOYEE, fetchEmployeeRequested);
}

const createEmployeeAttributes = employee => {
  const { mobilityEntries, ...rest } = employee;
  return { ...rest, newMobilityEntries: mobilityEntries };
};

function* postEmployeesRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const body = {
      data: {
        attributes: createEmployeeAttributes(action.employee),
        type: "employees",
      },
    };

    const response = yield call(() =>
      callApi(tenantEmployeesUrl(tenantId), token, method, body)
    );

    yield put({
      employee: dataFormatter.deserialize(response),
      type: POST_EMPLOYEE_SUCCEEDED,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: POST_EMPLOYEE_FAILED, message: e.message });
    }
  }
}

export function* watchPostEmployee() {
  yield takeEvery(POST_EMPLOYEE, postEmployeesRequested);
}

interface TerminateEmployeeAction {
  type: "TERMINATE_EMPLOYEE";
  employeeId: string;
  terminationDate: string;
  transactionDate: string;
  reverseCost: boolean;
}

function* terminateEmployee(action: TerminateEmployeeAction) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const body = {
      termination_date: action.terminationDate,
      transaction_date: action.transactionDate,
      termination_type: "TERMINATE_ALL_NOT_FULLY_VESTED",
      reverseCost: !!action.reverseCost,
    };

    yield call(() =>
      callApi(terminateEmployeeUrl(action.employeeId), token, method, body)
    );
    yield put({
      type: TERMINATE_EMPLOYEE_SUCCEEDED,
      employeeId: action.employeeId,
      terminationDate: action.terminationDate,
    });
    yield put(push(employeesRoute));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: TERMINATE_EMPLOYEE_FAILED, message: e.message });
    }
  }
}

export function* watchTerminateEmployee() {
  yield takeEvery(TERMINATE_EMPLOYEE, terminateEmployee);
}

function* terminateEmployeeCustom(action) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";
    yield call(() =>
      callApi(
        terminateEmployeeUrl(action.employeeId),
        token,
        method,
        action.payload
      )
    );
    yield all([put({ type: FETCH_EMPLOYEES }), put(push(employeesRoute))]);
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchTerminateEmployeeCustom() {
  yield takeEvery(TERMINATE_EMPLOYEE_CUSTOM, terminateEmployeeCustom);
}

function* reverseTerminateEmployee(action) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";

    yield call(() =>
      callApi(reverseTerminateEmployeeUrl(action.employeeId), token, method)
    );
    yield put({
      type: REVERSE_TERMINATE_EMPLOYEE_SUCCEEDED,
      employeeId: action.employeeId,
    });
    yield put(push(employeesRoute));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: REVERSE_TERMINATE_EMPLOYEE_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchReverseTerminateEmployee() {
  yield takeEvery(REVERSE_TERMINATE_EMPLOYEE, reverseTerminateEmployee);
}

function* deleteEmployeeRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const employeeId = action.employeeId;
    const method = "DELETE";

    yield call(() =>
      callApi(
        EMPLOYEES_OPTION_REQUEST_URL + employeeId + "?tenantId=" + tenantId,
        token,
        method
      )
    );
    yield put({ type: DELETE_EMPLOYEE_SUCCEEDED, employeeId });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_EMPLOYEE_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteEmployee() {
  yield takeEvery(DELETE_EMPLOYEE, deleteEmployeeRequested);
}

function* putEmployeeRequested(action) {
  try {
    const token = yield select(selectors.token);
    const employeeId = action.employeeId;
    const method = "PUT";
    const { mobilityEntries, ...rest } = action.employee;

    const body = dataFormatter.serialize({
      stuff: {
        ...rest,
        id: employeeId,
        newMobilityEntries: mobilityEntries,
        type: "employees",
      },
    });

    const response = yield call(() =>
      callApi(employeeUrl(employeeId), token, method, body)
    );

    yield put({
      employee: dataFormatter.deserialize(response),
      type: PUT_EMPLOYEE_SUCCEEDED,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: PUT_EMPLOYEE_FAILED, message: e.message });
    }
  }
}

export function* watchPutEmployee() {
  yield takeEvery(PUT_EMPLOYEE, putEmployeeRequested);
}

interface ImportAllEmployeesAction {
  type: "IMPORT_ALL_EMPLOYEES";
  employees: Api.V1.Employee[];
}

function* createEmployeesRequested(action: ImportAllEmployeesAction) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const promiseCreators = action.employees.map(employee => () =>
      callApi(tenantEmployeesUrl(tenantId), token, method, {
        data: {
          attributes: createEmployeeAttributes(employee),
          type: "employees",
        },
      })
    );
    const response = yield batchRequests(
      AUTH0_BATCH_SIZE,
      AUTH0_DELAY_IN_MILLISECONDS,
      promiseCreators
    );
    yield put({
      employees: response.map(r => dataFormatter.deserialize(r)),
      type: IMPORT_ALL_EMPLOYEES_SUCCEEDED,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: IMPORT_ALL_EMPLOYEES_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchCreateEmployees() {
  yield takeEvery(IMPORT_ALL_EMPLOYEES, createEmployeesRequested);
}

interface DeleteAllEmployeesAction {
  type: "DELETE_EMPLOYEES";
  employees: Api.V1.Employee[];
}

function* deleteAllEmployeesRequested(action: DeleteAllEmployeesAction) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "DELETE";
    const promiseCreators = action.employees.map(employee => () =>
      callApi(
        EMPLOYEES_OPTION_REQUEST_URL + employee.id + "?tenantId=" + tenantId,
        token,
        method
      )
    );

    const employees = yield batchRequests(
      AUTH0_BATCH_SIZE,
      AUTH0_DELAY_IN_MILLISECONDS,
      promiseCreators
    );
    yield put({ type: DELETE_ALL_EMPLOYEES_SUCCEEDED, employees });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: DELETE_ALL_EMPLOYEES_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchDeleteAllEmployees() {
  yield takeEvery(DELETE_ALL_EMPLOYEES, deleteAllEmployeesRequested);
}
