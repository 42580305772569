import React, { StatelessComponent } from "react";
import {
  Button,
  DropdownItemProps,
  Flag,
  FlagProps,
  Table,
} from "semantic-ui-react";
import { countryOptions } from "../../common/data/common";
import { Entity } from "../entity/entity-reducer";
import { EmployeeSheetImport, entityNameForId } from "./employee-import";

interface Props {
  employees: EmployeeSheetImport[];
  entities: Entity[];
}

const EmployeesPreview: StatelessComponent<Props> = ({
  employees,
  entities,
}) => {
  return (
    <div className="block-m sideways-scrollable">
      <Table celled={true} padded={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Entity</Table.HeaderCell>
            <Table.HeaderCell>Country</Table.HeaderCell>
            <Table.HeaderCell>Insider</Table.HeaderCell>
            <Table.HeaderCell>SocSec</Table.HeaderCell>
            <Table.HeaderCell>Internal Id</Table.HeaderCell>

            <Table.HeaderCell>shareDepositoryAccount</Table.HeaderCell>
            <Table.HeaderCell>shareDepositoryBank</Table.HeaderCell>
            <Table.HeaderCell>shareDepositoryContact</Table.HeaderCell>
            <Table.HeaderCell>shareDepositoryDescription</Table.HeaderCell>
            <Table.HeaderCell>Language</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {employees.map(employee => {
            const country = countryOptions.filter(
              option => option.text === employee.residence
            )[0];
            const flagProps: FlagProps = {
              name: country.flag,
            } as FlagProps;

            return (
              <Table.Row key={`${employee.firstName} ${employee.lastName}`}>
                <Table.Cell>{`${employee.firstName} ${
                  employee.lastName
                }`}</Table.Cell>
                <Table.Cell>{employee.email}</Table.Cell>
                <Table.Cell>
                  {entityNameForId(entities, employee.entityName)}
                </Table.Cell>
                <Table.Cell>
                  <Flag {...flagProps} />
                  {country.text}
                </Table.Cell>
                <Table.Cell>{employee.insider}</Table.Cell>
                <Table.Cell>{employee.socSec}</Table.Cell>
                <Table.Cell>{employee.internalIdentification}</Table.Cell>

                <Table.Cell>{employee.shareDepositoryAccount}</Table.Cell>
                <Table.Cell>{employee.shareDepositoryBank}</Table.Cell>
                <Table.Cell>{employee.shareDepositoryContact}</Table.Cell>
                <Table.Cell>{employee.shareDepositoryDescription}</Table.Cell>
                <Table.Cell>{employee.locale}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default EmployeesPreview;
