import moment, { Moment } from "moment";
import { Reducer } from "redux";
import { Employee } from "src/admin-portal/employees/employee-reducer";
import { SELECT_TENANT } from "src/admin-portal/tenant/tenant-actions";
import { OrderExerciseType } from "src/employee-portal/exercise/common/types";
import {
  mostRecentFirst,
  OrderStatus,
} from "src/employee-portal/exercise/order/order-reducer";
import { OrderType } from "src/employee-portal/purchase/duck/purchase-saga";
import {
  DELETE_ORDER,
  DELETE_ORDER_SUCCEEDED,
  FETCH_TENANT_ORDERS,
  FETCH_TENANT_ORDERS_SUCCEEDED,
  PUT_TENANT_ORDERS,
  PUT_TENANT_ORDERS_SUCCEEDED,
} from "./orders-actions";

export interface Order {
  id?: string;
  status: string;
  exerciseType: string;
  vps_account: number;
  employee: Employee;
  exercise_order_lines: ExerciseOrder[];
}

export interface APIOrder {
  order_type: OrderType;
  status: OrderStatus;
  employee: Employee & { first_name: string; last_name: string };
  employee_id: string;
  id: string;
  created_at: Moment;
  exercise_order?: APIExerciseOrder;
  purchase_order?: APIPurchaseOrder;
  window_id?: string;
}

export interface APIPurchaseOrder {
  purchase_amount: number;
  purchase_cash_amount?: number;
  share_depository_account?: string;
  share_depository_bank?: string;
  purchase_opportunity: {
    purchase_config: {
      price: string;
    };
  };
}

export interface APIExerciseOrder {
  exerciseType: OrderExerciseType;
  status: OrderStatus;
  id: string;
  vps_account?: string;
  bank_account?: string;
  share_depository_bank?: string;
  share_depository_clearing_code?: string;
  share_depository_contact?: string;
  share_depository_description?: string;
  exercise_order_lines?: APIExerciseOrderLine[];
}

interface APIExerciseOrderLine {
  exercise_quantity: number;
  tranche_id: string;
  id: string;
}

export interface UpdateOrder {
  status: string;
}

export interface ExerciseOrder {
  id?: string;
  tranche_id: string;
  exercise_quantity: number;
}

export interface OrderState {
  allOrders: APIOrder[];
  isFetching: boolean;
}

const initialState: OrderState = {
  allOrders: [],
  isFetching: false,
};

const orderReducer: Reducer<OrderState> = (state = initialState, action) => {
  if (
    [FETCH_TENANT_ORDERS, PUT_TENANT_ORDERS, DELETE_ORDER].includes(action.type)
  ) {
    return { ...state, isFetching: true };
  }

  if (action.type === FETCH_TENANT_ORDERS_SUCCEEDED) {
    const orders = action.orders.sort(mostRecentFirst);
    return { ...state, ...{ allOrders: orders }, isFetching: false };
  }

  if (action.type === PUT_TENANT_ORDERS_SUCCEEDED) {
    const orderIndex = state.allOrders.findIndex(
      order => order.id === action.order.id
    );
    const order = { ...state.allOrders[orderIndex], ...action.order };
    state.allOrders = [...state.allOrders];
    state.allOrders[orderIndex] = order;
    return { ...state, allOrders: [...state.allOrders], isFetching: false };
  }

  if (action.type === DELETE_ORDER_SUCCEEDED) {
    const allOrders = state.allOrders.filter(r => r.id !== action.orderId);
    return { ...state, allOrders, isFetching: false };
  }

  if (action.type === SELECT_TENANT) {
    return initialState;
  }

  return state;
};

export default orderReducer;
