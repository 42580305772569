import auth0 from "auth0-js";
import React, { Component } from "react";
import { connect, MapDispatchToProps } from "react-redux";
import {
  AUTH0_CLIENT_ID,
  AUTH0_CONFIGURATION_BASE_URL,
  AUTH0_DOMAIN,
  AUTH0_PASSWORDLESS_METHOD,
  AUTH_REDIRECT_URL,
} from "src/env";

const webAuth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  scope: "openid profile",
  redirectUri: AUTH_REDIRECT_URL,
  responseType: "token id_token",
  audience: "https://api-p.optioincentives.com",
  overrides: {
    __tenant: "optioincentives-p",
    __token_issuer: AUTH0_DOMAIN,
  },
});

interface DispatchProps {
  loginRequested: () => void;
}

class LoginPage extends Component<DispatchProps> {
  public componentDidMount() {
    // this.props.loginRequested();
    webAuth.authorize({
      connection: "optiopartners-waad",
    });
  }

  public render() {
    return null;
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = (
  dispatch
): DispatchProps => ({
  loginRequested: () => dispatch({ type: "LOGIN_REQUESTED" }),
});

export default connect(
  null,
  mapDispatchToProps
)(LoginPage);
