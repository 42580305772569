export const token = state => state.user.token;
export const isSysadmin = state => state.user.isSysadmin;
export const isAdminUserWithoutInstruments = (user, instrument) =>
  user.isAdmin && !instrument.totalQuantity;

export const tenantId = state =>
  state.tenant.selectedTenant ? state.tenant.selectedTenant.id : null;

export const userTenantId = state =>
  state.user.tenant ? state.user.tenant.id : null;

export const selectedTenant = state =>
  (state.tenant.selectedTenant && state.tenant.selectedTenant.name) ||
  state.user.tenant.name;

export const selectedTenantCurrency = state =>
  (state.tenant.selectedTenant && state.tenant.selectedTenant.currency_code) ||
  state.user.tenant.currency_code;
