import { Reducer } from "redux";
import { Award } from "src/admin-portal/awards/award-reducer";
import { PurchaseType } from "src/admin-portal/awards/purchase/purchase-page";
import { Program } from "src/admin-portal/programs/program-reducer";
import {
  DELETE_SUBPROGRAM,
  DELETE_SUBPROGRAM_SUCCEEDED,
  FETCH_SUBPROGRAMS_SUCCEEDED,
  GENERATE_SUBPROGRAM_AGREEMENTS,
  GENERATE_SUBPROGRAM_AGREEMENTS_SUCCEEDED,
  POST_SUBPROGRAM,
  POST_SUBPROGRAM_SUCCEEDED,
  PUT_SUBPROGRAM,
  PUT_SUBPROGRAM_SUCCEEDED,
  RESET_UPLOAD_SUBPROGRAM_TEMAPLATE_STATE,
  UPLOAD_SUBPROGRAM_TEMAPLATE,
  UPLOAD_SUBPROGRAM_TEMAPLATE_SUCCEEDED,
} from "./subprogram-actions";

export interface SubProgram {
  id?: string;
  incentive_program_id?: string;
  name: string;
  instrument_type_id: string;
  settlement_type_id: string;
  incentive_sub_program_template?: {
    tranche_templates: VestingEventTemplate[];
  };
  performance: boolean;
  use_fair_value_for_soc_sec: boolean;
  accounting_turnover_rate?: string;
  purchase_config?: PurchaseConfig;
  awards: Award[];
  program?: Program;
  documents?: Api.V1.Document[];
}

export interface VestingEventTemplate {
  id?: string;
  quantityPercentage: string;
  strike: string;
  grant_date: string;
  vestedDate: string;
  expiry_date: string;
  purchase_price?: string;
  is_dividend: boolean;
}

export interface PurchaseConfig {
  id: string;
  price: string;
  window_id?: string;
  purchase_type: PurchaseType;
  discount?: number;
  require_share_depository: boolean;
  purchase_opportunities: PurchaseOpportunity[];
}

export interface PurchaseOpportunity {
  id: string;
  document_id?: string;
  employee_id: string;
  maximumAmount?: number;
  maximum_cash_amount?: number;
  cash_amount_used?: string;
  purchasedAmount: number;
}

export interface SubProgramState {
  allSubPrograms: SubProgram[];
  subProgram?: SubProgram;
  selectedSubProgram?: SubProgram;
  isFetching: boolean;
  isUploadingTemplate: boolean;
  isUploadingTemplateSuccess: boolean;
  isGeneratingSubprogramAgreements: boolean;
}

const initialState: SubProgramState = {
  allSubPrograms: [],
  subProgram: null,
  selectedSubProgram: null,
  isFetching: false,
  isUploadingTemplate: false,
  isUploadingTemplateSuccess: false,
  isGeneratingSubprogramAgreements: false,
};

const subProgramReducer: Reducer<SubProgramState> = (
  state = initialState,
  action
) => {
  if (action.type === POST_SUBPROGRAM) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === POST_SUBPROGRAM_SUCCEEDED) {
    return {
      ...state,
      allSubPrograms: [...state.allSubPrograms, action.subProgram],
      isFetching: false,
    };
  }

  if (action.type === GENERATE_SUBPROGRAM_AGREEMENTS) {
    return { ...state, isGeneratingSubprogramAgreements: true };
  }

  if (action.type === GENERATE_SUBPROGRAM_AGREEMENTS_SUCCEEDED) {
    return { ...state, isGeneratingSubprogramAgreements: false };
  }

  if (action.type === "ADD_VESTING_SUCCEEDED") {
    if (state.subProgram) {
      state.subProgram.incentive_sub_program_template.tranche_templates.push(
        action.vesting
      );
      return { ...state, ...{ subProgram: { ...state.subProgram } } };
    }

    return state;
  }

  if (action.type === "SELECT_SUBPROGRAM") {
    if (action.subProgram) {
      return { ...state, ...{ selectedSubProgram: action.subProgram } };
    }
  }

  if (action.type === FETCH_SUBPROGRAMS_SUCCEEDED) {
    return { ...state, ...{ allSubPrograms: action.subPrograms } };
  }

  if (action.type === PUT_SUBPROGRAM) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === PUT_SUBPROGRAM_SUCCEEDED) {
    const subProgramIndex = state.allSubPrograms.findIndex(
      subProgram => subProgram.id === action.subProgram.id
    );
    const subProgram = {
      ...state.allSubPrograms[subProgramIndex],
      ...action.subProgram,
    };
    state.allSubPrograms = [...state.allSubPrograms];
    state.allSubPrograms[subProgramIndex] = subProgram;
    return {
      ...state,
      allSubPrograms: [...state.allSubPrograms],
      isFetching: false,
    };
  }

  if (action.type === DELETE_SUBPROGRAM) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === DELETE_SUBPROGRAM_SUCCEEDED) {
    const subPrograms = state.allSubPrograms.filter(
      subProgram => subProgram.id !== action.subProgramId
    );
    return {
      ...state,
      allSubPrograms: [...subPrograms],
      isFetching: false,
    };
  }

  if (action.type === RESET_UPLOAD_SUBPROGRAM_TEMAPLATE_STATE) {
    return {
      ...state,
      isUploadingTemplate: false,
      isUploadingTemplateSuccess: false,
    };
  }

  if (action.type === UPLOAD_SUBPROGRAM_TEMAPLATE) {
    return {
      ...state,
      isUploadingTemplate: true,
      isUploadingTemplateSuccess: false,
    };
  }

  if (action.type === UPLOAD_SUBPROGRAM_TEMAPLATE_SUCCEEDED) {
    return {
      ...state,
      isUploadingTemplate: false,
      isUploadingTemplateSuccess: true,
    };
  }

  return state;
};

export default subProgramReducer;
