import Jsona from "jsona";
import Raven from "raven-js";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import {
  FETCH_TEXTS,
  FETCH_TEXTS_FAILED,
  FETCH_TEXTS_SUCCEEDED,
  PUT_TEXTS,
  PUT_TEXTS_FAILED,
  PUT_TEXTS_SUCCEEDED,
  UPDATE_DEFAULT_TEXTS,
  UPDATE_DEFAULT_TEXTS_FAILED,
  UPDATE_DEFAULT_TEXTS_SUCCEEDED,
} from "./text-actions";
import { LocaledTexts } from "./text-reducer";
import * as selectors from "./text-selectors";

const dataFormatter = new Jsona();
const textsRequestUrlWithLocale = (tenantId: string, locale: string) =>
  `/tenants/${tenantId}/texts/${locale}`;
const defaultTextsRequestUrl = "/default-texts/en";
const defaultTextsRequestUrlForLocale = (locale: string) =>
  `/default-texts/${locale}`;

function* fetchTextsRequested() {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const locales = yield select(selectors.supportedLocales);

    const allTextsResponse = yield all(
      locales.map(locale =>
        call(() => callApi(textsRequestUrlWithLocale(tenantId, locale), token))
      )
    );

    const rawDefaultTexts = yield call(() =>
      callApi(defaultTextsRequestUrl, token)
    );
    const defaultTexts = dataFormatter.deserialize(rawDefaultTexts) as any;

    const allDefaultTextsResponses = yield all(
      locales.map(locale =>
        call(() => callApi(defaultTextsRequestUrlForLocale(locale), token))
      )
    );

    const defaultTextsLocaled: LocaledTexts = allDefaultTextsResponses.reduce(
      (accu, current, index) => {
        const texts = dataFormatter.deserialize(current) as any;
        accu[locales[index]] = texts ? texts.value : {};
        return accu;
      },
      {}
    );

    yield put({
      defaultTexts: defaultTexts ? defaultTexts.value : {},
      defaultTextsLocaled,
      allTexts: allTextsResponse
        .map(r => dataFormatter.deserialize(r))
        .filter(d => d) as Api.V1.Text[],
      type: FETCH_TEXTS_SUCCEEDED,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_TEXTS_FAILED, message: e.message });
    }
  }
}

export function* watchFetchTexts() {
  yield takeEvery(FETCH_TEXTS, fetchTextsRequested);
}

function* putTextsRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "PUT";

    const textsResponse = yield call(() =>
      callApi(
        textsRequestUrlWithLocale(tenantId, action.payload.locale),
        token,
        method,
        dataFormatter.serialize({ stuff: action.payload })
      )
    );

    yield put({
      type: PUT_TEXTS_SUCCEEDED,
      texts: dataFormatter.deserialize(textsResponse) as Api.V1.Text,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: PUT_TEXTS_FAILED, message: e.message });
    }
  }
}

export function* watchPutTexts() {
  yield takeEvery(PUT_TEXTS, putTextsRequested);
}

function* updateDefaultTextsRequested(action) {
  try {
    const token = yield select(selectors.token);
    const method = "PUT";
    const { texts, locale } = action;
    const defaultTexts = yield select(selectors.defaultTextsForLocale(locale));

    const updatedTexts = { ...defaultTexts, ...texts };
    const body = {
      data: {
        id: "some string should be present here, it will be ignored by the API",
        attributes: {
          value: updatedTexts,
        },
        type: "defaultTexts",
      },
    };
    const textResponse = yield call(() =>
      callApi(defaultTextsRequestUrlForLocale(locale), token, method, body)
    );
    const deserializedResponse = dataFormatter.deserialize(textResponse) as any;
    yield put({
      texts: deserializedResponse.value,
      locale,
      type: UPDATE_DEFAULT_TEXTS_SUCCEEDED,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: UPDATE_DEFAULT_TEXTS_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchUpdateDefaultTexts() {
  yield takeEvery(UPDATE_DEFAULT_TEXTS, updateDefaultTextsRequested);
}
