export const ADD_PROGRAM = "ADD_PROGRAM";
export const ADD_PROGRAM_FAILED = "ADD_PROGRAM_FAILED";
export const ADD_PROGRAM_SUCCEEDED = "ADD_PROGRAM_SUCCEEDED";

export const POST_PROGRAM = "POST_PROGRAM";
export const POST_PROGRAM_FAILED = "POST_PROGRAM_FAILED";
export const POST_PROGRAM_SUCCEEDED = "POST_PROGRAM_SUCCEEDED";

export const FETCH_PROGRAMS = "FETCH_PROGRAMS";
export const FETCH_PROGRAMS_FAILED = "FETCH_PROGRAMS_FAILED";
export const FETCH_PROGRAMS_SUCCEEDED = "FETCH_PROGRAMS_SUCCEEDED";

export const FETCH_EMPLOYEES_AND_PROGRAMS = "FETCH_EMPLOYEES_AND_PROGRAMS";
export const FETCH_EMPLOYEES_AND_PROGRAMS_FAILED =
  "FETCH_EMPLOYEES_AND_PROGRAMS_FAILED";
export const FETCH_EMPLOYEES_AND_PROGRAMS_SUCCEEDED =
  "FETCH_EMPLOYEES_AND_PROGRAMS_SUCCEEDED";

export const PUT_PROGRAM = "PUT_PROGRAM";
export const PUT_PROGRAM_FAILED = "PUT_PROGRAM_FAILED";
export const PUT_PROGRAM_SUCCEEDED = "PUT_PROGRAM_SUCCEEDED";

export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const DELETE_PROGRAM_FAILED = "DELETE_PROGRAM_FAILED";
export const DELETE_PROGRAM_SUCCEEDED = "DELETE_PROGRAM_SUCCEEDED";

export const IMPORT_ALL_PROGRAM_AWARDS = "IMPORT_ALL_PROGRAM_AWARDS";
export const IMPORT_ALL_PROGRAM_AWARDS_FAILED =
  "IMPORT_ALL_PROGRAM_AWARDS_FAILED";
export const IMPORT_ALL_PROGRAM_AWARDS_SUCCEEDED =
  "IMPORT_ALL_PROGRAM_AWARDS_SUCCEEDED";

export const DELETE_ALL_PROGRAMS = "DELETE_ALL_PROGRAMS";
export const DELETE_ALL_PROGRAMS_FAILED = "DELETE_ALL_PROGRAMS_FAILED";
export const DELETE_ALL_PROGRAMS_SUCCEEDED = "DELETE_ALL_PROGRAMS_SUCCEEDED";
