import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as selectors from "../../admin-portal/employees/employee-selectors";
import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_FAILED,
  FETCH_DOCUMENTS_SUCCEEDED,
} from "../../admin-portal/files/files-actions";
import { APIEmployeeDocument } from "../../admin-portal/files/files-reducer";
import { callApi, NOT_AUTHORIZED } from "../../common/api/api-helper";

const FETCH_DOCUMENTS_URL = "/documents";

interface FetchDocumentsResponse {
  data: APIEmployeeDocument[];
}

function* fetchDocuments() {
  const token = yield select(selectors.token);

  try {
    const resultJson: FetchDocumentsResponse = yield call(
      callApi,
      FETCH_DOCUMENTS_URL,
      token
    );
    yield put({
      type: FETCH_DOCUMENTS_SUCCEEDED,
      documents: resultJson.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_DOCUMENTS_FAILED, message: e.message });
    }
  }
}

export function* watchFetchDocuments() {
  yield takeEvery(FETCH_DOCUMENTS, fetchDocuments);
}
