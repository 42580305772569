import moment, { Moment } from "moment";
import {
  Interval,
  overlappingDays,
} from "src/admin-portal/reports/generate-report/common/date";
import { TrancheState } from "src/admin-portal/reports/generate-report/common/types";
import { apiShortDate } from "src/common/utils/utils";

export type GroupByProperty<T> = (item: T) => string;

export function groupBy<T>(array: T[], groupBy: GroupByProperty<T>) {
  return array.reduce((groups, item): GroupedByLines<T> => {
    const val = groupBy(item);
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
}

export interface GroupedByLines<T> {
  [key: string]: T[];
}

export const mobilitiesBeforeDate = (
  mobilities: Api.V1.MobilityEntry[],
  date: Moment
) => mobilities.filter(m => moment(m.fromDate, apiShortDate).isBefore(date));

export const mobilitiesWithCostOrQuantity = (
  mobilities: Api.V1.MobilityEntry[],
  costInterval: Interval,
  trancheOB: TrancheState,
  obDate: Moment,
  trancheCB: TrancheState,
  cbDate: Moment
) =>
  mobilities.filter(m => {
    const mobilityInterval = {
      start: moment(m.fromDate, apiShortDate),
      end: moment(m.toDate, apiShortDate),
    };

    const hasCost = overlappingDays(costInterval, mobilityInterval) > 0;

    const mobilityOverlappingOB =
      overlappingDays(mobilityInterval, {
        start: obDate,
        end: obDate,
      }) > 0;

    const mobilityOverlappingCB =
      overlappingDays(mobilityInterval, {
        start: cbDate,
        end: cbDate,
      }) > 0;

    const hasQuantityOB =
      mobilityOverlappingOB && trancheOB && trancheOB.quantity > 0;
    const hasQuantityCB =
      mobilityOverlappingCB && trancheCB && trancheCB.quantity > 0;

    return hasCost || hasQuantityOB || hasQuantityCB;
  });

export const parseBackendNumber = (number: string | undefined | null) =>
  isNaN(parseFloat(number)) ? null : parseFloat(number);
export const parseBackendDate = (date: string | undefined | null) =>
  date ? moment(date, apiShortDate) : null;

export const mockDimensions = [
  {
    name: "Department",
    dimensionValue: "Arcona",
  },
  {
    name: "Division",
    dimensionValue: "Oslo",
  },
];
