import { useFormatMessage } from "src/admin-portal/texts/utils";

export enum InstrumentType {
  SUBSCRIPTION_RIGHTS = "subscription_rights",
  WARRANT = "warrant",
  OPTION = "option",
  RSU = "rsu",
  PSU = "psu",
  RSA = "rsa",
}

export interface TextTerm {
  singular: string;
  plural: string;
}

export const useInstrumentTypeText = (instrumentType: InstrumentType) => {
  const formatMessage = useFormatMessage();
  return instrumentTypeText(instrumentType, formatMessage);
};

export type FormatMessageFuntion = (data: { id: string }) => string;

export const instrumentTypeText = (
  instrumentType: InstrumentType,
  formatMessage?: FormatMessageFuntion
): TextTerm => {
  if (formatMessage) {
    return {
      singular: formatMessage({ id: singularKey(instrumentType) }),
      plural: formatMessage({ id: pluralKey(instrumentType) }),
    };
  }

  switch (instrumentType) {
    case InstrumentType.WARRANT:
      return {
        singular: "warrant",
        plural: "warrants",
      };
    case InstrumentType.OPTION:
      return {
        singular: "option",
        plural: "options",
      };
    case InstrumentType.RSU:
      return {
        singular: "RSU",
        plural: "RSUs",
      };
    case InstrumentType.RSA:
      return {
        singular: "RSA",
        plural: "RSAs",
      };
    case InstrumentType.PSU:
      return {
        singular: "PSU",
        plural: "PSUs",
      };
    case InstrumentType.SUBSCRIPTION_RIGHTS:
      return {
        singular: "subscription right",
        plural: "subscription rights",
      };
  }
};

const singularKey = (instrumentType: InstrumentType) =>
  `instrument.${instrumentType}.singular`;
const pluralKey = (instrumentType: InstrumentType) =>
  `instrument.${instrumentType}.plural`;

export const instrumentTypesText = (
  instrumentTypes: InstrumentType[],
  formatMessage?: FormatMessageFuntion
): TextTerm => {
  const singular = instrumentTypes
    .map(t => instrumentTypeText(t, formatMessage).singular)
    .join("/");
  const plural = instrumentTypes
    .map(t => instrumentTypeText(t, formatMessage).plural)
    .join("/");
  return {
    singular,
    plural,
  };
};
