import { Moment } from "moment";
import { Reducer } from "redux";
import { SELECT_TENANT } from "src/admin-portal/tenant/tenant-actions";
import {
  generatePaginatedPagesFromAllItems,
  generatePaginationLinksFromAllItems,
} from "src/common/utils/pagination";
import { flatten } from "../../common/utils/utils";
import {
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEES_SUCCEEDED,
} from "../employees/employee-actions";
import { Employee, MobilityEntry } from "../employees/employee-reducer";
import { Entity } from "../entity/entity-reducer";
import {
  FETCH_PROGRAMS,
  FETCH_PROGRAMS_SUCCEEDED,
} from "../programs/program-actions";
import {
  ADD_AWARD_VESTING_SUCCEEDED,
  DELETE_AWARD,
  DELETE_AWARD_SUCCEEDED,
  FETCH_TENANT_VESTING_EVENTS,
  FETCH_TENANT_VESTING_EVENTS_SUCCEEDED,
  fetchTranche,
  fetchTrancheSucceeded,
  POST_AWARD,
  POST_AWARD_SUCCEEDED,
  PUT_AWARD,
  PUT_AWARD_SUCCEEDED,
} from "./award-actions";
import {
  CREATE_TRANSACTIONS,
  CREATE_TRANSACTIONS_FAILED,
  CREATE_TRANSACTIONS_SUCCEEDED,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCEEDED,
  UPDATE_TRANSACTION_SECONDARY_FAILED,
  UPDATE_TRANSACTION_SECONDARY_SUCCEEDED,
  UPDATE_TRANSACTION_SUCCEEDED,
} from "./transaction/transaction-actions";

import {
  FETCH_EMPLOYEES_AND_PROGRAMS,
  FETCH_EMPLOYEES_AND_PROGRAMS_SUCCEEDED,
} from "src/admin-portal/programs/program-actions";

export interface Award {
  id?: string;
  incentive_sub_program_id: string;
  quantity: number;
  employee_id: string;
  is_purchasable?: boolean;
  tranches: VestingEvent[];
  documents?: Api.V1.Document[];
}

export interface VestingEvent {
  id?: string;
  quantity: number;
  strike: string;
  vestedDate: Moment;
  grant_date: Moment;
  expiry_date: Moment;
  exercised_quantity?: number;
  termination_quantity?: number;
  purchase_price?: string;
  is_dividend: boolean;
  transactions?: TrancheTransaction[];
  fair_value?: number | string;
  cap_on_gain?: string;
}

export interface TrancheImport {
  id?: string;
  quantity: number;
  strike: string;
  vestedDate: Moment;
  grant_date: Moment;
  expiry_date: Moment;
  exercised_quantity?: number;
  termination_quantity?: number;
  purchase_price?: string;
  is_dividend: boolean;
  fair_value?: number | string;
  fv_valuation_method?: string;
  fv_share_price_at_grant?: string;
  fv_strike_price?: string;
  fv_expected_lifetime?: string;
  fv_volatility?: string;
  fv_interest_rate?: string;
  fv_dividend?: string;
  comment?: string;
}

export interface TrancheTransaction {
  id?: string;
  transaction_date: string;
  transaction_type: string;

  termination_date?: string;

  grant_date?: string;
  vested_date?: string;
  expiry_date?: string;
  strike?: string;
  quantity?: number;
  purchase_price?: string;

  fair_value?: string;
  account_id?: string;
  tranche_id?: string;
  fv_valuation_method?: string;
  fv_share_price_at_grant?: string;
  fv_strike_price?: string;
  fv_expected_lifetime?: string;
  fv_volatility?: string;
  fv_interest_rate?: string;
  fv_dividend?: string;
  comment?: string;
}

export interface VestingEventApi {
  id?: string;
  quantity: number;
  strike: string;
  vestedDate: string;
  grant_date: string;
  expiry_date: string;
  purchase_price?: string;
  is_dividend: boolean;
}

export interface ExcelSheetAwardLine {
  id: string;
  integerId: number; // Used for sorting while working with the report in Excel
  internal_employee_id?: string;
  tranche_id: string;
  programId: string;
  programName: string;
  subProgramName: string;
  employeeName: string;
  employee: Api.V1.Employee;
  entity: Api.V1.Entity;
  country: string;
  entityName: string;
  instrumentName: string;
  settlementName: string;
  performance: boolean;
  grantDate?: Moment;
  vestedDate?: Moment;
  expiryDate?: Moment;
  strike: number;
  quantity: number;
  exercisedQuantity: number;
  purchase_price?: string;
  fair_value?: string;
  transaction_type: string;
  transaction_date: Moment;
  transactions_to_terminate?: ExcelSheetAwardLine[];
  is_dividend: boolean;
  mobility: Api.V1.MobilityEntry[];
  fv_valuation_method?: string;
  fv_share_price_at_grant?: number;
  fv_strike_price?: number;
  fv_expected_lifetime?: number;
  fv_volatility?: number;
  fv_interest_rate?: number;
  fv_dividend?: number;
  comment?: string;
  newQuantityFactor: number;
  performanceRule: Api.V1.PerformanceRule;
}

export interface Tranche {
  id: string;

  employeeName: string;
  employee: Api.V1.Employee;
  country: string;
  entityName: string;
  entity: Api.V1.Entity;
  internal_employee_id?: string;

  programId: string;
  programName: string;
  subProgramId: string;
  subProgramName: string;
  subProgram: Api.V1.IncentiveSubProgram;
  instrumentName: string;
  settlementName: string;

  performance: boolean;
  grantDate: Moment;
  vestedDate: Moment;
  expiryDate: Moment;
  strike: number;
  quantity: number;
  exercisedQuantity: number;
  termination_quantity: number;
  purchase_price?: string;
  is_dividend: boolean;
  fair_value?: string;

  transactions: Api.V1.Transaction[];
  mobility: Api.V1.MobilityEntry[];
  tranchePerformanceRules: Api.V1.TranchePerformanceRule[];
  annualTurnover?: string;
}

export interface PaginatedTranches {
  isFetching: boolean;
  allPaginatedTranches: { [pageNumber: string]: Api.V1.VestingEvent[] };
  paginatedTranchesLinks: { [name: string]: string };
  recordCount: number;
}

export interface AwardState {
  allAwards: Award[];
  award?: Award;
  isFetching: boolean;
  isFetchingAllTranches: boolean;
  isFetchingTranches: boolean;
  isFetchingTranche: boolean;
  areAllTranchesFetched: boolean;
  isCreatingTransactions: boolean;
  isCreatingTransactionsSuccess: boolean;
  isDeletingingTransaction: boolean;
  hasPutTransactionErr: boolean;
  allTranches: Api.V1.VestingEvent[];
  paginatedTranches: PaginatedTranches;
  tranche: Api.V1.VestingEvent;
}

const initialState: AwardState = {
  allAwards: [],
  award: null,
  isFetching: false,
  areAllTranchesFetched: false,
  allTranches: [],
  paginatedTranches: {
    isFetching: false,
    allPaginatedTranches: {},
    paginatedTranchesLinks: null,
    recordCount: 0,
  },
  isFetchingAllTranches: false,
  isFetchingTranches: false,
  isFetchingTranche: false,
  isCreatingTransactions: false,
  isCreatingTransactionsSuccess: false,
  isDeletingingTransaction: false,
  hasPutTransactionErr: false,
  tranche: null,
};

const awardReducer: Reducer<AwardState> = (state = initialState, action) => {
  if (action.type === POST_AWARD) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === POST_AWARD_SUCCEEDED) {
    return {
      ...state,
      allAwards: [...state.allAwards, action.award],
      isFetching: false,
    };
  }

  if (action.type === ADD_AWARD_VESTING_SUCCEEDED) {
    if (state.award) {
      state.award.tranches.push(action.vesting);
      return { ...state, ...{ award: { ...state.award } } };
    }
  }

  if (action.type === PUT_AWARD) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === PUT_AWARD_SUCCEEDED) {
    const awardIndex = state.allAwards.findIndex(
      award => award.id === action.award.id
    );
    const award = { ...state.allAwards[awardIndex], ...action.award };
    state.allAwards = [...state.allAwards];
    state.allAwards[awardIndex] = award;
    return { ...state, allAwards: [...state.allAwards], isFetching: false };
  }

  if (
    [FETCH_PROGRAMS, FETCH_EMPLOYEES, FETCH_EMPLOYEES_AND_PROGRAMS].includes(
      action.type
    )
  ) {
    return { ...state, isFetching: true };
  }

  if (
    [
      FETCH_PROGRAMS_SUCCEEDED,
      FETCH_EMPLOYEES_SUCCEEDED,
      FETCH_EMPLOYEES_AND_PROGRAMS_SUCCEEDED,
    ].includes(action.type)
  ) {
    return { ...state, isFetching: false };
  }

  if (action.type === FETCH_TENANT_VESTING_EVENTS) {
    return {
      ...state,
      paginatedTranches: { ...state.paginatedTranches, isFetching: true },
    };
  }

  if (action.type === FETCH_TENANT_VESTING_EVENTS_SUCCEEDED) {
    const paginatedTrancheKey = Object.keys(action.tranches)[0];
    return {
      ...state,
      paginatedTranches: {
        allPaginatedTranches: {
          ...state.paginatedTranches.allPaginatedTranches,
          ...action.tranches,
        },
        paginatedTranchesLinks: action.paginatedTranchesLinks,
        recordCount: action.recordCount,
        isFetching: false,
      },
      allTranches: [
        ...state.allTranches,
        ...action.tranches[paginatedTrancheKey],
      ],
    };
  }

  if (action.type === DELETE_AWARD) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === DELETE_AWARD_SUCCEEDED) {
    const allAwards = state.allAwards.filter(
      award => award.id !== action.awardId
    );
    return { ...state, allAwards: [...allAwards], isFetching: false };
  }

  if (action.type === CREATE_TRANSACTIONS) {
    return {
      ...state,
      isCreatingTransactions: true,
      isCreatingTransactionsSuccess: false,
    };
  }

  if (action.type === CREATE_TRANSACTIONS_FAILED) {
    return {
      ...state,
      isCreatingTransactions: false,
      isCreatingTransactionsSuccess: false,
    };
  }

  if (action.type === CREATE_TRANSACTIONS_SUCCEEDED) {
    const allTranches = state.allTranches.map(tranche => {
      const matchedTransaction = action.transactions.find(
        transaction => transaction.tranche.id === tranche.id
      );
      return matchedTransaction ? matchedTransaction.tranche : tranche;
    });

    return {
      ...state,
      allTranches,
      isCreatingTransactions: false,
      isCreatingTransactionsSuccess: true,
    };
  }

  if (action.type === DELETE_TRANSACTION) {
    return { ...state, isDeletingingTransaction: true };
  }

  if (action.type === DELETE_TRANSACTION_SUCCEEDED) {
    const allTranches = state.allTranches.map(tranche => {
      let transactions = tranche.transactions;

      if (tranche.id === action.trancheId) {
        transactions = tranche.transactions.filter(
          t => t.id !== action.transactionId
        );
      }

      return { ...tranche, transactions };
    });
    return { ...state, allTranches, isDeletingingTransaction: false };
  }

  if (action.type === UPDATE_TRANSACTION_SECONDARY_FAILED) {
    return { ...state, hasPutTransactionErr: true };
  }

  if (
    action.type === UPDATE_TRANSACTION_SUCCEEDED ||
    action.type === UPDATE_TRANSACTION_SECONDARY_SUCCEEDED
  ) {
    const allTranches = state.allTranches.map(tranche => {
      const updatedTransaction = action.transactions.find(
        transaction => transaction.tranche.id === tranche.id
      );
      return updatedTransaction ? updatedTransaction.tranche : tranche;
    });

    return {
      ...state,
      allTranches,
      paginatedTranches: {
        allPaginatedTranches: generatePaginatedPagesFromAllItems(
          allTranches,
          action.pageSize
        ),
        paginatedTranchesLinks: generatePaginationLinksFromAllItems(
          allTranches.length,
          action.pageSize
        ),
        recordCount: allTranches.length,
        isFetching: false,
      },
    };
  }

  if (action.type === SELECT_TENANT) {
    return initialState;
  }

  if (action.type === fetchTranche.getType()) {
    return {
      ...state,
      isFetchingTranche: true,
    };
  }

  if (action.type === fetchTrancheSucceeded.getType()) {
    return {
      ...state,
      isFetchingTranche: false,
      tranche: action.payload,
    };
  }

  return state;
};

export default awardReducer;
