import { TransactionType } from "src/constants";
import { sumNumbers } from "./utils";

export const terminationQuantity = (tranche: Api.V1.VestingEvent) =>
  -1 *
  tranche.transactions
    .filter(x => x.transactionType === TransactionType.TERMINATION)
    .map(x => x.quantity || 0)
    .reduce(sumNumbers, 0);

export const expiredQuantity = (tranche: Api.V1.VestingEvent) =>
  -1 *
  tranche.transactions
    .filter(x => x.transactionType === TransactionType.EXPIRY)
    .map(x => x.quantity || 0)
    .reduce(sumNumbers, 0);
