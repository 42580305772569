import { all } from "redux-saga/effects";
import { watchFetchAuditLogs } from "src/admin-portal/audit-logs/audit-logs-saga";
import { watchPostEmail } from "src/admin-portal/email/send-email-saga";
import {
  watchDeletePerformanceRuleEntry,
  watchPatchPerformanceRuleEntry,
  watchPerformanceRuleEntries,
  watchPostPerformanceRuleEntry,
} from "src/admin-portal/performance-rules/performance-rule-entries-page/performance-rule-entries-saga";
import {
  watchDeletePerformanceRule,
  watchPatchPerformanceRule,
  watchPatchTranchePerformanceRule,
  watchPerformanceRules,
  watchPostPerformanceRule,
} from "src/admin-portal/performance-rules/performance-rules-page/performance-rules-saga";
import {
  watchDeleteReportConfig,
  watchFetchReportConfigs,
  watchPatchReportConfig,
  watchPostReportConfig,
} from "src/admin-portal/reports/reports-config/reports-configs-saga";
import {
  watchDeleteReport,
  watchFetchReports,
  watchFetchReportsAllAwards,
  watchGenerateReport,
  watchPostReport,
  watchUpdateReport,
} from "src/sagas/reports-saga";
import {
  watchDeleteSharePrice,
  watchFetchSharePrice,
  watchPostSharePrice,
} from "src/sagas/share-price-saga";
import { watchFetchSelfServiceOverview } from "src/self-service-portal/self-service-saga";
import {
  watchAddAwardVesting,
  watchDeleteAward,
  watchFetchTenantVestingEvents,
  watchFetchTrancheRequested,
  watchPostAward,
  watchPutAward,
  watchPutAwards,
} from "../admin-portal/awards/award-saga";
import {
  watchCreatePurchaseConfig,
  watchDeletePurchaseConfig,
  watchUpdatePurchaseConfig,
} from "../admin-portal/awards/purchase/purchase-saga";
import {
  watchCreateTransactions,
  watchDeleteTransaction,
  watchUpdateTransaction,
  watchUpdateTransactionSecondary,
} from "../admin-portal/awards/transaction/transaction-saga";
import {
  watchDeleteContentTemplateValues,
  watchFetchContent,
  watchPatchContentTemplateValues,
  watchPostContentTemplateValues,
  watchUpdateContent,
} from "../admin-portal/content/content-saga";
import {
  watchCreateDividend,
  watchFetchDividends,
} from "../admin-portal/dividend/dividend-saga";
import {
  watchCreateEmployees,
  watchDeleteAllEmployees,
  watchDeleteEmployee,
  watchFetchEmployee,
  watchFetchEmployees,
  watchFetchEntitiesAndEmployees,
  watchPostEmployee,
  watchPutEmployee,
  watchReverseTerminateEmployee,
  watchTerminateEmployee,
  watchTerminateEmployeeCustom,
} from "../admin-portal/employees/employee-saga";
import {
  watchCreateEntities,
  watchDeleteEntities,
  watchDeleteEntity,
  watchFetchEntities,
  watchPostEntity,
  watchPutEntity,
} from "../admin-portal/entity/entity-saga";
import {
  watchDeleteExerciseWindow,
  watchFetchExerciseWindow,
  watchFetchWindowOrdersRequested,
  watchPostExerciseWindow,
  watchPutExerciseWindow,
  watchWindowCompleteCashlessOrders,
  watchWindowCompleteExerciseAndHoldOrders,
  watchWindowCompleteSaleCashlessOrders,
  watchWindowStateStartProcessingRequested,
} from "../admin-portal/exercise-windows/window-saga";
import {
  watchAttatchEmployeeToFile,
  watchdeleteDocument,
  watchDeleteEmployeeAssociation,
  watchFetchEmployeesAndFiles,
  watchFetchFiles,
  watchFileDownload,
  watchMarkDocumentAsRead,
  watchUpdateEmployeeDocumentsForFile,
  watchUploadFiles,
} from "../admin-portal/files/files-saga";
import { watchImportAllModels } from "../admin-portal/import/import-saga";
import {
  watchDeleteOrder,
  watchFetchTenantsOrders,
  watchPutTenantsOrders,
} from "../admin-portal/orders/orders-saga";
import {
  watchAddProgram,
  watchAddSubProgram,
  watchDeleteAllPrograms,
  watchDeleteProgram,
  watchFetchEmployeesAndPrograms,
  watchFetchPrograms,
  watchImportAllProgramAwards,
  watchPostProgram,
  watchPutProgram,
} from "../admin-portal/programs/program-saga";
import {
  watchAddTemplateToSubprogram,
  watchAddVesting,
  watchDeleteSubProgram,
  watchFetchSubProgram,
  watchGenerateSubProgramAgreements,
  watchPostSubProgram,
  watchPutSubProgram,
} from "../admin-portal/subprograms/subprogram-saga";
import {
  watchDeleteSysadmin,
  watchFetchSysadmins,
  watchPostSysadmin,
} from "../admin-portal/sysadmin/sysadmin-saga";
import {
  watchDeleteTenant,
  watchDeleteTenantSucceeded,
  watchFetchLiveTenantsOverview,
  watchFetchTenants,
  watchPostTenant,
  watchPutTenant,
  watchPutTenantSucceeded,
  watchSelectTenant,
} from "../admin-portal/tenant/tenant-saga";
import {
  watchFetchTexts,
  watchPutTexts,
  watchUpdateDefaultTexts,
} from "../admin-portal/texts/text-saga";
import {
  watchLoginRequested,
  watchLogoutRequested,
  watchParseAuthHash,
} from "../common/auth/auth-saga";
import { watchKeepAlive } from "../common/auth/keep-alive-saga";
import { watchFetchDocuments } from "../employee-portal/documents/document-saga";
import { watchPutEmployeeProfile } from "../employee-portal/employee-portal-profile-saga";
import { watchFethEmployeePortalWelcome } from "../employee-portal/employee-portal-welcome-saga";
import {
  watchFetchUsersOrders,
  watchPlaceOrder,
} from "../employee-portal/exercise/order/order-saga";
import {
  watchCreatePurchaseOrder,
  watchFetchPurchaseDocument,
} from "../employee-portal/purchase/duck/purchase-saga";

export default function* root() {
  yield all([
    watchFetchTenants(),
    watchPostTenant(),
    watchSelectTenant(),
    watchPutTenant(),
    watchPutTenantSucceeded(),
    watchDeleteTenant(),
    watchDeleteTenantSucceeded(),
    watchFetchLiveTenantsOverview(),
    watchFetchEntities(),
    watchPostEntity(),
    watchDeleteEntity(),
    watchPutEntity(),
    watchCreateEntities(),
    watchDeleteEntities(),
    watchFetchEmployees(),
    watchFetchEmployee(),
    watchPostEmployee(),
    watchDeleteEmployee(),
    watchPutEmployee(),
    watchCreateEmployees(),
    watchDeleteAllEmployees(),
    watchFetchEntitiesAndEmployees(),
    watchFetchSysadmins(),
    watchPostSysadmin(),
    watchDeleteSysadmin(),
    watchAddProgram(),
    watchAddSubProgram(),
    watchAddVesting(),
    watchCreatePurchaseConfig(),
    watchUpdatePurchaseConfig(),
    watchUpdateDefaultTexts(),
    watchDeletePurchaseConfig(),
    watchFetchPrograms(),
    watchPostProgram(),
    watchPutProgram(),
    watchDeleteProgram(),
    watchDeleteAllPrograms(),
    watchFetchEmployeesAndPrograms(),
    watchImportAllProgramAwards(),
    watchPostSubProgram(),
    watchFetchSubProgram(),
    watchPutSubProgram(),
    watchDeleteSubProgram(),
    watchFethEmployeePortalWelcome(),
    watchPutEmployeeProfile(),
    watchPostAward(),
    watchPutAward(),
    watchPutAwards(),
    watchFetchTrancheRequested(),
    watchDeleteAward(),
    watchFetchDividends(),
    watchCreateDividend(),
    watchFetchTenantVestingEvents(),
    watchAddAwardVesting(),
    watchCreateTransactions(),
    watchUpdateTransaction(),
    watchUpdateTransactionSecondary(),
    watchDeleteTransaction(),
    watchPerformanceRules(),
    watchPostPerformanceRule(),
    watchPatchPerformanceRule(),
    watchDeletePerformanceRule(),
    watchPatchTranchePerformanceRule(),
    watchPerformanceRuleEntries(),
    watchPostPerformanceRuleEntry(),
    watchPatchPerformanceRuleEntry(),
    watchDeletePerformanceRuleEntry(),
    watchPostEmail(),
    watchParseAuthHash(),
    watchPostSharePrice(),
    watchFetchFiles(),
    watchUploadFiles(),
    watchAttatchEmployeeToFile(),
    watchdeleteDocument(),
    watchFileDownload(),
    watchFetchPurchaseDocument(),
    watchCreatePurchaseOrder(),
    watchReverseTerminateEmployee(),
    watchMarkDocumentAsRead(),
    watchFetchContent(),
    watchImportAllModels(),
    watchUpdateEmployeeDocumentsForFile(),
    watchUpdateContent(),
    watchPostContentTemplateValues(),
    watchDeleteContentTemplateValues(),
    watchPatchContentTemplateValues(),
    watchFetchDocuments(),
    watchFetchEmployeesAndFiles(),
    watchDeleteEmployeeAssociation(),
    watchFetchSharePrice(),
    watchWindowStateStartProcessingRequested(),
    watchTerminateEmployee(),
    watchTerminateEmployeeCustom(),
    watchDeleteSharePrice(),
    watchPlaceOrder(),
    watchFetchUsersOrders(),
    watchPostExerciseWindow(),
    watchFetchExerciseWindow(),
    watchDeleteExerciseWindow(),
    watchPutExerciseWindow(),
    watchParseAuthHash(),
    watchKeepAlive(),
    watchFetchTenantsOrders(),
    watchLoginRequested(),
    watchLogoutRequested(),
    watchPutTenantsOrders(),
    watchDeleteOrder(),
    watchFetchTexts(),
    watchPutTexts(),
    watchFetchReports(),
    watchPostReport(),
    watchDeleteReport(),
    watchUpdateReport(),
    watchFetchReportConfigs(),
    watchPostReportConfig(),
    watchPatchReportConfig(),
    watchDeleteReportConfig(),
    watchFetchWindowOrdersRequested(),
    watchFetchSelfServiceOverview(),
    watchFetchReportsAllAwards(),
    watchWindowCompleteExerciseAndHoldOrders(),
    watchWindowCompleteCashlessOrders(),
    watchWindowCompleteSaleCashlessOrders(),
    watchGenerateReport(),
    watchGenerateSubProgramAgreements(),
    watchAddTemplateToSubprogram(),
    watchFetchAuditLogs(),
  ]);
}
