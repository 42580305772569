import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  toApiOrder,
  toApiOrderSimple,
} from "src/common/api/orders-response-mapper";
import { callApi, NOT_AUTHORIZED } from "../../common/api/api-helper";
import {
  DELETE_ORDER,
  DELETE_ORDER_FAILED,
  DELETE_ORDER_SUCCEEDED,
  FETCH_TENANT_ORDERS,
  FETCH_TENANT_ORDERS_FAILED,
  FETCH_TENANT_ORDERS_SUCCEEDED,
  PUT_TENANT_ORDERS,
  PUT_TENANT_ORDERS_FAILED,
  PUT_TENANT_ORDERS_SUCCEEDED,
} from "./orders-actions";
import * as selectors from "./orders-selectors";

const ORDERS_REQUEST_URL = "/orders?tenantId=";

const orderUrl = id => `/orders/${id}`;

function* fetchTenantsOrdersRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const ordersResponse = yield call(() =>
      callApi(ORDERS_REQUEST_URL + tenantId, token)
    );
    yield put({
      type: FETCH_TENANT_ORDERS_SUCCEEDED,
      orders: ordersResponse.data.map(toApiOrder),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
      yield put({ type: FETCH_TENANT_ORDERS_FAILED, message: e.message });
    }
  }
}

export function* watchFetchTenantsOrders() {
  yield takeEvery(FETCH_TENANT_ORDERS, fetchTenantsOrdersRequested);
}

function* putTenantsOrdersRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const orderId = action.orderId;
    const method = "PUT";

    const ordersResponse = yield call(() =>
      callApi(
        orderUrl(orderId) + "?tenantId=" + tenantId,
        token,
        method,
        action.updateOrder
      )
    );
    yield put({
      type: PUT_TENANT_ORDERS_SUCCEEDED,
      order: toApiOrderSimple(ordersResponse.data),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
      yield put({ type: PUT_TENANT_ORDERS_FAILED, message: e.message });
    }
  }
}

export function* watchPutTenantsOrders() {
  yield takeEvery(PUT_TENANT_ORDERS, putTenantsOrdersRequested);
}

function* deleteOrderRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const { orderId } = action;
    const method = "DELETE";

    yield call(() =>
      callApi(orderUrl(orderId) + "?tenantId=" + tenantId, token, method)
    );
    yield put({ type: DELETE_ORDER_SUCCEEDED, orderId });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_ORDER_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, deleteOrderRequested);
}
