import Jsona from "jsona";
import Raven from "raven-js";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import {
  DELETE_REPORT,
  DELETE_REPORT_FAILED,
  DELETE_REPORT_SUCCEEDED,
  FETCH_REPORTS,
  FETCH_REPORTS_ALL_AWARDS,
  FETCH_REPORTS_ALL_AWARDS_FAILED,
  FETCH_REPORTS_ALL_AWARDS_SUCCEEDED,
  FETCH_REPORTS_FAILED,
  FETCH_REPORTS_SUCCEEDED,
  generateReport,
  POST_REPORT,
  POST_REPORT_FAILED,
  POST_REPORT_SUCCEEDED,
  UPDATE_REPORT,
  UPDATE_REPORT_FAILED,
  UPDATE_REPORT_SUCCEEDED,
} from "src/admin-portal/actions/reports-actions";
import { callApi, NOT_AUTHORIZED, submitForm } from "src/common/api/api-helper";
import { apiShortDate } from "src/common/utils/utils";
import * as selectors from "src/selectors";

const reportsFetchUrl = tenantId =>
  `/tenants/${tenantId}/reports?include=uploadedReports`;
const reportsPostUrl = tenantId =>
  `/tenants/${tenantId}/reports?include=uploadedReports`;
const uploadedReportPostUrl = tenantId =>
  `/tenants/${tenantId}/uploaded-reports`;
const reportUrl = reportId => `/reports/${reportId}`;
const dataFormatter = new Jsona();

function* fetchReportsRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId =
      (yield select(selectors.tenantId)) ||
      (yield select(selectors.userTenantId));

    const response = yield call(() =>
      callApi(reportsFetchUrl(tenantId), token)
    );
    yield put({
      type: FETCH_REPORTS_SUCCEEDED,
      reports: dataFormatter.deserialize(response),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_REPORTS_FAILED, message: e.message });
    }
  }
}

export function* watchFetchReports() {
  yield takeEvery(FETCH_REPORTS, fetchReportsRequested);
}

function* postReportRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";
    const { files, ...attributes } = action.report;

    const uploadedReports = yield all(
      Array.from(files).map(async (file: File) => {
        const uploadReportBody = new FormData();
        uploadReportBody.append("data[type]", "uploaded_reports");
        uploadReportBody.append("data[attributes][file]", file);

        const uploadReportResponse = await submitForm(
          uploadedReportPostUrl(tenantId),
          token,
          uploadReportBody
        );
        const uploadReportSerialized: any = dataFormatter.deserialize(
          uploadReportResponse
        );
        return {
          type: uploadReportSerialized.type,
          id: uploadReportSerialized.id,
        };
      })
    );

    const body = {
      data: {
        type: "reports",
        attributes,
        relationships: {
          uploadedReports: {
            data: uploadedReports,
          },
        },
      },
    };

    const response = yield call(() =>
      callApi(reportsPostUrl(tenantId), token, method, body)
    );
    yield put({
      type: POST_REPORT_SUCCEEDED,
      report: dataFormatter.deserialize(response),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: POST_REPORT_FAILED, message: e.message });
    }
  }
}

export function* watchPostReport() {
  yield takeEvery(POST_REPORT, postReportRequested);
}

function* updateReportRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "PATCH";
    const { files, id, ...attributes } = action.report;

    const body = {
      data: {
        id,
        type: "reports",
        attributes,
      },
    };

    const response = yield call(() =>
      callApi(reportUrl(id) + "?include=uploadedReports", token, method, body)
    );
    yield put({
      type: UPDATE_REPORT_SUCCEEDED,
      report: dataFormatter.deserialize(response),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: UPDATE_REPORT_FAILED, message: e.message });
    }
  }
}

export function* watchUpdateReport() {
  yield takeEvery(UPDATE_REPORT, updateReportRequested);
}

function* deleteReportRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const { reportId } = action;
    const method = "DELETE";

    yield call(() => callApi(reportUrl(reportId), token, method));
    yield put({ type: DELETE_REPORT_SUCCEEDED, reportId });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_REPORT_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteReport() {
  yield takeEvery(DELETE_REPORT, deleteReportRequested);
}

const awardIncludes = [
  "tranches.transactions",
  "tranches.tranchePerformanceRules.performanceRule",
  "tranches.tranchePerformanceRules.performanceRule.performanceRuleEntries",
  "employee.entity",
  "employee.mobilityEntries",
  "employee.mobilityEntries.entity",
  "employee.employeeCustomRelationships",
  "employee.employeeCustomRelationships.customRelationshipType",
  "incentiveSubProgram.incentiveProgram",
];
export const tenantAwardsRequestUrl = tenantId =>
  `/tenants/${tenantId}/all-awards?include=${awardIncludes.join(",")}`;

function* fetchReportsAllAwards(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const awardResponse = yield call(() =>
      callApi(tenantAwardsRequestUrl(tenantId), token)
    );
    const awards = dataFormatter.deserialize(awardResponse);

    yield put({
      type: FETCH_REPORTS_ALL_AWARDS_SUCCEEDED,
      awards,
    });
  } catch (e) {
    Raven.captureException(e);
    yield put({ type: FETCH_REPORTS_ALL_AWARDS_FAILED, message: e.message });
  }
}

export function* watchFetchReportsAllAwards() {
  yield takeEvery(FETCH_REPORTS_ALL_AWARDS, fetchReportsAllAwards);
}

function* generateReportRequested(action: ReturnType<typeof generateReport>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const {
      overviewData,
      ifrsData,
      ifrsYtdData,
      socSecData,
      socSecYtdData,
      dilutionData,
      notesData,
      employeeNotesData,
      fromDate,
      toDate,
      filename,
    } = action.payload;
    const response = yield call(() =>
      callApi(`/tenants/${tenantId}/generate_report`, token, "POST", {
        overview_data: overviewData,
        ifrs_data: ifrsData,
        ifrs_ytd_data: ifrsYtdData,
        soc_sec_data: socSecData,
        soc_sec_ytd_data: socSecYtdData,
        dilution_data: dilutionData,
        notes_data: notesData,
        employee_notes_data: employeeNotesData,
        from_date: fromDate.format(apiShortDate),
        to_date: toDate.format(apiShortDate),
        filename,
      })
    );
    const blob = yield response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  } catch (e) {
    Raven.captureException(e);
  }
}

export function* watchGenerateReport() {
  yield takeEvery(generateReport.getType(), generateReportRequested);
}
