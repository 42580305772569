import { flatten } from "../../common/utils/utils";
import { RootState } from "../../reducers/all-reducers";
import { Award, VestingEvent } from "../awards/award-reducer";
import { SubProgram } from "../subprograms/subprogram-reducer";
import { Program } from "./program-reducer";

export const token = state => state.user.token;
export const isSysadmin = state => state.user.isSysadmin;
export const tenantId = state =>
  state.tenant.selectedTenant && state.tenant.selectedTenant.id;

export const allSubprograms = (programs: Program[]): SubProgram[] =>
  flatten(
    programs.map((p: Program) =>
      p.incentive_sub_programs.map(sp => ({ ...sp, program: p }))
    )
  );
export const subprogramById = (subprogramId: string) => (
  state: RootState
): SubProgram | undefined =>
  allSubprograms(state.program.allPrograms).filter(
    sp => sp.id === subprogramId
  )[0];

const allAwards = (programs: Program[]): Award[] =>
  flatten(allSubprograms(programs).map(sp => sp.awards));
const allTranches = (programs: Program[]): VestingEvent[] =>
  flatten(allAwards(programs).map(a => a.tranches));

export const awardById = (awardId: string) => (state: RootState): Award =>
  allAwards(state.program.allPrograms).filter(a => a.id === awardId)[0];

export const trancheById = (
  trancheId: string,
  tranches: Api.V1.VestingEvent[]
): Api.V1.VestingEvent | undefined =>
  tranches.filter(tranche => tranche.id === trancheId)[0];
