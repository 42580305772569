import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { postSharePriceSucceeded } from "src/admin-portal/actions/share-price-actions";
import { POST_AWARD_SUCCEEDED } from "src/admin-portal/awards/award-actions";
import { POST_EMPLOYEE_SUCCEEDED } from "src/admin-portal/employees/employee-actions";
import { FETCH_TENANT_ORDERS_SUCCEEDED } from "src/admin-portal/orders/orders-actions";
import { POST_PROGRAM_SUCCEEDED } from "src/admin-portal/programs/program-actions";
import { POST_SUBPROGRAM_SUCCEEDED } from "src/admin-portal/subprograms/subprogram-actions";
import { POST_TENANT_SUCCEEDED } from "src/admin-portal/tenant/tenant-actions";
import { setSession, updateSession } from "./auth";
import { employeePortalUserEmail } from "./selectors";
export const KEEP_ALIVE_ACTION = "KEEP_ALIVE";

function* keepAlive() {
  try {
    const authResult = yield call(updateSession);
    const userEmail = yield select(employeePortalUserEmail);
    setSession(authResult, userEmail);
    yield put({ type: "PARSE_AUTH_HASH_SUCCEEDED", authResult });
  } catch (e) {
    Raven.captureException(e);
    yield put({ type: "KEEP_ALIVE_FAILED", message: e });
  }
}

export function* watchKeepAlive() {
  yield* [
    takeEvery(KEEP_ALIVE_ACTION, keepAlive),
    takeEvery(FETCH_TENANT_ORDERS_SUCCEEDED, keepAlive),
    takeEvery(POST_AWARD_SUCCEEDED, keepAlive),
    takeEvery(POST_EMPLOYEE_SUCCEEDED, keepAlive),
    takeEvery(POST_SUBPROGRAM_SUCCEEDED, keepAlive),
    takeEvery(POST_PROGRAM_SUCCEEDED, keepAlive),
    takeEvery(postSharePriceSucceeded.getType(), keepAlive),
    takeEvery(POST_TENANT_SUCCEEDED, keepAlive),
  ];
}
