import moment, { Moment } from "moment";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItemProps,
  Form,
  Message,
} from "semantic-ui-react";
import { SupportedLocale } from "src/admin-portal/texts/text-reducer";
import CurrencyDropdown from "src/common/components/currency/currency-dropdown";
import { generateSupportedLanguagesOptions } from "src/common/utils/locale-utils";
import { DateFormatState } from "src/reducers/date-format-reducer";
import { countryOptions } from "../../common/data/common";
import {
  apiShortDate,
  changeCommaForPunctuation,
  norwegianShortDateLongYear,
} from "../../common/utils/utils";

interface Props {
  entityOptions: DropdownItemProps[];
  saveEmployee: (employee: Api.V1.Employee) => void;
  onCloseForm: () => void;
  selectedEmployee: Api.V1.Employee;
  dateFormat: DateFormatState;
  tenantCurrency: string;
}

interface MobilityEntryInput {
  id: string;
  fromDate: string;
  toDate: string;
  overrideEntitySocSec: string;
  entityId: string;
}

interface State {
  firstName: string;
  lastName: string;
  locale: string;
  currencyCode: string;
  email: string;
  residence: string;
  entityId: string;
  socSec: string;
  internalIdentification: string;
  insider: boolean;
  mobilityEntries: MobilityEntryInput[];
  areMobilityRowsValid: boolean;
  shareDepositoryAccount: string;
  shareDepositoryBank: string;
  shareDepositoryContact: string;
  shareDepositoryDescription: string;
}

class EmployeeForm extends Component<Props, State> {
  public state = {
    firstName: this.props.selectedEmployee
      ? this.props.selectedEmployee.firstName
      : "",
    lastName: this.props.selectedEmployee
      ? this.props.selectedEmployee.lastName
      : "",
    locale: this.props.selectedEmployee
      ? this.props.selectedEmployee.locale
      : "en",
    currencyCode: this.props.selectedEmployee
      ? this.props.selectedEmployee.currencyCode
      : this.props.tenantCurrency || "",
    email: this.props.selectedEmployee ? this.props.selectedEmployee.email : "",
    residence: this.props.selectedEmployee
      ? this.props.selectedEmployee.residence
      : "",
    entityId:
      this.props.selectedEmployee && this.props.selectedEmployee.entity
        ? this.props.selectedEmployee.entity.id
        : "",
    socSec: this.props.selectedEmployee
      ? this.props.selectedEmployee.socSec || ""
      : "",
    internalIdentification: this.props.selectedEmployee
      ? this.props.selectedEmployee.internalIdentification
      : "",
    insider: this.props.selectedEmployee
      ? this.props.selectedEmployee.insider
      : false,
    mobilityEntries: this.props.selectedEmployee
      ? this.props.selectedEmployee.mobilityEntries.map(toMobilityEntryInput)
      : [],
    areMobilityRowsValid: true,
    shareDepositoryAccount:
      (this.props.selectedEmployee &&
        this.props.selectedEmployee.shareDepositoryAccount) ||
      "",
    shareDepositoryBank:
      (this.props.selectedEmployee &&
        this.props.selectedEmployee.shareDepositoryBank) ||
      "",
    shareDepositoryContact:
      (this.props.selectedEmployee &&
        this.props.selectedEmployee.shareDepositoryContact) ||
      "",
    shareDepositoryDescription:
      (this.props.selectedEmployee &&
        this.props.selectedEmployee.shareDepositoryDescription) ||
      "",
  };

  public render() {
    const { dateFormat } = this.props;
    const localeOptions = generateSupportedLanguagesOptions();
    return (
      <div className="form-greyscale width-limit width-limit-medium">
        <Form size={"large"}>
          <Form.Field width={9}>
            <label>First Name</label>
            <input
              placeholder="First Name"
              value={this.state.firstName}
              onChange={this.handleChange.bind(this, "firstName")}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Last Name</label>
            <input
              placeholder="Last Name"
              value={this.state.lastName}
              onChange={this.handleChange.bind(this, "lastName")}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Company Email (will be used for login)</label>
            <input
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleChange.bind(this, "email")}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Internal Id</label>
            <input
              placeholder="Internal id"
              value={this.state.internalIdentification}
              onChange={this.handleChange.bind(this, "internalIdentification")}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Language</label>
            <Dropdown
              value={this.state.locale}
              fluid={true}
              selection={true}
              options={localeOptions}
              name={"locale"}
              onChange={this.handleSelect}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Currency Code</label>
            <CurrencyDropdown
              value={this.state.currencyCode}
              name="currencyCode"
              onChange={this.handleSelect}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Residence</label>
            <Dropdown
              placeholder="Search countries..."
              fluid={true}
              search={true}
              selection={true}
              options={countryOptions}
              name={"residence"}
              value={this.state.residence}
              onChange={this.handleSelect}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Share depository</label>
            <input
              placeholder="Share depository"
              value={this.state.shareDepositoryAccount}
              onChange={this.handleChange.bind(this, "shareDepositoryAccount")}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Share depository bank</label>
            <input
              placeholder="Share depository bank"
              value={this.state.shareDepositoryBank}
              onChange={this.handleChange.bind(this, "shareDepositoryBank")}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Share depository contact</label>
            <input
              placeholder="Share depository contact"
              value={this.state.shareDepositoryContact}
              onChange={this.handleChange.bind(this, "shareDepositoryContact")}
            />
          </Form.Field>
          <Form.Field width={9}>
            <label>Share depository description</label>
            <input
              placeholder="Share depository description"
              value={this.state.shareDepositoryDescription}
              onChange={this.handleChange.bind(
                this,
                "shareDepositoryDescription"
              )}
            />
          </Form.Field>
          <div className="block-m">
            <Form.Field>
              <div className="block-m">
                <Form.Field inline={true} width={9}>
                  <Checkbox
                    label="Insider"
                    checked={this.state.insider}
                    onChange={this.handleToggleChange}
                  />
                </Form.Field>
              </div>
            </Form.Field>
          </div>

          <div className="block-xl">
            <h3>Mobility</h3>
            {this.state.mobilityEntries.length === 0 ? (
              <div>
                <Form.Field width={9}>
                  <label>Which entity does the employee belong to?</label>
                  <div className="relative">
                    <Dropdown
                      placeholder="Search entities..."
                      fluid={true}
                      search={true}
                      selection={true}
                      name={"entityId"}
                      options={this.props.entityOptions}
                      value={this.state.entityId}
                      onChange={this.handleSelect}
                    />
                  </div>
                </Form.Field>
                <Form.Field width={9}>
                  <label>
                    Soc Sec (overrides the soc sec specified in the entity)
                  </label>
                  <Form.Input
                    placeholder="Soc sec"
                    value={this.state.socSec}
                    name="socSec"
                    onChange={this.inputDecimalChange}
                  />
                </Form.Field>
              </div>
            ) : (
              this.state.mobilityEntries.map(
                (mobility: MobilityEntryInput, index, arr) => (
                  <div className="flex-row" key={mobility.id}>
                    <div style={{ flex: 9 }}>
                      <Form.Group>
                        <Form.Select
                          width={6}
                          fluid={true}
                          label="Entity"
                          options={this.props.entityOptions}
                          placeholder="Select entity"
                          value={mobility.entityId || ""}
                          name={"entityId"}
                          onChange={this.handleMobilityChange(mobility.id)}
                        />
                        <Form.Input
                          width={3}
                          fluid={true}
                          label={`From Date (${dateFormat.normalDateFormat})`}
                          value={index === 0 ? "" : mobility.fromDate}
                          placeholder="From Date"
                          disabled={true}
                          onChange={this.handleMobilityChange(mobility.id)}
                          name={"fromDate"}
                        />
                        <Form.Input
                          label={`To Date (${dateFormat.normalDateFormat})`}
                        >
                          <DatePicker
                            selected={
                              mobility.toDate
                                ? moment(
                                    mobility.toDate,
                                    dateFormat.normalDateFormat
                                  )
                                : null
                            }
                            onChange={m =>
                              this.handleToDate(m, mobility.id, index)
                            }
                            minDate={moment(
                              mobility.fromDate,
                              dateFormat.normalDateFormat
                            ).add(1, "days")}
                            placeholderText="Select a date"
                          />
                        </Form.Input>
                        <Form.Input
                          width={4}
                          fluid={true}
                          label="Soc sec (blank for entity soc sec)"
                          value={mobility.overrideEntitySocSec}
                          placeholder="0,14 for 14%"
                          onChange={this.handleMobilityChange(mobility.id)}
                          name={"overrideEntitySocSec"}
                        />
                      </Form.Group>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        visibility: isLast(index, arr) ? "visible" : "hidden",
                      }}
                      className="center-center"
                    >
                      <a
                        href="javascript:void(0)"
                        onClick={this.removeMobilityRow.bind(this, mobility.id)}
                      >
                        Remove row
                      </a>
                    </div>
                  </div>
                )
              )
            )}
            {this.state.areMobilityRowsValid || (
              <Message visible={true} color="red">
                <p>All mobility rows must have an Entity.</p>
                <p>
                  In all rows except first and last – both dates have to be
                  selected. First row needs an open "From date" and the last row
                  needs an open "End date.
                </p>
              </Message>
            )}
            {this.state.mobilityEntries.length ? (
              <Message visible={true}>
                Enter a "To date" to add a new mobility row
              </Message>
            ) : null}
          </div>
          <div className="text-center">
            <Button.Group>
              <Button type="button" onClick={this.props.onCloseForm}>
                Cancel
              </Button>
              <Button.Or />
              <Button
                positive={true}
                type="submit"
                onClick={this.validateMobilityRows}
              >
                Save employee
              </Button>
            </Button.Group>
          </div>
        </Form>
      </div>
    );
  }

  private handleChange = (key, event) => {
    const updateObject = {};
    updateObject[key] = event.target.value;
    this.setState(updateObject);
  };

  private inputDecimalChange = (event, { name, value }) =>
    this.setState({ [name]: changeCommaForPunctuation(value) } as Pick<
      State,
      keyof State
    >);

  private handleSelect = (event, { value, name }) => {
    this.setState({ [name]: value } as Pick<State, keyof State>);
  };

  private handleToggleChange = () => {
    this.setState({ insider: !this.state.insider });
  };

  private handleToDate = (value, id, index) => {
    const mobilityEntries = [...this.state.mobilityEntries];
    const { dateFormat } = this.props;
    const updatedMobilityEntries = handleToDateChange(
      mobilityEntries,
      id,
      value,
      index,
      dateFormat
    );

    this.setState(
      () => ({ mobilityEntries: updatedMobilityEntries }),
      () => index === mobilityEntries.length - 1 && this.addAnotherMobilityRow()
    );
  };

  private handleMobilityChange = id => (event, { name, value }) => {
    this.setState((prevState: State, props) => ({
      mobilityEntries: prevState.mobilityEntries.map(
        m => (m.id === id ? { ...m, [name]: value } : m)
      ),
    }));
  };

  private addAnotherMobilityRow = () => {
    const { mobilityEntries } = this.state;
    const toDateFromPrevRow =
      mobilityEntries[mobilityEntries.length - 1].toDate;

    this.setState((prevState: State) => ({
      mobilityEntries: [
        ...prevState.mobilityEntries,
        createDefaultMobilityEntry(null, toDateFromPrevRow),
      ],
    }));
  };

  private removeMobilityRow = id => {
    this.setState((prevState: State, props) => ({
      mobilityEntries: prevState.mobilityEntries.filter(m => m.id !== id),
    }));
  };

  private validateMobilityRows = () => {
    const mobilityEntries = this.state.mobilityEntries;
    const areAllEntitiesSet = mobilityEntries.every(m => Boolean(m.entityId));
    const allAllDatesSet = mobilityEntries
      .slice(1, -1)
      .every(m => Boolean(m.fromDate) && Boolean(m.toDate));

    this.setState(
      { areMobilityRowsValid: areAllEntitiesSet && allAllDatesSet },
      () => this.state.areMobilityRowsValid && this.addEmployee()
    );
  };

  private addEmployee = () => {
    const mobilityEntries: Api.V1.MobilityEntry[] =
      this.state.mobilityEntries.length === 0
        ? [
            createDefaultMobilityEntry(
              this.state.entityId,
              null,
              this.state.socSec
            ),
          ]
        : this.state.mobilityEntries.map(toMobilityEntry);

    const employee: Api.V1.Employee = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      residence: this.state.residence,
      entityId: this.state.entityId,
      socSec: this.state.socSec,
      internalIdentification: this.state.internalIdentification,
      insider: this.state.insider,
      mobilityEntries,
      currencyCode: this.state.currencyCode,
      locale: this.state.locale as SupportedLocale,
      shareDepositoryAccount: this.state.shareDepositoryAccount,
      shareDepositoryBank: this.state.shareDepositoryBank,
      shareDepositoryContact: this.state.shareDepositoryContact,
      shareDepositoryDescription: this.state.shareDepositoryDescription,
    };

    this.props.saveEmployee(employee);
  };
}

export const createDefaultMobilityEntry = (
  entityId?: string,
  toDateFromPrevRow?: string,
  socSec?: string
): MobilityEntryInput => ({
  id: new Date().getTime().toString(),
  fromDate: toDateFromPrevRow
    ? moment(toDateFromPrevRow, norwegianShortDateLongYear)
        .add(1, "days")
        .format(norwegianShortDateLongYear)
    : "",
  toDate: "",
  entityId,
  overrideEntitySocSec: socSec,
});

const toMobilityEntryInput = (
  me: Api.V1.MobilityEntry,
  index: number
): MobilityEntryInput => ({
  id: `${index}-${new Date().getTime()}`,
  fromDate: moment(me.fromDate, apiShortDate).isValid()
    ? moment(me.fromDate, apiShortDate).format(norwegianShortDateLongYear)
    : "",
  toDate: moment(me.toDate, apiShortDate).isValid()
    ? moment(me.toDate, apiShortDate).format(norwegianShortDateLongYear)
    : "",
  overrideEntitySocSec: me.overrideEntitySocSec || "",
  entityId: me.entity.id,
});

const toMobilityEntry = (me: MobilityEntryInput): Api.V1.MobilityEntry => ({
  fromDate: me.fromDate
    ? moment(me.fromDate, norwegianShortDateLongYear).format(apiShortDate)
    : null,
  toDate: me.toDate
    ? moment(me.toDate, norwegianShortDateLongYear).format(apiShortDate)
    : null,
  overrideEntitySocSec: me.overrideEntitySocSec
    ? changeCommaForPunctuation(me.overrideEntitySocSec)
    : null,
  entityId: me.entityId,
});

const handleToDateChange = (
  me: MobilityEntryInput[],
  id: string,
  value: Moment,
  index: number,
  dateFormat: DateFormatState
): MobilityEntryInput[] => {
  const len = me.length;
  // updating "toDate"
  const mobilityEntries = me.map(entry => {
    if (entry.id === id) {
      return {
        ...entry,
        toDate: value ? value.format(dateFormat.normalDateFormat) : "",
      };
    } else {
      return entry;
    }
  });
  // updating next "fromDate"
  mobilityEntries.forEach((entry: MobilityEntryInput, i) => {
    if (index < len - 1 && entry.id === id) {
      mobilityEntries[i + 1] = {
        ...me[i + 1],
        fromDate: value.add(1, "days").format(dateFormat.normalDateFormat),
      };
    }
  });

  return mobilityEntries;
};

const isLast = (index: number, arr: MobilityEntryInput[]): boolean =>
  index === arr.length - 1;

export default EmployeeForm;
