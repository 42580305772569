import { ConnectedRouter, push } from "connected-react-router";
import { History } from "history";
import React, { Component } from "react";
import Loadable from "react-loadable";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { TenantState } from "src/admin-portal/tenant/tenant-reducer";
import { KEEP_ALIVE_ACTION } from "src/common/auth/keep-alive-saga";
import GeneralErrorModal from "src/common/error/general-error-modal.tsx";
import { CONTACT_EMAIL } from "src/index";
import { UserState } from "src/reducers/user";
import { isAdminUserWithoutInstruments } from "src/selectors";
import LoggedInCallback from "./common/auth/logged-in";
import LoginPage from "./common/auth/login-page";
import Logout from "./common/auth/logout-page";
import RequireAuth from "./common/auth/require-auth";
import SpinnerFullScreen from "./common/components/spinner-full-screen";
import { RootState } from "./reducers/all-reducers";

const LoadableAdminRouter = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'employer-portal' */ "./admin-portal/admin-router"),
  LoadingComponent: () => <SpinnerFullScreen active={true} />,
});

const LoadableEmployeePortalRouter = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'employee-portal' */ "./employee-portal/employee-portal-router"),
  LoadingComponent: () => <SpinnerFullScreen active={true} />,
});

interface StateProps {
  generalApiRequestFailed;
  isSysadmin: boolean;
  companyName?: string;
  tenant: TenantState;
  user: UserState;
}

interface OwnProps {
  history: History;
}

type Props = StateProps & OwnProps;

class Router extends Component<StateProps & Props> {
  public shouldRedirectToDifferentPortal = path => {
    const { isSysadmin } = this.props;
    const pathIncludesAdmin = path.includes("admin");

    if (!isSysadmin && pathIncludesAdmin) {
      return <Redirect to="/" />;
    } else if (isSysadmin && !pathIncludesAdmin) {
      return <Redirect to="/admin" />;
    }
  };
  public render() {
    const {
      generalApiRequestFailed,
      companyName,
      tenant: { selectedTenant },
      user,
      history,
    } = this.props;

    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact={true} path="/login" render={() => <LoginPage />} />
          <Route
            exact={true}
            path="/loggedin"
            render={props => <LoggedInCallback {...props} />}
          />
          <Route exact={true} path="/logout" render={() => <Logout />} />
          <Route
            path="/"
            render={({ match }) => (
              <RequireAuth>
                {this.shouldRedirectToDifferentPortal(
                  history.location.pathname
                )}

                <div className="content-wrapper">
                  <Switch>
                    <Route
                      path="/admin"
                      render={({ match }) =>
                        generalApiRequestFailed ? (
                          <GeneralErrorModal
                            message={`An error occured while fetching data. Please try again. If the problem persists, please contact us at ${CONTACT_EMAIL} or by using the chat in the bottom right corner (if available)`}
                          />
                        ) : (
                          <LoadableAdminRouter
                            match={match}
                            selectedTenant={selectedTenant}
                            user={user}
                          />
                        )
                      }
                    />
                    <Route
                      path="/"
                      render={({ match }) => (
                        <LoadableEmployeePortalRouter
                          match={match}
                          companyName={companyName}
                        />
                      )}
                    />
                  </Switch>
                </div>
              </RequireAuth>
            )}
          />
        </Switch>
      </ConnectedRouter>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = ({
  generalApiRequestFailed,
  user,
  tenant,
}): StateProps => ({
  generalApiRequestFailed,
  isSysadmin: user.isSysadmin,
  companyName: user.tenant && user.tenant.name,
  user,
  tenant,
});

export default connect<StateProps, null, OwnProps>(mapStateToProps)(Router);
