import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  deletePerformanceRuleEntry,
  deletePerformanceRuleEntrySucceeded,
  fetchPerformanceRuleEntries,
  fetchPerformanceRuleEntriesSucceeded,
  patchPerformanceRuleEntry,
  patchPerformanceRuleEntrySucceeded,
  postPerformanceRuleEntry,
  postPerformanceRuleEntrySucceeded,
} from "src/admin-portal/performance-rules/performance-rule-entries-page/performance-rule-entries-actions";
import * as selectors from "src/admin-portal/tenant/tenant-selectors";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { USER_NOT_AUTHORIZED } from "src/reducers/user";

const dataFormatter = new Jsona();

const performanceRuleEntriesUrl = (performanceRuleId: string) =>
  `/performance-rules/${performanceRuleId}/performance-rule-entries`;
const performanceRuleEntriesPatchAndDeleteUrl = (
  performanceRuleEntryId: string
) => `/performance-rule-entries/${performanceRuleEntryId}`;

function* fetchPerformanceRuleEntriesRequested(
  action: ReturnType<typeof fetchPerformanceRuleEntries>
) {
  try {
    const token = yield select(selectors.token);

    const response = yield call(() =>
      callApi(
        performanceRuleEntriesUrl(action.payload.performanceRuleId),
        token
      )
    );

    yield put(
      fetchPerformanceRuleEntriesSucceeded({
        performanceRuleEntries: dataFormatter.deserialize(
          response
        ) as Api.V1.PerformanceRuleEntry[],
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else if (e.errorMessage) {
      Raven.captureException(e);
    }
  }
}

export function* watchPerformanceRuleEntries() {
  yield takeEvery(
    fetchPerformanceRuleEntries.getType(),
    fetchPerformanceRuleEntriesRequested
  );
}

function* postPerformanceRuleEntryRequested(
  action: ReturnType<typeof postPerformanceRuleEntry>
) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";
    const response = yield call(() =>
      callApi(
        performanceRuleEntriesUrl(action.payload.performanceRuleId),
        token,
        method,
        dataFormatter.serialize({
          stuff: {
            ...action.payload.performanceRuleEntry,
            type: "performanceRuleEntries",
          },
        })
      )
    );
    yield put(
      postPerformanceRuleEntrySucceeded(dataFormatter.deserialize(
        response
      ) as Api.V1.PerformanceRuleEntry)
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostPerformanceRuleEntry() {
  yield takeEvery(
    postPerformanceRuleEntry.getType(),
    postPerformanceRuleEntryRequested
  );
}

const getTrimmedPatchPayload = payload => {
  const {
    links,
    performanceRule,
    relationshipNames,
    ...trimmedPayload
  } = payload;
  return trimmedPayload;
};

function* patchPerformanceRuleEntryRequested(
  action: ReturnType<typeof patchPerformanceRuleEntry>
) {
  try {
    const token = yield select(selectors.token);
    const method = "PATCH";
    const trimmedPayload = getTrimmedPatchPayload(
      action.payload.performanceRuleEntry
    );

    const response = yield call(() =>
      callApi(
        performanceRuleEntriesPatchAndDeleteUrl(
          action.payload.performanceRuleEntryId
        ),
        token,
        method,
        dataFormatter.serialize({
          stuff: trimmedPayload,
        })
      )
    );
    yield put(
      patchPerformanceRuleEntrySucceeded(dataFormatter.deserialize(
        response
      ) as Api.V1.PerformanceRuleEntry)
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPatchPerformanceRuleEntry() {
  yield takeEvery(
    patchPerformanceRuleEntry.getType(),
    patchPerformanceRuleEntryRequested
  );
}

function* deletePerformanceRuleEntryRequested(
  action: ReturnType<typeof deletePerformanceRuleEntry>
) {
  try {
    const token = yield select(selectors.token);
    const method = "DELETE";

    yield call(() =>
      callApi(
        performanceRuleEntriesPatchAndDeleteUrl(action.payload),
        token,
        method
      )
    );
    yield put(deletePerformanceRuleEntrySucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeletePerformanceRuleEntry() {
  yield takeEvery(
    deletePerformanceRuleEntry.getType(),
    deletePerformanceRuleEntryRequested
  );
}
