import { createAction } from "redux-act";

export const FETCH_TENANTS_SUCCEEDED = "FETCH_TENANTS_SUCCEEDED";
export const FETCH_TENANTS_FAILED = "FETCH_TENANTS_FAILED";
export const FETCH_TENANTS = "FETCH_TENANTS";

export const POST_TENANT_SUCCEEDED = "POST_TENANT_SUCCEEDED";
export const POST_TENANT_FAILED = "POST_TENANT_FAILED";
export const POST_TENANT = "POST_TENANT";

export const PUT_TENANT_SUCCEEDED = "PUT_TENANT_SUCCEEDED";
export const PUT_TENANT_FAILED = "PUT_TENANT_FAILED";
export const PUT_TENANT = "PUT_TENANT";

export const SELECT_TENANT = "SELECT_TENANT";
export const selectTenant = createAction("SELECT_TENANT");

export const DELETE_TENANT_SUCCEEDED = "DELETE_TENANT_SUCCEEDED";
export const DELETE_TENANT_FAILED = "DELETE_TENANT_FAILED";
export const DELETE_TENANT = "DELETE_TENANT";

export const FETCH_LIVE_TENANTS_OVERVIEW_SUCCEEDED =
  "FETCH_LIVE_TENANTS_OVERVIEW_SUCCEEDED";
export const FETCH_LIVE_TENANTS_OVERVIEW = "FETCH_LIVE_TENANTS_OVERVIEW";
