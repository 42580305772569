export const FETCH_TENANT_ORDERS_SUCCEEDED = "FETCH_ORDERS_SUCCEEDED";
export const FETCH_TENANT_ORDERS_FAILED = "FETCH_ORDERS_FAILED";
export const FETCH_TENANT_ORDERS = "FETCH_TENANT_ORDERS";

export const PUT_TENANT_ORDERS_SUCCEEDED = "PUT_TENANT_ORDERS_SUCCEEDED";
export const PUT_TENANT_ORDERS_FAILED = "PUT_TENANT_ORDERS_FAILED";
export const PUT_TENANT_ORDERS = "PUT_TENANT_ORDERS";

export const DELETE_ORDER_SUCCEEDED = "DELETE_ORDER_SUCCEEDED";
export const DELETE_ORDER_FAILED = "DELETE_ORDER_FAILED";
export const DELETE_ORDER = "DELETE_ORDER";
