import {
  FETCH_SELF_SERVICE_OVERVIEW,
  FETCH_SELF_SERVICE_OVERVIEW_FAILED,
  FETCH_SELF_SERVICE_OVERVIEW_SUCCEEDED,
} from "./self-service-actions";

export interface SelfServiceState {
  overview: Api.V1.TenantOverview;
}

const initialState: SelfServiceState = {
  overview: null,
};

const selfService = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SELF_SERVICE_OVERVIEW_SUCCEEDED:
      return {
        ...state,
        overview: action.overview,
      };
    default:
      return state;
  }
};

export default selfService;
