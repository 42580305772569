import { Reducer } from "redux";
import {
  DELETE_CONTENT_TEMPLATE_VALUES,
  DELETE_CONTENT_TEMPLATE_VALUES_SUCCEEDED,
  FETCH_CONTENTS,
  FETCH_CONTENTS_FAILED,
  FETCH_CONTENTS_SUCCEEDED,
  PATCH_CONTENT_TEMPLATE_VALUES,
  PATCH_CONTENT_TEMPLATE_VALUES_SUCCEEDED,
  POST_CONTENT_TEMPLATE_VALUES,
  POST_CONTENT_TEMPLATE_VALUES_SUCCEEDED,
} from "./content-actions";

export interface ContentState {
  allContents: Api.V1.ContentTemplate[];
  isFetchingContents: boolean;
}

const initialState: ContentState = {
  allContents: [],
  isFetchingContents: false,
};

const ContentReducer: Reducer<ContentState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_CONTENTS:
    case POST_CONTENT_TEMPLATE_VALUES:
    case PATCH_CONTENT_TEMPLATE_VALUES:
    case DELETE_CONTENT_TEMPLATE_VALUES:
      return { ...state, isFetchingContents: true };
    case FETCH_CONTENTS_FAILED:
      return { ...state, isFetchingContents: false };
    case FETCH_CONTENTS_SUCCEEDED:
      return {
        ...state,
        isFetchingContents: false,
        allContents: action.contents,
      };
    case POST_CONTENT_TEMPLATE_VALUES_SUCCEEDED:
      return {
        ...state,
        isFetchingContents: false,
        allContents: state.allContents.map(
          contents =>
            contents.id === action.contentTemplateValue.contentTemplate.id
              ? {
                  ...contents,
                  contentTemplateValues: [
                    ...contents.contentTemplateValues,
                    action.contentTemplateValue,
                  ],
                }
              : contents
        ),
      };
    case PATCH_CONTENT_TEMPLATE_VALUES_SUCCEEDED:
      return {
        ...state,
        isFetchingContents: false,
        allContents: state.allContents.map(
          contents =>
            contents.id === action.contentTemplateValue.contentTemplate.id
              ? {
                  ...contents,
                  contentTemplateValues: contents.contentTemplateValues.map(
                    ctv =>
                      ctv.id === action.contentTemplateValue.id
                        ? { ...ctv, value: action.contentTemplateValue.value }
                        : ctv
                  ),
                }
              : contents
        ),
      };
    case DELETE_CONTENT_TEMPLATE_VALUES_SUCCEEDED:
      return {
        ...state,
        isFetchingContents: false,
        allContents: state.allContents.map(
          contents =>
            contents.id === action.contentTemplateId
              ? {
                  ...contents,
                  contentTemplateValues: contents.contentTemplateValues.filter(
                    ctv => ctv.id !== action.contentTemplateValuesId
                  ),
                }
              : contents
        ),
      };
  }

  return state;
};

export default ContentReducer;
