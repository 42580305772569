import { createReducer } from "redux-act";
import {
  AuditLogs,
  fetchAuditLogs,
  fetchAuditLogsSucceeded,
} from "src/admin-portal/audit-logs/audit-logs-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";
import { PaginationLinks } from "src/common/utils/pagination";

export interface AuditLogsState {
  isFetching: boolean;
  allAuditLogs: AuditLogs;
  auditLogsPaginationLinks: PaginationLinks;
}

export const initialState: AuditLogsState = {
  isFetching: false,
  allAuditLogs: {},
  auditLogsPaginationLinks: null,
};

export default createReducer(on => {
  on(fetchAuditLogs, state => ({
    ...state,
    isFetching: true,
  }));
  on(
    fetchAuditLogsSucceeded,
    (state, { auditLogs, auditLogsPaginationLinks }) => ({
      ...state,
      isFetching: false,
      allAuditLogs: { ...state.allAuditLogs, ...auditLogs },
      auditLogsPaginationLinks,
    })
  );
  on(selectTenant, () => initialState);
}, initialState);
