import moment, { Moment } from "moment";
import { countryEntry } from "src/common/data/common";
import { sortMultipleLevels } from "src/common/utils/sort";
import {
  expiredQuantity,
  terminationQuantity,
} from "src/common/utils/tranche-utils";
import {
  apiShortDate,
  flatten,
  norwegianShortDateLongYear,
  toNumberOrDefault,
} from "src/common/utils/utils";
import {
  NO_VALUE,
  OPTIO_INCENTIVES_BEGINNING_OF_TIME,
  OPTIO_INCENTIVES_END_OF_TIME,
  TransactionType,
} from "src/constants";
import { ExcelSheetAwardLine, Tranche } from "./award-reducer";

export const token = state => state.user.token;
export const isSysadmin = state => state.user.isSysadmin;
export const tenantId = state =>
  state.tenant.selectedTenant && state.tenant.selectedTenant.id;

export const entityAtDate = (
  mobiltyEntries: Api.V1.MobilityEntry[],
  date: Moment
): Api.V1.Entity => {
  const currentEntry = mobiltyEntries.find(me =>
    date.isBetween(me.fromDate, me.toDate, null, "[]")
  );
  if (!currentEntry) {
    throw new Error("Could not find an entity.");
  }

  return currentEntry.entity;
};

export const toExcelSheetAwardLine = (
  rawTranches: Tranche[]
): ExcelSheetAwardLine[] => {
  const tranches = sortedTranches(rawTranches);
  const lines: ExcelSheetAwardLine[] = flatten(
    tranches.map((tranche, trancheIndex) =>
      tranche.transactions.map(
        (transaction: Api.V1.Transaction, transactionIndex) => {
          const trancheIntegerId = (trancheIndex + 1) * 1000;
          const integerId = trancheIntegerId + transactionIndex + 1;
          return {
            id: transaction.id,
            tranche_id: tranche.id,
            programId: tranche.programId,
            programName: tranche.programName,
            subProgramName: tranche.subProgramName,
            employeeName: tranche.employeeName,
            employee: tranche.employee,
            entity: tranche.entity,
            entityAtTransactionDate: entityAtDate(
              tranche.mobility,
              moment(transaction.transactionDate, apiShortDate)
            ),
            country: tranche.country,
            entityName: tranche.entityName,
            instrumentName: tranche.instrumentName,
            settlementName: tranche.settlementName,
            performance: tranche.performance,
            grantDate: transaction.grantDate && moment(transaction.grantDate),
            vestedDate:
              transaction.vestedDate && moment(transaction.vestedDate),
            expiryDate:
              transaction.expiryDate && moment(transaction.expiryDate),
            quantity: transaction.quantity,
            strike: isNaN(parseFloat(transaction.strike))
              ? null
              : parseFloat(transaction.strike),
            exercisedQuantity: tranche.exercisedQuantity,
            purchase_price: transaction.purchasePrice,
            fair_value: transaction.fairValue,
            transaction_type: transaction.transactionType,
            transaction_date: moment(transaction.transactionDate, apiShortDate),
            is_dividend: tranche.is_dividend,
            integerId,
            mobility: tranche.mobility,
            fv_valuation_method: transaction.fvValuationMethod,
            fv_share_price_at_grant: parseDecimalNumberOrNull(
              transaction.fvSharePriceAtGrant
            ),
            fv_strike_price: parseDecimalNumberOrNull(
              transaction.fvStrikePrice
            ),
            fv_expected_lifetime: parseDecimalNumberOrNull(
              transaction.fvExpectedLifetime
            ),
            fv_volatility: parseDecimalNumberOrNull(transaction.fvVolatility),
            fv_interest_rate: parseDecimalNumberOrNull(
              transaction.fvInterestRate
            ),
            fv_dividend: parseDecimalNumberOrNull(transaction.fvDividend),
            comment: transaction.comment,
            newQuantityFactor: toNumberOrDefault(
              transaction.newQuantityFactor,
              1
            ),
            performanceRule:
              tranche.tranchePerformanceRules[0] &&
              tranche.tranchePerformanceRules[0].performanceRule,
          } as ExcelSheetAwardLine;
        }
      )
    )
  );

  return lines.map(line => {
    if (
      TransactionType[line.transaction_type] === TransactionType.TERMINATION
    ) {
      return {
        ...line,
        transactions_to_terminate: transactionsToTerminate(lines, line),
      };
    }
    return line;
  });
};

export const toTranches = (award: Api.V1.Award): Tranche[] => {
  return award.tranches.map(
    (ve): Tranche => ({
      id: ve.id,
      internal_employee_id: award.employee.internalIdentification,
      programId: award.incentiveSubProgram.incentiveProgram.id,
      programName: award.incentiveSubProgram.incentiveProgram.name,
      subProgramId: award.incentiveSubProgram.id,
      subProgramName: award.incentiveSubProgram.name,
      subProgram: award.incentiveSubProgram,
      employeeName: `${award.employee.firstName} ${award.employee.lastName}`,
      employee: award.employee,
      entity: award.employee.entity,
      country: award.employee.residence,
      entityName: award.employee.entity.name,
      instrumentName: award.incentiveSubProgram.instrumentTypeId,
      settlementName: award.incentiveSubProgram.settlementTypeId,
      performance: award.incentiveSubProgram.performance,
      grantDate: moment(ve.grantDate),
      vestedDate: moment(ve.vestedDate),
      expiryDate: moment(ve.expiryDate),
      quantity: ve.quantity,
      strike: isNaN(parseFloat(ve.strike)) ? null : parseFloat(ve.strike),
      exercisedQuantity: ve.exercisedQuantity,
      termination_quantity: ve.terminationQuantity,
      purchase_price: ve.purchasePrice,
      is_dividend: ve.isDividend,
      fair_value: ve.fairValue,
      transactions: ve.transactions,
      mobility: award.employee.mobilityEntries.map(me => ({
        ...me,
        fromDate: me.fromDate || OPTIO_INCENTIVES_BEGINNING_OF_TIME,
        toDate: me.toDate || OPTIO_INCENTIVES_END_OF_TIME,
      })),
      tranchePerformanceRules: ve.tranchePerformanceRules,
      annualTurnover: award.incentiveSubProgram.accountingTurnoverRate,
    })
  );
};

const parseDecimalNumberOrNull = (numberString: string): number | null =>
  isNaN(parseFloat(numberString)) ? null : parseFloat(numberString);

const transactionsToTerminate = (
  lines: ExcelSheetAwardLine[],
  line: ExcelSheetAwardLine
) => lines.filter(l => l.tranche_id === line.tranche_id && line.id !== l.id);

export const trancheDataArray = (
  tranches: Api.V1.VestingEvent[]
): Array<Array<string | number>> => {
  const headers = [
    "Name",
    "Email",
    "Entity",
    "Country",
    "Program",
    "Subprogram",
    "Instrument",
    "Settlement",
    "Performance",
    "Purchase price",
    "Strike",
    "Quantity",
    "Exercised",
    "Terminated",
    "Expired",
    "Grant Date",
    "Vested Date",
    "Expiry Date",
    "Is dividend?",
    "Fair value",
    "Share Depo Account",
    "Share Depo Bank",
    "Share Depo Contact",
    "Share Depo Desc.",
  ];

  const rows = tranches.map(tranche => [
    `${tranche.award.employee.firstName} ${tranche.award.employee.lastName}`,
    tranche.award.employee.email,
    tranche.award.employee.entity.name,
    countryEntry(tranche.award.employee.residence).text,
    tranche.award.incentiveSubProgram.incentiveProgram.name,
    tranche.award.incentiveSubProgram.name,
    tranche.award.incentiveSubProgram.instrumentTypeId,
    tranche.award.incentiveSubProgram.settlementTypeId,
    tranche.award.incentiveSubProgram ? "Yes" : "No",
    tranche.purchasePrice ? parseFloat(tranche.purchasePrice) : NO_VALUE,
    tranche.strike ? tranche.strike : NO_VALUE,
    tranche.quantity - expiredQuantity(tranche),
    -1 * tranche.exercisedQuantity,
    terminationQuantity(tranche),
    expiredQuantity(tranche),
    tranche.grantDate
      ? moment(tranche.grantDate).format(norwegianShortDateLongYear)
      : NO_VALUE,
    tranche.vestedDate
      ? moment(tranche.vestedDate).format(norwegianShortDateLongYear)
      : NO_VALUE,
    tranche.expiryDate
      ? moment(tranche.expiryDate).format(norwegianShortDateLongYear)
      : NO_VALUE,
    tranche.isDividend ? "Yes" : "No",
    tranche.fairValue ? parseFloat(tranche.fairValue) : NO_VALUE,
    tranche.award.employee.shareDepositoryAccount
      ? tranche.award.employee.shareDepositoryAccount
      : NO_VALUE,
    tranche.award.employee.shareDepositoryBank
      ? tranche.award.employee.shareDepositoryBank
      : NO_VALUE,
    tranche.award.employee.shareDepositoryContact
      ? tranche.award.employee.shareDepositoryContact
      : NO_VALUE,
    tranche.award.employee.shareDepositoryDescription
      ? tranche.award.employee.shareDepositoryDescription
      : NO_VALUE,
  ]);

  return [headers, ...rows];
};

export const sortedTranches = (tranches: Tranche[]): Tranche[] =>
  sortMultipleLevels(tranches)("employeeName", "grantDate", "vestedDate");
