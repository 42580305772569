import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Popup } from "semantic-ui-react";
import Content from "src/admin-portal/texts/content";
import { formatCurrencyConversionFactor } from "src/common/utils/utils";
import {
  currencyConversionFactor,
  overrideCurrencyCode,
  tenantCurrencyCode,
} from "src/employee-portal/employee-portal-selectors";
import { RootState } from "src/reducers/all-reducers";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "./currency-formatter";

interface OwnProps {
  value: number;
  decimals: number;
  showOverrideCurrencyOnly?: boolean;
}

interface StateProps {
  conversionFactor: number;
  tenantCurrency: string;
  overrideCurrency?: string;
}

type Props = OwnProps & StateProps;

const CurrencyConversionView: FunctionComponent<Props> = ({
  value,
  decimals,
  overrideCurrency,
  conversionFactor,
  tenantCurrency,
  showOverrideCurrencyOnly,
}) => (
  <CurrencyFormatter>
    {(formatter: CurrencyFormatterProps) => {
      const sameCurrency = tenantCurrency === overrideCurrency;
      const showConversionCurrency = overrideCurrency && !sameCurrency;
      const showBaseCurrency = !showOverrideCurrencyOnly || sameCurrency;
      return showConversionCurrency ? (
        <Popup
          hoverable={true}
          trigger={
            <div>
              {showBaseCurrency && (
                <div>{formatter.formatCurrency(value, decimals)}</div>
              )}
              <div>
                {formatter.formatCurrencyWithCurrencyCode(overrideCurrency)(
                  conversionFactor * value,
                  decimals
                )}
              </div>
            </div>
          }
        >
          <Content
            id={"currency.conversion.message"}
            values={{
              baseCurrency: tenantCurrency,
              conversionFactor: formatCurrencyConversionFactor(
                conversionFactor
              ),
              overrideCurrency,
            }}
          />
        </Popup>
      ) : (
        <div>{formatter.formatCurrency(value, decimals)}</div>
      );
    }}
  </CurrencyFormatter>
);

const mapStateToProps = (state: RootState): StateProps => ({
  conversionFactor: currencyConversionFactor(state),
  tenantCurrency: tenantCurrencyCode(state),
  overrideCurrency: overrideCurrencyCode(state),
});

export default connect(mapStateToProps)(CurrencyConversionView);
