import { Reducer } from "redux";
import {
  DELETE_SYSADMIN,
  DELETE_SYSADMIN_FAILED,
  DELETE_SYSADMIN_SUCCEEDED,
  FETCH_SYSADMINS,
  FETCH_SYSADMINS_FAILED,
  FETCH_SYSADMINS_SUCCEEDED,
  POST_SYSADMIN,
  POST_SYSADMIN_FAILED,
  POST_SYSADMIN_SUCCEEDED,
} from "./sysadmin-actions";

export interface Sysadmin {
  id?: string; // optional so it can be reused in the SysAdminManagementPage
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  avatar: string;
}

export interface SysadminState {
  allSysadmins: Sysadmin[];
  isFetching: boolean;
  hasFetchingErr: boolean;
  hasPostErr: boolean;
  hasDeleteErr: boolean;
}

const initialState: SysadminState = {
  allSysadmins: [],
  isFetching: false,
  hasFetchingErr: false,
  hasPostErr: false,
  hasDeleteErr: false,
};

const sysadminReducer: Reducer<SysadminState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_SYSADMINS:
      return { ...state, isFetching: true };
    case FETCH_SYSADMINS_SUCCEEDED:
      return {
        ...state,
        allSysadmins: action.sysadmins,
        isFetching: false,
        hasFetchingErr: false,
      };
    case FETCH_SYSADMINS_FAILED:
      return {
        ...state,
        hasFetchingErr: true,
        isFetching: false,
      };
    case POST_SYSADMIN_SUCCEEDED:
      return {
        ...state,
        allSysadmins: [...state.allSysadmins, action.sysadmin],
        hasPostErr: false,
        hasDeleteErr: false,
      };
    case POST_SYSADMIN_FAILED:
      return {
        ...state,
        hasPostErr: true,
        hasDeleteErr: false,
      };
    case DELETE_SYSADMIN_SUCCEEDED:
      const newAllSysadmins = state.allSysadmins.filter(
        sysadmin => sysadmin.id !== action.sysadminId
      );
      return {
        ...state,
        allSysadmins: newAllSysadmins,
        hasDeleteErr: false,
        hasPostErr: false,
      };
    case DELETE_SYSADMIN_FAILED:
      return {
        ...state,
        hasDeleteErr: true,
        hasPostErr: false,
      };
    default:
      return state;
  }
};

export default sysadminReducer;
