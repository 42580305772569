import React, { Component, StatelessComponent } from "react";
import { Button, Modal } from "semantic-ui-react";
import { CONTACT_EMAIL } from "src";

interface Props {
  message?: string;
}

const GeneralErrorModal: StatelessComponent<Props> = ({ message }) => (
  <div className="text-center">
    <Modal open={true} size={"tiny"}>
      <Modal.Header className="text-center error-modal-header">
        Oops...
      </Modal.Header>
      <Modal.Content className="text-center">
        <div className="block-m error-modal-description">
          <Modal.Description>
            {message ||
              `An unknown error occured. Please try again. If the problem persists,
              please contact us at ${CONTACT_EMAIL} or by using the chat in the
              bottom right corner`}
          </Modal.Description>
        </div>
        <div className="block-s">
          <Button
            primary={true}
            basic={true}
            size={"big"}
            icon="refresh"
            content="Try again"
            onClick={() => window.location.reload()}
          />
        </div>
      </Modal.Content>
    </Modal>
  </div>
);

export default GeneralErrorModal;
