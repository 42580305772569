import { all, call, delay } from "redux-saga/effects";

export type CreatePromise = () => Promise<any>;

export function* batchRequests(
  batchSize: number,
  delayInMilliseconds: number,
  promises: CreatePromise[]
) {
  let result = [];
  let currentIndex = 0;
  while (currentIndex <= promises.length) {
    const batch = promises.slice(currentIndex, currentIndex + batchSize);
    const batchResult = yield all(batch.map(p => call(p)));
    result = result.concat(batchResult);
    currentIndex += batchSize;
    yield delay(delayInMilliseconds);
  }

  return result;
}
