import { createReducer } from "redux-act";
import {
  deleteReportConfigSucceeded,
  fetchReportConfigs,
  fetchReportConfigsSucceeded,
  patchReportConfigSucceeded,
  postReportConfigSucceeded,
} from "src/admin-portal/reports/reports-config/reports-config-actions";

export interface ReportsConfigsState {
  configs: Api.V1.ReportConfig[];
  isFetching: boolean;
}

export const initialState: ReportsConfigsState = {
  configs: null,
  isFetching: false,
};

export default createReducer(on => {
  on(fetchReportConfigs, state => ({
    ...state,
    isFetching: true,
  }));
  on(fetchReportConfigsSucceeded, (state, payload) => ({
    ...state,
    configs: payload.configs,
    isFetching: false,
  }));
  on(postReportConfigSucceeded, (state, payload) => ({
    ...state,
    configs: [...state.configs, payload],
  }));
  on(patchReportConfigSucceeded, (state, payload) => ({
    ...state,
    configs: state.configs.map(
      config => (config.id === payload.id ? payload : config)
    ),
  }));
  on(deleteReportConfigSucceeded, (state, payload) => ({
    ...state,
    configs: state.configs.filter(config => config.id !== payload),
  }));
}, initialState);
