import Raven from "raven-js";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { callApi, NOT_AUTHORIZED } from "../../common/api/api-helper";
import {
  DELETE_SYSADMIN,
  DELETE_SYSADMIN_FAILED,
  DELETE_SYSADMIN_SUCCEEDED,
  FETCH_SYSADMINS,
  FETCH_SYSADMINS_FAILED,
  FETCH_SYSADMINS_SUCCEEDED,
  POST_SYSADMIN,
  POST_SYSADMIN_FAILED,
  POST_SYSADMIN_SUCCEEDED,
  SELECT_SYSADMIN,
} from "./sysadmin-actions";
import * as selectors from "./sysadmin-selectors";

const SYSADMINS_REQUEST_URL = "/users";

function* fetchSysadminsRequested(action) {
  try {
    const token = yield select(selectors.token);
    const sysadminResponse = yield call(() =>
      callApi(SYSADMINS_REQUEST_URL, token)
    );

    const sysadmins = sysadminResponse.data.map(sysadmin => ({
      id: sysadmin.user_id,
      email: sysadmin.email,
      firstName: sysadmin.user_metadata.first_name,
      lastName: sysadmin.user_metadata.last_name,
      role: sysadmin.app_metadata.roles.includes("sysadmin")
        ? "sysadmin"
        : "noooope, not a sysadmin",
      avatar: sysadmin.picture,
    }));

    yield put({ type: FETCH_SYSADMINS_SUCCEEDED, sysadmins });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_SYSADMINS_FAILED, message: e.message });
    }
  }
}

export function* watchFetchSysadmins() {
  yield takeEvery(FETCH_SYSADMINS, fetchSysadminsRequested);
}

function* postSysadminRequested(action) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";

    const sysadminResponse = yield call(() =>
      callApi(SYSADMINS_REQUEST_URL, token, method, action.sysadmin)
    );

    const sysadmin = {
      id: sysadminResponse.user.user_id,
      email: sysadminResponse.user.email,
      firstName: sysadminResponse.user.user_metadata.first_name,
      lastName: sysadminResponse.user.user_metadata.last_name,
      avatar: sysadminResponse.user.picture,
    };

    yield put({ type: POST_SYSADMIN_SUCCEEDED, sysadmin });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: POST_SYSADMIN_FAILED, message: e.message });
    }
  }
}

export function* watchPostSysadmin() {
  yield takeLatest(POST_SYSADMIN, postSysadminRequested);
}

function* deleteSysadminRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);
    const method = "DELETE";

    yield call(() =>
      callApi(
        `${SYSADMINS_REQUEST_URL}/${action.sysadminId}?tenantId=${tenantId}`,
        token,
        method
      )
    );
    yield put({
      type: DELETE_SYSADMIN_SUCCEEDED,
      sysadminId: action.sysadminId,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_SYSADMIN_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteSysadmin() {
  yield takeEvery(DELETE_SYSADMIN, deleteSysadminRequested);
}
