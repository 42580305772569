import { createReducer } from "redux-act";
import {
  closePatchTranchePerformanceRuleSucceededNotification,
  deletePerformanceRuleSucceeded,
  fetchPerformanceRules,
  fetchPerformanceRulesSucceeded,
  patchPerformanceRuleSucceeded,
  patchTranchePerformanceRule,
  patchTranchePerformanceRuleSucceeded,
  postPerformanceRuleSucceeded,
} from "src/admin-portal/performance-rules/performance-rules-page/performance-rules-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface PerformanceRulesState {
  performanceRules: Api.V1.PerformanceRule[];
  isFetching: boolean;
  isAttachingToTranches: boolean;
  isAttachingToTranchesSucceeded: boolean;
}

export const initialState: PerformanceRulesState = {
  performanceRules: null,
  isFetching: false,
  isAttachingToTranches: false,
  isAttachingToTranchesSucceeded: false,
};

export default createReducer(on => {
  on(fetchPerformanceRules, state => ({
    ...state,
    isFetching: true,
  }));
  on(fetchPerformanceRulesSucceeded, (state, { performanceRules }) => ({
    ...state,
    performanceRules,
    isFetching: false,
  }));
  on(postPerformanceRuleSucceeded, (state, payload) => ({
    ...state,
    performanceRules: [payload, ...state.performanceRules],
  }));
  on(patchPerformanceRuleSucceeded, (state, payload) => ({
    ...state,
    performanceRules: state.performanceRules.map(
      pr => (pr.id === payload.id ? payload : pr)
    ),
  }));
  on(deletePerformanceRuleSucceeded, (state, payload) => ({
    ...state,
    performanceRules: state.performanceRules.filter(pr => pr.id !== payload),
  }));
  on(patchTranchePerformanceRule, state => ({
    ...state,
    isAttachingToTranches: true,
  }));
  on(patchTranchePerformanceRuleSucceeded, state => ({
    ...state,
    isAttachingToTranches: false,
    isAttachingToTranchesSucceeded: true,
  }));
  on(closePatchTranchePerformanceRuleSucceededNotification, state => ({
    ...state,
    isAttachingToTranchesSucceeded: false,
  }));
  on(selectTenant, () => initialState);
}, initialState);
