import { routerMiddleware } from "connected-react-router";
import createHistory from "history/createBrowserHistory";
import LogRocket from "logrocket";
import Raven from "raven-js";
import React, { Component } from "react";
import ErrorBoundary from "react-error-boundary";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import GeneralErrorFallback from "src/common/error/general-error-fallback";
import { crashReporter } from "src/common/logging/sentry-redux-middleware";
import rootReducer from "src/reducers/all-reducers";
import { LOGROCKET, SENTRY_DSN, SENTRY_VERSION, SENTRY_WHITELIST } from "./env";
import Router from "./router";
import rootSaga from "./sagas/all-sagas";

const sagaMiddleware = createSagaMiddleware({
  onError: error => Raven.captureException(error),
});

if (LOGROCKET) {
  LogRocket.init(LOGROCKET);
}

if (SENTRY_DSN) {
  Raven.config(SENTRY_DSN, {
    whitelistUrls: SENTRY_WHITELIST,
    release: SENTRY_VERSION,
  }).install();
}

const history = createHistory();

export const store = createStore(
  rootReducer(history),
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), crashReporter, sagaMiddleware)
  )
);

sagaMiddleware.run(rootSaga);

const errorHandler = (error: Error, componentStack: string) => {
  console.log(error, error.message, componentStack);
  Raven.captureException(error);
};

class App extends Component {
  public render() {
    return (
      <ErrorBoundary
        FallbackComponent={GeneralErrorFallback}
        onError={errorHandler}
      >
        <Provider store={store}>
          <Router history={history} />
        </Provider>
      </ErrorBoundary>
    );
  }
}

declare global {
  interface Window {
    Cypress: object;
    __store__: object;
  }
}

if (window.Cypress) {
  window.__store__ = store;
}

export default App;
