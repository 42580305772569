import { createAction } from "redux-act";

export const POST_AWARD_SUCCEEDED = "POST_AWARD_SUCCEEDED";
export const POST_AWARD_FAILED = "POST_AWARD_FAILED";
export const POST_AWARD = "POST_AWARD";

export const ADD_AWARD_VESTING_SUCCEEDED = "ADD_AWARD_VESTING_SUCCEEDED";
export const ADD_AWARD_VESTING_FAILED = "ADD_AWARD_VESTING_FAILED";
export const ADD_AWARD_VESTING = "ADD_AWARD_VESTING";

export const PUT_AWARD_SUCCEEDED = "PUT_AWARD_SUCCEEDED";
export const PUT_AWARD_FAILED = "PUT_AWARD_FAILED";
export const PUT_AWARD = "PUT_AWARD";
export const PUT_AWARDS_FAILED = "PUT_AWARDS_FAILED";
export const PUT_AWARDS = "PUT_AWARDS";

export const DELETE_AWARD_SUCCEEDED = "DELETE_AWARD_SUCCEEDED";
export const DELETE_AWARD_FAILED = "DELETE_AWARD_FAILED";
export const DELETE_AWARD = "DELETE_AWARD";

export const FETCH_TENANT_VESTING_EVENTS_SUCCEEDED =
  "FETCH_TENANT_VESTING_EVENTS_SUCCEEDED";
export const FETCH_TENANT_VESTING_EVENTS_FAILED =
  "FETCH_TENANT_VESTING_EVENTS_FAILED";
export const FETCH_TENANT_VESTING_EVENTS = "FETCH_TENANT_VESTING_EVENTS";

export const fetchTranche = createAction(
  "FETCH_TRANCHE",
  (payload: string) => payload
);

export const fetchTrancheSucceeded = createAction(
  "FETCH_TRANCHE_SUCCEEDED",
  (payload: Api.V1.VestingEvent) => payload
);
