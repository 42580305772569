import { createAction } from "redux-act";

export const postSharePrice = createAction(
  "POST_SHARE_PRICE",
  (payload: Api.V1.SharePrice) => payload
);

export const postSharePriceSucceeded = createAction(
  "POST_SHARE_PRICE_SUCCEEDED",
  (payload: Api.V1.SharePrice) => payload
);

export const fetchSharePrices = createAction(
  "FETCH_SHARE_PRICES",
  (payload: Api.V1.SharePriceQueryParams) => payload
);

export const fetchSharePricesSucceeded = createAction(
  "FETCH_SHARE_PRICES_SUCCEEDED",
  (payload: { sharePrices: Api.V1.SharePrice[]; date: string }) => payload
);

export const deleteSharePrice = createAction(
  "DELETE_SHARE_PRICE",
  (payload: Api.V1.SharePrice["id"]) => payload
);

export const deleteSharePriceSucceeded = createAction(
  "DELETE_SHARE_PRICE_SUCCEEDED",
  (payload: Api.V1.SharePrice["id"]) => payload
);
