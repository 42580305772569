import Raven from "raven-js";
import { call, put, select } from "redux-saga/effects";
import { callApi } from "../../common/api/api-helper";
import { FETCH_EMPLOYEE_PORTAL_TEXTS_SUCCEEDED } from "./employee-portal-texts-reducer";

const textsUrl = (tenantId: string, locale: string) =>
  `/tenants/${tenantId}/texts/${locale}/merged`;

export function* fetchTexts(locale) {
  try {
    const state = yield select();
    const { token, tenant } = state.user;

    const response = yield call(() =>
      callApi(textsUrl(tenant.id, locale), token)
    );
    yield put({
      type: FETCH_EMPLOYEE_PORTAL_TEXTS_SUCCEEDED,
      messages: response.data,
    });
  } catch (e) {
    Raven.captureException(e);
  }
}
