export const NO_VALUE = "-";

export const NORWEGIAN_DATE = "NORWEGIAN_DATE";
export const ENGLISH_DATE = "ENGLISH_DATE";

export enum TransactionType {
  TRANCHE = "TRANCHE",
  TRANCHE_MOBILITY = "TRANCHE_MOBILITY",
  GRANT = "GRANT",
  GRANT_MOBILITY = "GRANT-MOBILITY",
  TERMINATION = "TERMINATION",
  TERMINATION_MOBILITY = "TERMINATION-MOBILITY",
  ADJUSTMENT = "ADJUSTMENT",
  ADJUSTMENT_FVSS = "ADJUSTMENT_FVSS", // Fair value for soc sec adjustments
  ADJUSTMENT_DIVIDEND = "ADJUSTMENT_DIVIDEND",
  ADJUSTMENT_DIVIDEND_MOBILITY = "ADJUSTMENT_DIVIDEND-MOBILITY",
  EXERCISE = "EXERCISE",
  EXERCISE_RELEASE = "EXERCISE_RELEASE",
  EXERCISE_MOBILITY = "EXERCISE-MOBILITY",
  EXPIRY = "EXPIRY",
  RELEASE = "RELEASE",
  ADJUSTMENT_VESTING_DATE = "ADJUSTMENT_VESTING_DATE",
  ADJUSTMENT_VESTING_DATE_MOBILITY = "ADJUSTMENT_VESTING_DATE-MOBILITY",
  ADJUSTMENT_STRIKE = "ADJUSTMENT_STRIKE",
  ADJUSTMENT_STRIKE_MOBILITY = "ADJUSTMENT_STRIKE-MOBILITY",
}

export const STANDARD_LONG_PRECISION = 4;

export const OPTIO_INCENTIVES_BEGINNING_OF_TIME = "1970-01-01";
export const OPTIO_INCENTIVES_END_OF_TIME = "4000-01-01";
